.app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;

  &--editor-fixed {
    position: fixed;
  }

  &.main {
    .main-nofix {
      margin-bottom: 60px;
      height: 100px;
      background-color: $color--blue-main;
      color: $color--white;
      font-size: 12px;

      &__meta {
        > .ui.container {
          text-align: center;
          margin-bottom: 0;
        }

        span {
          margin: 0 5px;
        }
      }
    }

    .main-nofix.nofix-superadmin {
      margin-bottom: 0;
    }

    .main-fixed {
      height: 60px;
      background-color: $color--blue-main;
      position: fixed;
      bottom: 0;
      width: 100%;
      box-shadow: 0 -7px 5px -4px #666;

      .ui.menu {
        .item {
          &:hover {
            color: $color--white !important;
            font-weight: $fw-medium;
            opacity: 0.9;
          }

          @media (max-width: 400px) {
            font-size: 10px;
          }
        }
      }
    }
  }

  &.editor {
    height: 64px;
    background-color: $color--darks-black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;

    .footer-logo {
      margin-top: -10px;
      display: block;
    }

    .editor-footer-actions {
      display: flex;
      align-items: center;
      justify-content: center;

      &__autosave {
        margin-right: 16px;
      }
    }
  }

  &__anonymous {
    height: 231px;
    background-color: $color--blue-main;
    color: $color--white;
    padding-top: 35px;

    &__info {
      position: absolute;
      bottom: -120px;
      width: 300px;
      white-space: nowrap;
      font-size: 10px;

      u {
        cursor: pointer;
        padding-left: 4px;
      }

      u:hover {
        color: $color--teal-main;
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 90%;
      margin: 5px auto;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      &--flex {
        display: flex;
        justify-content: space-evenly;
        width: 70%;
        margin-left: 20px;

        @media (max-width: 768px) {
          width: 430px;
          flex-wrap: wrap;
          margin: 30px auto 10px;
          justify-content: space-between;
        }
      }

      &--info {
        border-top: 1px solid $color--white;
        min-width: 145px;
        font-size: 10px;
        font-weight: 600;

        &--info,
        &--links,
        &--contacts {
          display: flex;
          flex-direction: column;
          flex-basis: 144px;
          border-top: 2px solid $color--white-inactive;
          margin: 10px 0;
        }

        &--block {
          margin-top: 15px;

          a {
            display: block;
            text-decoration: none;
            font-size: 13px;
            color: $color--white;
          }

          a:hover {
            color: $color--teal-main;
          }
        }

        &--terms {
          width: 100%;
          margin: 15px auto;

          @media (max-width: 768px) {
            width: 430px;
          }
        }
      }
    }
  }

  .footer-editor-button {
    color: $color--white;
    font-size: 16px;
    font-weight: $fw-bold;
    text-align: center;
    letter-spacing: -0.32px;
    max-width: 300px;

    &.cursored {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 991px) {
  .app-footer {
    &__anonymous {
      height: auto;
      padding-top: 20px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .app-footer.main {
    .main-nofix {
      margin-bottom: 0;
    }

    .main-fixed {
      display: none;
    }
  }
}

.expired-footer {
  margin-bottom: 0 !important;
}
