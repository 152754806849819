@import "../../../assets/styles/base/fonts";

.WidgetTextUI {
  padding: 5px;

  width: 100%;
  border-radius: 9px;
  -ms-word-break: break-word;
  word-break: break-word;
  font-size: 16px;
  font-family: $font-family-message;
  line-height: 24px;

  &--placeholder {
    font-family: $font-family-message;
    line-height: 24px;
    font-size: 16px;
    padding: 5px;
  }

  &::placeholder {
    margin-bottom: 15px;
  }

  span {
    background-color: unset !important;
  }

  > * {
    line-height: initial;
  }

  * {
    background-color: unset !important;
  }

  h6,
  p,
  pre {
    margin: 0 0 4px !important;
    min-height: 1.4285em;
    line-height: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 4px !important;
    min-height: 1.2857em;
    font-family: $font-family-message;
  }

  blockquote {
    margin-block-start: 7px;
    margin-block-end: 7px;
    min-height: 1.4285em;
  }
}
