.footer-logo {
  background-image: svg-load("../svg/footer-logo.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  height: 60px;
  width: 151px;
  margin: 0 auto;
  background-size: 100%;
  display: block;
}

@media (max-width: 700px) {
  .footer-logo {
    top: initial;
  }
}
