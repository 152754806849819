.account-settings-container {
  margin: 0 52px;
  max-width: 1440px;

  @media (min-width: 1544px) {
    &.ris-container {
      margin: 0 auto;
    }
  }

  .ui.header {
    font-size: 26px;

    @media (max-width: 992px) {
      font-size: 24px;
    }
  }

  &__statistics-header {
    color: $color--darks-black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: $fw-medium;

    .row {
      padding: 4px 0 !important;
    }
  }

  &__statistics-value {
    font-size: 14px;
  }

  &.ris-container {
    margin-bottom: 0;
  }
}

.messages-container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 50px;

  @media (max-width: 875px) {
    .spinner__content {
      min-height: calc(100vh - 475px);
    }
  }

  > div:first-child {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ui.header {
      margin-bottom: 0;
      margin-right: 15px;

      @media (max-width: 480px) {
        margin-left: 0.5em;
      }
    }

    > div {
      @media (max-width: 768px) {
        width: 100%;
      }

      > div {
        display: flex;
        justify-content: flex-end;
        min-width: 234px;

        @media (max-width: 768px) {
          justify-content: space-between;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 17px;

    @media (max-width: 450px) {
      padding: 0 10px;
    }
  }
}

.table-page-container {
  margin: 0 20px !important;
  overflow: hidden;

  > .ui.grid {
    margin: 0 !important;
  }

  @media (max-width: 991px) {
    margin: 0 15px !important;

    @media (max-width: 767px) {
      margin: 0 7.5px !important;
    }
  }

  &.ris-container > .ui.header {
    margin-bottom: 0;
    font-size: 26px !important;

    @media (max-width: 992px) {
      font-size: 24px !important;
    }
  }

  &.manager {
    width: 100%;
  }
}

.userAccess-table {
  width: 100%;
}

.container-centered {
  max-width: 1440px;
  margin: 0 auto;
}
