.ColorPicker {
  align-items: center;
  background: #FFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: calc(200px + 1rem);
  box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
  z-index: 10;
  right: 0;
  top: 135%;

  &--absolute {
    position: absolute;
  }

  &__input-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 10px;
  }

  &__input {
    border: 1px solid $color--grey-border;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
    font-family: $font-family;
    font-size: 14px;
    font-weight: $fw-medium;
    padding: 0.5rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    margin-top: 1rem;
    width: 100%;
  }

  &__presets {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 1rem;
    justify-content: center;
    padding-top: 1rem;
    border-top: 1px solid $color--grey-border;
  }

  &__preset-color {
    $preset-color-size: 20px;
    border: 1px solid $color--grey-border;
    cursor: pointer;
    width: $preset-color-size;
    height: $preset-color-size;
  }
}
