@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.VideoWidgetController {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__controller-body {
    padding: 17px;
    max-width: 934px;
    margin: 0 auto;
    width: 100%;
  }

  &__video-player-wrapper {
    padding: 14px;
  }

  &__input-wrapper {
    margin-bottom: 24px;
  }

  &__label-text {
    font-size: 14px;
    font-weight: 600;
    color: $color--accent-obsidian;
  }

  &__label-text-alternative {
    color: $color--darks-black;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    font-family: $font-family;
  }

  &__button-wrapper {
    gap: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
}
