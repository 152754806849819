.editor {
  &__drag {
    i {
      display: none;
    }
  }

  &__drag:hover {
    border: 5px solid $color--teal-dark;
    padding-bottom: 0;

    i {
      display: inline-flex;
      float: right;
    }
  }

  &__header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .material-icons {
      margin-left: 5px;
    }
  }
}

.editor-grid-container {
  height: calc(100vh - 120px);
  margin: 0 !important;

  .editor-view {
    .dividing.header {
      color: $color--teal-main;
      padding: 0 0 1em 1.5em;

      > i {
        float: right;
        color: $color--darks-black;
      }
    }
  }

  .ui.divider {
    margin: 0 auto !important;
  }
}

.editor-toolbox {
  box-shadow: -2px 0 13px 0 rgba(0, 0, 0, 0.26);
  overflow-y: auto;
  padding: 0 !important;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;

    &__header {
      border-bottom: 2px solid rgba(34, 36, 38, 0.15);

      .ui.header {
        padding: 20px 30px;
        margin-top: 5px;
        color: $color--teal-main;
      }

      .default-menu-header {
        display: flex;
        font-size: 20px;
        font-family: $font-family-headline;
        padding: 20px 30px;

        .menu-item {
          width: 50%;
          text-align: center;
        }

        .pointer:hover {
          background-color: $color--blue-light;
          color: $color--white;
        }

        .pointer {
          background-color: $color--white;
          border: 2px solid $color--blue-light;
          color: $color--blue-light;
          padding: 7px 5px;
          font-family: $font-family;
          display: block;
          margin: auto;
          letter-spacing: -0.5px;
          cursor: pointer;

          &--active {
            background-color: $color--blue-light;
            color: $color--white;
          }
        }

      }
    }

    &__content {
      overflow: auto;
      padding: 17px;

      .editor-toolbar-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-width: 320px;

        > div {
          display: flex;
        }

        @media (min-width: 991px) {
          > div {
            width: 100%;
            justify-content: space-evenly;
          }
        }

        @media (max-width: 991px) {
          overflow-x: auto;

          .toolbar-item {
            min-width: 80px;
            height: 80px;

            > i {
              font-size: 40px !important;
              margin-bottom: 5px;
            }

            .text {
              font-size: 13px;
              line-height: 18px;
            }
          }
        }
      }

      .ris-form--editor {
        .headline {
          padding: 20px 0;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: $color--teal-main;
        }

        .subheadline {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
        }

        .field {
          line-height: 4;
        }

        .choose-button .icon {
          margin-left: 5px !important;
        }

        &__picture-size {
          width: 100%;
          display: flex;
          padding: 1rem;
          align-items: center;

          &--rating {
            display: flex;
            flex-direction: column;

            input[type="range"] {
              appearance: none;
              margin: 18px 0;
              width: 100%;
              border-radius: 2.5px;
            }

            input[type="range"]:focus {
              outline: none;
            }

            input[type="range"]::-webkit-slider-runnable-track {
              width: 100%;
              height: 8.4px;
              cursor: pointer;
              background: $color--blue-main;
              border-radius: 1.3px;
              border: 0.2px solid $color--almost-black2;
            }

            input[type="range"]::-webkit-slider-thumb {
              appearance: none;
              box-shadow: 0 0 6px 0 $color--25;
              border: 1px solid $color--03;
              height: 32px;
              width: 32px;
              border-radius: 50%;
              background: $color--white;
              cursor: pointer;
              margin-top: -12px;
            }

            input[type="range"]:focus::-webkit-slider-runnable-track {
              background: $color--blue-main;
            }

            input[type="range"]::-moz-range-track {
              width: 100%;
              height: 8.4px;
              cursor: pointer;
              box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
              background: $color--teal-main;
              border-radius: 1.3px;
              border: 0.2px solid $color--03;
            }

            input[type="range"]::-moz-range-thumb {
              box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
              border: 1px solid $color--03;
              height: 20px;
              width: 20px;
              border-radius: 3px;
              background: $color--white;
              cursor: pointer;
            }

            input[type="range"]::-ms-track {
              width: 100%;
              height: 8.4px;
              cursor: pointer;
              background: transparent;
              border-color: transparent;
              border-width: 16px 0;
              color: transparent;
            }

            input[type="range"]::-ms-fill-lower {
              background: $color--teal-light;
              border: 0.2px solid $color--almost-black2;
              border-radius: 2.6px;
              box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--very-black;
            }

            input[type="range"]::-ms-fill-upper {
              background: $color--teal-light;
              border: 0.2px solid $color--almost-black2;
              border-radius: 2.6px;
              box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
            }

            input[type="range"]::-ms-thumb {
              box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
              border: 1px solid $color--03;
              height: 20px;
              width: 20px;
              border-radius: 3px;
              background: $color--white;
              cursor: pointer;
            }

            input[type="range"]:focus::-ms-fill-lower {
              background: $color--teal-light;
            }

            input[type="range"]:focus::-ms-fill-upper {
              background: $color--teal-light;
            }
          }
        }

        &--upload-video {
          width: 100%;
          display: flex;
          padding: 1rem;
          align-items: center;

          @media (max-width: 510px) {
            flex-wrap: wrap;

            .ui.button {
              font-size: 14px !important;
              white-space: nowrap !important;
              margin-top: 8px;
            }
          }
        }

        &__video {
          width: 100%;
          cursor: pointer;

          .video-js {
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            .vjs-big-play-button {
              top: calc(50% - 1.25em);
              left: calc(50% - 1.25em);
              height: 2.5em;
              width: 2.5em;
              background: svg-load('../svg/play-icon.svg') no-repeat;
              background-size: contain;
              border: none;
              box-shadow: none;

              > span::before {
                content: "";
              }
            }
          }
        }

        &__block {
          &--inline {
            display: flex;
            align-items: center;
            padding: 3px 0 20px;

            .headline {
              white-space: nowrap;
              margin-right: 1rem;
            }

            .content {
              width: 100%;
            }
          }

          .headline {
            display: flex;
            justify-content: space-between;
          }

          .content {
            display: none;

            &.active {
              display: block;
            }
          }

          .field {
            line-height: normal;
          }
        }

        .add-input-field {
          cursor: pointer;
          padding: 10px 0;
          display: flex;
          align-items: center;
          color: $color--teal-main;
          font-weight: $fw-bold;

          &::before {
            background: svg-load('../svg/add_teal.svg') no-repeat;
            background-size: contain;
            height: 14px;
            width: 14px;
            margin-right: 11px;
            content: "";
          }
        }

        .input-field {
          position: relative;
          display: grid;
          grid-template-columns: 14px 1fr 2fr;
          column-gap: 14px;
          align-items: center;
          justify-content: center;
          padding: 20px 10px;
          background-color: $color--grey-input;

          &:last-child {
            margin-bottom: 10px;
          }

          @media (max-width: 768px) {
            grid-template-columns: 14px 1fr;

            span {
              grid-row: 1/3;
            }

            div:last-child {
              grid-column: 2;
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      margin: auto 0 0;
      border-top: 2px solid rgba(34, 36, 38, 0.15);
      padding: 20px 30px;
      background: $color--accent-splashedwhite;

      span {
        font-weight: $fw-medium;
        font-size: 18px;
        margin-left: 1em;
        cursor: pointer;
      }

      .ui.button {
        margin-right: 1em;
        height: 40px;
        width: 92px;
        font-size: 18px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }
  }
}

.editor-view-tablet {
  //background: url('../png/tablet.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 50vh;
  min-height: 403px;
  max-height: 680px;
  width: 95%;
  min-width: 485px;
  max-width: 70vh;
  border-top: 3vh solid $color--darks-black;
  border-bottom: 3vh solid $color--darks-black;
  border-left: 5vh solid $color--darks-black;
  border-right: 5vh solid $color--darks-black;
  border-radius: 2vh;
  margin: 10px auto;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .tablet-content {
    overflow-y: scroll;
    height: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
  }
}

.editor-view-mobile {
  background-image: svg-load('../svg/iphonex.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 72vh;
  margin: 10px auto;
  width: 36vh;
  max-height: 1000px;
  max-width: 500px;
  padding-left: 2.4vh !important;
  padding-right: 2.4vh !important;
  padding-top: 5vh;
  padding-bottom: 2.2vh;

  .mobile-content {
    overflow-y: auto;
    height: 100%;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 2.8vh;
    border-bottom-right-radius: 2.8vh;
  }
}

.editor-view-desktop {
  width: 100%;
  min-height: calc(100% - 82px);
  max-width: 1440px;
  margin: 0 auto;

  .desktop-content {
    overflow-y: auto;
    padding: 10px;
    width: 100%;
    margin: 0;
    min-height: calc(96vh - 215px);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.dragging {
  cursor: grabbing !important;
}

.toolbar-item {
  background-color: $color--white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: grab;
  height: 110px;
  max-width: 120px;
  min-width: 120px;
  margin: 10px 15px;
  border: none;
  outline: none;

  @media (max-width: 991px) {
    cursor: pointer;
  }

  .text {
    color: $color--darks-martinique;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  &:hover {
    background-color: $color--blue-celeste;

    > .widget-icon {
      color: $color--white;
    }
  }

  .widget-icon {
    font-size: 45px;
    color: $color--blue-cyan;
  }
}

.editor-toolbar-button {
  color: #87CEFA;
  font-size: 45px;
  margin-bottom: 10px;
}

.droppable-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .empty-block-content {
    width: 99%;
    min-height: 100px;
    background-color: $color--white-splashed;
    margin: 0 0 15px;
    padding: 10px;
    background-image:
      linear-gradient(to right, $color--very-black 50%, transparent 50%),
      linear-gradient(to right, $color--very-black 50%, transparent 50%),
      linear-gradient(to bottom, $color--very-black 50%, transparent 50%),
      linear-gradient(to bottom, $color--very-black 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 24px 1.5px, 24px 1.5px, 1.5px 24px, 1.5px 24px;

    /* &::after {
      background-image: svg-load('../svg/dragndrop.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 50px;
      width: 100px;
      content: '';
      position: absolute;
      right: 2px;
      top: 120px;
    } */
    // TODO: finish dragndrop image appearance
    i.material-icons {
      font-size: 54px;
      text-align: center;
      width: 100%;
      margin-top: 15px;
    }

    .text {
      margin: 20px;
      font-size: 18px;
      color: $color--darks-black;
      text-align: center;
    }
  }
}

.empty-block-dragging {
  border-color: $color--blue-royal !important;
  background-color: #87CEFA2E !important;
  min-height: 40px !important;
  transition: min-height 0.4s ease-out;

  > .text,
  > .material-icons {
    display: none;
  }

  > i {
    color: $color--blue-royal;
  }

  > div {
    color: $color--blue-royal !important;
  }
}

.survey {
  &__question {
    background-color: $color--accent-koala;
    margin: 20px -30px;
    padding: 20px 30px;
    border: 1px solid $color--accent-spindle;
  }

  &-checkboxes {
    min-width: 24px;
    min-height: 24px;
    background: svg-load('../svg/new-check-inactive.svg') no-repeat;
    background-size: contain;
    margin-left: 5px;
  }

  &-radios {
    min-width: 24px;
    min-height: 24px;
    background: svg-load('../svg/new-check-inactive-empty.svg') no-repeat;
    background-size: contain;
    margin-left: 5px;
  }

  &--quiz {
    cursor: pointer;

    &.selected-quiz-answer {
      min-width: 24px;
      min-height: 24px;
      background: svg-load('../svg/new-check.svg') no-repeat;
      background-size: contain;
      margin-left: 5px;
    }
  }
}

.margin {
  margin: 15px;
}

.ui.divider.close-divider {
  cursor: pointer;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  border-top: 2px solid rgba(34, 36, 38, 0.15) !important;
  pointer-events: none;
}

.plus-button-row {
  padding-right: 1em !important;
  height: 107px;
  margin-top: auto;
  bottom: 50px;
  position: fixed !important;

  > div {
    margin-left: auto;
  }
}

.video-thumbnail .material-icons,
.image-thumbnail .material-icons {
  position: relative;
  left: 24px;
  cursor: pointer;
}

.survey-question-box {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .ui.grid {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }

  .row {
    width: 100%;
  }
}

.full-width {
  width: 100%;
}

.content-width {
  width: fit-content;
}

.spaced-20 {
  margin-bottom: 20px;
}

.spaced-10 {
  margin-bottom: 10px;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

.video-js.vjs-controls-enabled .vjs-big-play-button {
  display: none;
}

.video-js.vjs-controls-enabled.vjs-paused .vjs-big-play-button {
  display: block;
}

.DraftEditor-root {
  height: 172px !important;
}

.DraftEditor-editorContainer {
  .public-DraftStyleDefault-block {
    * {
      line-height: initial;
    }
  }
}

.vjs-error .vjs-error-display .vjs-modal-dialog-content {
  display: none;
}

.video-js .vjs-modal-dialog::before {
  display: none;
}

.add-another {
  color: $color--teal-main;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;

  span {
    display: flex;
    align-items: center;
  }
}

.relatived {
  position: relative;
}

.editor-toolbox-form {
  padding: 0 13px 20px;

  &__picture {
    display: flex;
    flex-wrap: wrap;

    &__wraper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 150px !important;
      margin-right: 24px;

      img {
        height: auto;
        width: auto;
        max-height: 100px;
        max-width: 150px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 340px;
      margin-top: 20px;
      width: 50%;
      margin-bottom: 12px;

      span {
        color: $color--darks-black;
        font-family: $font-family;
        font-size: 16px;
        font-weight: $fw-medium;
      }

      @media (max-width: 500px) {
        min-width: 280px;
      }
    }

    &__buttons {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 380px;
      margin-top: 10px;

      > span {
        margin: 0 20px;
      }

      button.ui.button {
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          height: 40px;
          font-size: 16px;
          display: flex;
          align-items: center;
          min-width: 165px;
        }
      }

      @media (max-width: 430px) {
        > span {
          margin: 0 10px;
        }

        button.ui.button {
          &:first-child {
            font-size: 14px;
            height: 36px;
            min-width: 95px;
          }

          &:last-child {
            font-size: 14px;
            height: 36px;
            min-width: 140px;

            > i {
              margin-right: 8px;
              font-size: 18px;
            }
          }
        }
      }

      @media (max-width: 360px) {
        > span {
          margin: 0 5px;
        }

        button.ui.button {
          &:first-child {
            font-size: 12px;
            height: 30px;
            min-width: 70px;
          }

          &:last-child {
            font-size: 12px;
            height: 30px;
            min-width: 120px;

            > i {
              margin-right: 6px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  &__file-content {
    > .ui.label {
      min-width: unset !important;
    }

    .attachment-block__box {
      width: 100% !important;
      justify-content: flex-start !important;

      .attachment-block__item {
        width: unset !important;
        min-width: 200px;
        max-width: 200px;
        margin-right: 20px !important;
      }
    }
  }

  &__optin-buttons {
    > div:first-child {
      padding-top: 0;
    }

    > div {
      padding: 10px 0;
      border-bottom: 1px solid $color--grey-border;
    }

    > div:last-child {
      padding-bottom: 20px;
      border-bottom: none;
    }

    .editor-input {
      .ui.label.label {
        color: $color--darks-brown;
      }
    }
  }

  &__button {
    .field.input {
      margin-bottom: 20px;

      &:first-child {
        margin-bottom: 30px;
      }
    }

    &__field {
      padding: 15px 0;
      display: flex;
    }
  }

  @media (max-width: 991px) {
    &__tablet {
      margin: 0 auto;
    }
  }

  .color-field {
    padding: 0;

    .ui.label {
      font-family: $font-family;
      color: $color--darks-brown;
      font-weight: $fw-medium;
      white-space: nowrap;
    }
  }

  .editor-question-select {
    display: flex !important;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;
    flex-wrap: wrap;

    &__first {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 20px;
      margin-right: 10px;
      align-items: center;

      &__reorder {
        margin-right: 38px;
        display: flex;
        height: 100%;
        align-items: center;

        &__up {
          background: svg-load('../svg/survey-reorder.svg') no-repeat;
          height: 24px;
          width: 24px;
          transform: rotate(270deg);
          cursor: pointer;

          &--disabled {
            background: svg-load('../svg/survey-reorder-disabled.svg') no-repeat;
            height: 24px;
            width: 24px;
            transform: rotate(270deg);
          }
        }

        &__down {
          background: svg-load('../svg/survey-reorder.svg') no-repeat;
          height: 24px;
          width: 24px;
          transform: rotate(90deg);
          cursor: pointer;

          &--disabled {
            background: svg-load('../svg/survey-reorder-disabled.svg') no-repeat;
            height: 24px;
            width: 24px;
            transform: rotate(90deg);
          }
        }
      }
    }

    &__reorder {
      margin-right: 38px;
      display: flex;

      &__up {
        background: svg-load('../svg/survey-reorder.svg') no-repeat;
        height: 24px;
        width: 24px;
        transform: rotate(90deg);

        &--disabled {
          background: svg-load('../svg/survey-reorder-disabled.svg') no-repeat;
          height: 24px;
          width: 24px;
          transform: rotate(90deg);
        }
      }

      &__down {
        background: svg-load('../svg/survey-reorder.svg') no-repeat;
        height: 24px;
        width: 24px;
        transform: rotate(270deg);

        &--disabled {
          background: svg-load('../svg/survey-reorder-disabled.svg') no-repeat;
          height: 24px;
          width: 24px;
          transform: rotate(270deg);
        }
      }
    }

    &__required {
      .field.input {
        line-height: unset;
      }
      margin-bottom: 20px;
    }

    &__qid {
      color: $color--blue-main;
      font-family: $font-family;
      font-size: 18px;
      font-weight: $fw-bold;
      line-height: 24px;
      margin-right: 18px;
    }

    > div {
      .ui.selection.visible.dropdown > .text:not(.default) {
        font-weight: $fw-medium;
      }

      .ui.active.visible.selection.dropdown {
        box-shadow: none !important;
      }

      .visible.menu.transition,
      .visible.menu.transition:hover {
        box-shadow:
          0 4px 5px 0 rgba(0, 0, 0, 0.14),
          0 1px 10px 0 rgba(0, 0, 0, 0.12),
          0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
        max-height: 200px;

        .active.selected.item {
          color: $color--blue-cerulean !important;
        }

        .item:hover {
          color: $color--blue-cerulean !important;
        }
      }

      .ui.active.visible.selection.dropdown:hover {
        .visible.menu.transition,
        .visible.menu.transition:hover {
          box-shadow:
            0 4px 5px 0 rgba(0, 0, 0, 0.14),
            0 1px 10px 0 rgba(0, 0, 0, 0.12),
            0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
  }

  .editor-question {
    &__open {
      &__answer {
        .ui.input input {
          color: $color--darks-dark-accent;
          font-weight: $fw-medium;
          font-size: 14px;
          font-style: italic;
        }
      }
    }

    &__likedislike {
      &__question {
        margin-bottom: 10px;
      }

      &__answer {
        padding-top: 0;
        display: flex !important;

        .thumbup {
          background: svg-load('../svg/thumbup_active.svg') no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
          border: 0;
          margin-right: 35px;
          cursor: pointer;
        }

        .thumbdown {
          background: svg-load('../svg/thumbdown_active.svg') no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
          border: 0;
          cursor: pointer;
        }
      }
    }

    &__multiple {
      .ui.input {
        width: 100%;
      }

      &__question {
        .ui.input input {
          padding-left: 0;
        }
      }

      &__answer {
        justify-content: space-between;
        height: auto !important;

        .field.input {
          width: 92%;
          line-height: 3.5;

          input {
            padding-left: 0;
          }
        }

        > div > .material-icons {
          cursor: pointer;
        }

        .row:last-child {
          span {
            color: $color--blue-cerulean;
            font-size: 14px;
            font-weight: $fw-bold;
            cursor: pointer;

            span {
              font-size: 25px;
              vertical-align: text-bottom;
              position: relative;
              top: 2px;
              margin-right: 14px;
            }
          }
        }
      }
    }

    &__rating {
      .ui.input {
        width: 100%;
      }

      &__question {
        .ui.input input {
          border-bottom: 2px solid $color--teal-main;
          font-weight: $fw-medium;
          padding-left: 0;
        }
      }

      &__answer {
        width: 100%;
        margin: 0 0 0 auto;

        > small {
          font-size: 12px;
          font-style: italic;
          float: right;
        }

        .field.input {
          padding-bottom: 1rem;

          .ui.labeled {
            flex-direction: row;
            align-items: baseline;

            > div {
              font-size: 16px;
            }

            input {
              width: 123px;
            }

            > input::placeholder {
              color: $color--blue-wildblue;
              font-style: normal;
            }
          }

          .input-error-icon {
            top: 10px !important;
            bottom: unset;
          }
        }
      }
    }
  }

  small {
    color: $color--darks-black;
    display: inline-block;
    font-size: 14px;
    font-weight: $fw-medium;
    min-width: 156px;
    font-family: $font-family;
  }

  .ui.label {
    font-size: 14px !important;
    font-weight: $fw-medium;
    color: $color--accent-obsidian;
    margin-right: 15px;
    min-width: 142px;
  }

  .color-field {
    align-items: center;
    display: flex;
    flex-direction: row;

    .color-input {
      border: 0;
      font-family: $font-family;
      font-weight: $fw-medium;
      font-size: 14px;
      width: 88px;
      outline: none;
    }

    .color-block {
      border: 1px solid $color--white-inactive;
      border-radius: 4px;
      display: inline-block;
      height: 20px;
      margin-right: 5px;
      padding-right: 8px;
      width: 20px;
      min-width: 20px;
    }
  }

  .editor-select-column {
    display: flex;

    > div:nth-child(2) {
      display: inline-block;

      .ui.selection.dropdown {
        min-width: 6em;
      }
    }
  }

  .ui.toggle.checkbox .box::before,
  .ui.toggle.checkbox label::before,
  .ui.toggle.checkbox input:focus ~ .box::before,
  .ui.toggle.checkbox input:focus ~ label::before {
    background-color: $color--white-silver !important;
  }

  .ui.toggle.checkbox input:checked ~ .box::before,
  .ui.toggle.checkbox input:checked ~ label::before {
    background-color: $color--teal-light !important;
    height: 13px;
    top: 5px;
  }

  .ui.toggle.checkbox .box::before,
  .ui.toggle.checkbox label::before,
  .ui.toggle.checkbox input:focus ~ .box::before,
  .ui.toggle.checkbox input:focus ~ label::before {
    height: 13px;
    top: 5px;
  }

  .ui.toggle.checkbox input:checked ~ .box::after,
  .ui.toggle.checkbox input:checked ~ label::after {
    background-color: $color--teal-main;
  }

  .ui.toggle.checkbox label {
    color: $color--darks-brown;
    font-size: 16px;
    font-weight: $fw-medium;
  }

  &__required-delete {
    display: flex !important;
    padding-top: 0 !important;
    justify-content: space-between;
    align-items: baseline;
  }

  .keywords-header {
    padding: 1em !important;
  }

  .keywords-available {
    padding: 0 1em 1em !important;
  }

  &__textarea {
    background-color: $color--grey-input;
    min-height: 200px;
    max-height: 350px;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.2);
    font-size: 16px;
    font-family: $font-family-message;

    > div > div > div > div {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      pre,
      div {
        margin: 0 0 4px !important;
        font-family: $font-family-message;
      }

      blockquote {
        margin-block-start: 7px;
        margin-block-end: 7px;
      }

      .public-DraftStyleDefault-block {
        margin: 0 !important;
      }
    }
  }
}

.editor-preview {
  overflow-y: auto;
  height: calc(100vh - 120px);
}

.editor-toolbar {
  overflow-y: auto;
  height: calc(100vh - 120px);
}

.editor-input.field.input {
  position: relative;
}
