.anonymous-logo {
  background-image: svg-load("../svg/footer-logo.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  height: 40px;
  width: 151px;
  background-size: 100%;
  display: block;
}

@media only screen and (max-width: 767px) {
  .anonymous-logo {
    width: 430px;
    margin: 0 auto;
    background-size: contain;
    background-position: initial;
  }
}
