.ui.header {
  font-family: $font-family-headline;
  font-size: 26px;
  padding: 1em 0;

  &.analytics {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.ui.header.forgot-text {
  color: $color--blue-main;
  font-family: $font-family;
  font-size: 14px;
  font-style: italic;
  font-weight: $fw-book;
  margin-top: -25px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
}

.dropdown-header-nav.ui.dropdown {
  display: flex;
  align-items: center;
  height: 33px;
  background-color: transparent !important;
  border: 0;
  box-shadow: none;
  max-width: 100%;

  .menu.transition.visible {
    z-index: 15;
  }
}

.account-navigation-menu-desktop {
  float: right;

  &.menu {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
}

.editor-modal {
  .ui.dividing.header {
    color: $color--teal-main;
    font-family: $font-family-headline;
    font-size: 20px;
    font-weight: $fw-bold;
    padding: 17px 20px 10px 10px;

    i {
      color: $color--darks-black;
      float: right;
    }
  }
}
