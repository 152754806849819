@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.ColorSelector {
  display: flex;
  align-items: center;
  position: relative;

  &__color-selector-cta {
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  &__label {
    font-family: $font-family;
    color: $color--darks-brown;
    font-weight: 600;
    white-space: nowrap;
    font-size: 14px;
    margin-right: 15px;
  }

  &__color-info-container {
    display: flex;
    align-items: center;
  }

  &__current-color-display {
    border: 1px solid $color--white-inactive;
    border-radius: 4px;
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    padding-right: 8px;
    width: 20px;
    min-width: 20px;
  }

  &__current-color-code {
    border: 0;
    font-family: $font-family;
    font-weight: 600;
    font-size: 14px;
    outline: none;
  }

  &__color-palette-container {
    position: absolute;
    display: flex;
    border: 1px solid #D4D4D5;
    border-radius: 4px;
    background-color: $color--white;
    flex-direction: column;
    max-width: 216px;
    padding: 8px;
    width: 100%;
    z-index: 60;
    left: 30%;

    @media (min-width: 415px) {
      left: 40%;
    }
  }

  &__input-container {
    display: flex;
    justify-content: center;
  }

  &__color-input {
    border: 1px solid $color--grey-border;
    border-radius: 8px;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    padding: 0.5rem;
    margin: 14px 0;
  }

  &__cta-button {
    border: none;
    padding: 11px 21px;
    font-weight: 700;
    font-size: 14px;
    background-color: #2185D0;
    color: $color--white;
    text-shadow: none;
    background-image: none;
    font-family: $font-family;
    border-radius: 8px;
    margin-bottom: 14px;
    cursor: pointer;
  }
}
