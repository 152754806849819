.OptInWidgetController {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__controller-body {
    display: flex;
    flex-direction: column;
    padding: 17px;
    overflow-y: auto;
    max-width: 934px;
    margin: 0 auto;
    width: 100%;
  }
}
