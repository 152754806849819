@import '../../../assets/styles/base/variables-color';

.AutomationEditorView {
  height: calc(100vh - 129px);

  .EditorViewHeader__input-container {
    display: none;
  }

  &__editor-layout {
    display: flex;
    flex-direction: column;
  }

  &__editor-preview-side {
    height: calc(100vh - 129px);
    width: 100%;
    padding: 16px;
  }

  &__widget-controller-side {
    box-shadow: -2px 0 13px 0 rgba(0, 0, 0, 0.26);
    overflow-y: auto;
    width: 100%;
    height: auto;
    background: $color--white;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1px solid #22242626;
    box-sizing: border-box;

    &--active-element {
      background-color: rgba(0, 0, 0, 0.85);
      height: calc(100vh - 120px);
      display: flex;
    }

    .OptInWidgetController,
    .SurveyWidgetController,
    .VideoWidgetController {
      height: calc(100vh - 128px);
      overflow-y: auto;
    }
  }

  &__toolbar-wrapper {
    width: 100%;
    background: $color--white;
    margin: auto;
    border-radius: 8px;

    div {
      border-radius: 8px;
    }

    @media (min-width: 400px) {
      width: 88%;
    }
  }

  &__display-toolbar-button {
    background-color: $color--teal-light;
    border-radius: 35px;
    border: none;
    position: fixed;
    right: 30px;
    max-height: 70px;
    max-width: 70px;
    height: 70px;
    width: 70px;
    min-height: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color--white;
    cursor: pointer;
    bottom: 90px;
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.14),
      0 3px 4px 0 rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: $color--teal-main;
    }

    p {
      font-size: 40px;
      margin-bottom: 4px;
    }
  }

  &__hide-toolbar-button {
    position: absolute;
    right: 6px;
    margin-top: 6px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  &__automation-cta-section {
    display: flex;
    align-items: center;
    gap: 8px;
    position: fixed;
    right: 20px;
  }

  &__automation-save-button {
    display: flex;
    align-items: center;
    justify-self: center;
    background-color: $color--white;
    border: 2px solid $color--blue-main;
    border-radius: 8px;
    color: $color--blue-main;
    cursor: pointer;
    padding: 10px 36px;
  }

  &__automation-close-button {
    display: block;
    height: 32px;
    width: 32px;
    cursor: pointer;
    border: none;
    background: transparent;

    div {
      height: 32px;
      width: 32px;
    }
  }

  .ui.toggle.checkbox input:checked ~ label::before {
    background-color: $color--teal-light !important;
    height: 13px;
    top: 5px;
  }

  .WidgetsDesign {
    .ColorSelector__color-palette-container {
      position: static;
    }
  }
}
