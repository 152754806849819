.checkbox-label {
  background-color: $color--white-inactive;
}

.checkbox-span {
  label input {
    display: none;
  }

  label [type="checkbox"] + span {
    cursor: pointer;
    position: absolute;
    border: double 2px $color--darks-black;
    background-color: $color--white;
    color: $color--darks-black;
    white-space: nowrap;
    width: 19px;
    height: 19px;
    border-radius: 3px;
    display: inline-block;
    margin-top: 3px;
  }

  input:checked + span::before {
    content: '';
    position: initial;
    width: 11px;
    height: 7px;
    background: transparent;
    display: inline-block;
    border: 3px solid $color--darks-black;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    margin-bottom: 5px;
    margin-left: 2px;
  }

  > span {
    font-size: 18px;
    font-family: $font-family;
    font-weight: $fw-medium;
    line-height: 24px;
    padding-left: 30px;
  }
}

.span-font {
  font-size: 18px;
  font-family: $font-family;
  font-weight: $fw-medium;
  line-height: 24px;

  > span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.checkbox-span2 {
  label input {
    display: none;
  }

  label [type="checkbox"] + span {
    cursor: pointer;
    position: absolute;
    border: double 2px $color--darks-black;
    background-color: $color--white;
    color: $color--darks-black;
    white-space: nowrap;
    width: 19px;
    height: 19px;
    border-radius: 3px;
    display: inline-block;
    margin-top: 3px;
  }

  input:checked + span::after {
    content: '';
    position: initial;
    width: 11px;
    height: 7px;
    background: transparent;
    display: inline-block;
    border: 3px solid $color--darks-black;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    margin-bottom: 5px;
    margin-left: 2px;
  }

  > span {
    font-size: 18px;
    font-family: $font-family;
    font-weight: $fw-medium;
    line-height: 24px;
    padding-left: 30px;
  }
}

.registration-padding {
  margin-top: 15px;
}

.company-list {
  display: flex;
  flex-direction: column;
  margin: 2em auto;
}

.registration {
  &__label {
    color: $color--darks-black;
    font-size: 16px;
    font-weight: $fw-medium;
  }

  &__card-flex {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;

    &--input {
      padding: 10px 5px;
      width: 100%;
      box-shadow: 1px 1px 4px 2px $color--label-shadow;
    }
  }
}

.registration-form {
  margin-top: -50px !important;

  > div {
    padding-top: 0 !important;

    > div {
      padding-top: 0 !important;

      > div {
        padding-top: 0 !important;
      }
    }
  }

  &__list-wrapper {
    display: flex;
    justify-content: center;
  }
}
