.sms-pricing {
  &__back {
    font-size: 14px;
    font-weight: 600;
    margin-left: 50px;
    color: $color--darks-black;
    margin-top: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::before {
      content: '';
      background: svg-load('../svg/arrow-blue.svg');
      background-size: contain;
      height: 14px;
      width: 9px;
      margin-right: 5px;
      transform: rotate(180deg);
    }

    @media (max-width: 500px) {
      margin-left: 25px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--logo {
      margin-top: 31px;
      display: flex;
      margin-bottom: 30px;

      .title {
        font-size: 36px;
        color: $color--blue-main;
        font-weight: $fw-bold;
        line-height: 46px;
        font-family: Dosis;
      }

      .icon {
        background: svg-load('../svg/arrow-blue.svg') no-repeat;
        height: 20px;
        width: 15px;
        position: relative;
        left: 10px;
        top: 4px;
      }
    }

    &--content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto 20px;

      .info {
        color: $color--teal-main;
        font-size: 18px;
        font-style: italic;
        margin-left: 20%;

        &--modal {
          color: $color--teal-main;
          font-size: 18px;
          font-style: italic;
        }
      }

      .price {
        color: $color--blue-main;
        font-size: 16px;
        font-style: italic;
        margin-right: 114px;
      }

      @media (max-width: 769px) {
        flex-direction: column;

        .info {
          margin: 10px auto;
        }

        .price {
          margin: 10px auto;
        }

        @media (max-width: 400px) {
          transform: scale(0.9);
          margin: 0 auto 10px;

          @media (max-width: 350px) {
            .info {
              font-size: 16px;
            }

            .price {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &__body {
    box-shadow: 0 4px 5px 0 $color--14, 0 1px 10px 0 $color--blacky, 0 2px 4px 0 $color--02;

    &--button {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 694px;
      margin: 0 auto 141px;
      padding: 0 20px;
      align-items: center;

      > span {
        margin-right: 20px;
      }

      @media (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
        margin: 0 auto 80px;

        > span {
          margin-bottom: 15px;
        }
      }
    }

    &--disclaimer {
      font-size: 16px;
      font-style: italic;
      text-align: right;
      line-height: 22px;
      color: $color--blue-main;
      margin-right: 114px;

      &--modal {
        font-size: 16px;
        font-style: italic;
        text-align: right;
        line-height: 22px;
        color: $color--blue-main;
      }

      > div {
        padding-bottom: 15px;
      }

      @media (max-width: 850px) {
        margin: 0 auto;
        padding: 0 10px;
        max-width: 670px;

        @media (max-width: 450px) {
          font-size: 14px;
        }

        @media (max-width: 380px) {
          font-size: 12px;
        }
      }
    }
  }
}

.pricing {
  padding: 30px 20px 0;
  max-width: 694px;
  margin: 0 auto;

  @media (max-width: 450px) {
    padding: 30px 5px 0;
  }

  &__header {
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 16px;
    font-weight: $fw-bold;
    color: $color--darks-black;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-left: 25px;

    &__column {
      display: flex;
      align-items: center;

      .ui.dropdown {
        border: none !important;
        border-radius: 4px !important;

        * {
          border: none !important;
        }

        > input.search {
          background-color: $color--grey-input !important;
          box-shadow: 0 1px 3px 0 rgba(230, 235, 241, 0.4) !important;
          border: none !important;
          border-radius: 4px !important;
        }
      }
    }

    @media (max-width: 450px) {
      transform: scale(0.8);
      padding-left: 0;
    }
  }
}

.price {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  font-weight: $fw-medium;
  color: $color--darks-black;
  margin-left: 10px;

  &--select {
    border-radius: 4px !important;
    background-color: $color--grey-input !important;
    box-shadow: 0 1px 3px 0 $color-select-shadow !important;
    font-weight: $fw-medium !important;
    width: 200px !important;

    @media (max-width: 450px) {
      transform: scale(0.9);
    }
  }

  &--disclaimer {
    color: $color--teal-main;
    font-weight: $fw-regular;
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
  }
}

.price-delete {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 12px;

  .delete {
    background: svg-load('../svg/close.svg') no-repeat;
    height: 13px;
    width: 13px;
    cursor: pointer;
  }
}

.add-wrapper {
  display: flex;
  cursor: pointer;
  padding-left: 25px;
  margin-bottom: 15px;

  @media (max-width: 450px) {
    padding-left: 10%;
  }

  .add-more {
    color: $color--darks-black;
    font-size: 14px;
    font-weight: $fw-medium;
    cursor: pointer;
  }

  .add-icon {
    background: svg-load('../svg/add_black.svg') no-repeat;
    margin-right: 10px;
    height: 20px;
    width: 15px;
    margin-top: 4px;
  }
}

.pricing-country {
  width: 200px;
  margin-right: 10px;
}
