.ui.progress {
  .bar {
    background-color: $color--blue-light !important;
    height: 9px;
    border-radius: 15px;
    overflow: hidden;

    > .progress {
      display: none;
    }
  }
}
