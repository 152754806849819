@import "../../../../../assets/styles/base/variables-color";
@import "../../../../../assets/styles/base/fonts";

.OptInHeaderController {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-title {
    padding: 20px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color--teal-main;
  }

  &__image-data-controller {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: column;

    @media (min-width: 535px) {
      flex-direction: row;
      gap: 24px;
    }

  }

  &__image {
    height: auto;
    width: auto;
    max-height: 100px;
    max-width: 150px;
  }

  &__image-cta-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__image-cta-section-label {
    color: $color--darks-black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
  }

  &__image-cta-buttons-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__text-editor-wrapper {
    margin-top: 20px;
  }
}
