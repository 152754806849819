.ui.button {
  font-family: $font-family;
  border-radius: 8px !important;

  &.small {
    font-size: 16px;
    height: 40px;
    padding: 0 24px;
  }

  &.small-14 {
    font-size: 14px;
    height: 40px;
    padding: 0 24px;
  }

  &.icon {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 16px;

    .material-icons {
      margin-right: 5px;

      &--after {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }

  &.tertiary {
    border: solid 2px $color--accent-charmbray;
    color: $color--accent-charmbray;
    background: transparent;

    &:hover {
      border: solid 2px $color--accent-charmbray;
      background: $color--accent-koala;
    }

    &:active,
    &:focus {
      border: solid 2px $color--accent-charmbray !important;
      background: $color--accent-spindle;
    }
  }

  &.blue {
    background-color: $color--blue-main;
    color: $color--white;

    &.tertiary {
      border: solid 2px $color--accent-charmbray;
      color: $color--accent-charmbray;
      background: transparent;

      &:hover {
        border: solid 2px $color--accent-charmbray;
        background: $color--accent-koala;
      }

      &:active,
      &:not(.no-focus):focus {
        border: solid 2px $color--accent-charmbray !important;
        background: $color--accent-spindle;
      }
    }

    &.white-bordered {
      border: 2px solid $color--white;
      display: flex;
      align-items: center;
      min-width: 235px;
      font-family: $font-family;
      font-size: 16px;
      font-weight: $fw-bold;
      height: 40px;
      padding: 0 15px !important;
      border-radius: 7px;
      cursor: pointer;

      > i {
        margin-right: 12px;
      }

      &:active {
        border: 2px solid $color--white !important;
      }
    }

    &.segmentation-button {
      display: flex;
      padding: 0 15px;
      text-align: center;
      align-items: center;
    }

    &.invert {
      border: 2px solid $color--blue-main;
      color: $color--blue-main;
      background-color: $color--white;
      font-weight: $fw-bold;

      &:hover {
        border: 2px solid $color--blue-main;
        background: $color--accent-koala;
      }

      &:active,
      &:focus {
        border: 2px solid $color--blue-main !important;
        background: $color--accent-spindle;
      }
    }

    &.no-margin {
      margin: 0;
    }
  }

  &.teal {
    background-color: $color--teal-light;
    border-radius: 8px !important;
    color: $color--white;

    &:hover {
      background-color: $color--teal-main;
    }
  }

  &.red {
    background-color: $color--red-password;
    border-radius: 8px !important;
    color: $color--white;

    &:hover {
      background-color: $color--red-main;
    }
  }

  &.white {
    background-color: transparent !important;
    color: $color--darks-black;
    font-size: 16px;
    font-weight: $fw-book;

    &:hover {
      color: $color--darks-black;
    }

    &.segmentation-finish-button {
      border-radius: 8px;
      border: solid 2px $color--blue-main;
      color: $color--blue-main;
      font-weight: $fw-bold;
      letter-spacing: -0.3px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
    }

    &.automation-button {
      &--save {
        background-color: $color--white !important;
      }

      &--cancel {
        border: 2px solid $color--white;
        color: $color--white;
        margin-left: 8px;
      }
    }
  }

  &.blue-light {
    background-color: $color--blue-light;
    color: $color--white;

    &:hover {
      background-color: $color--blue-cerulean;
    }

    &.delivery-method-send,
    &.delivery-method-send--loading {
      font-size: 30px;
      font-weight: $fw-bold;
      font-family: $font-family-headline;
      width: 70%;
      margin: 0 auto;
      height: 60px;
      padding: 0;
      min-width: 280px;
      max-width: 415px;
      border-radius: 8px;

      &:hover {
        background-color: $color--blue-cerulean;
        cursor: pointer;
      }
    }

    &.delivery-method-send::after {
      background: svg-load('../svg/arrow_white.svg') no-repeat;
      background-size: contain;
      content: '';
      display: inline-block;
      height: 22px;
      width: 15px;
      margin-left: 10px;
    }
  }

  &.blue-royal {
    background-color: $color--blue-royal;
    color: $color--white;

    &:hover {
      background-color: $color--blue-science;
    }
  }
}

@media (max-width: 991px) {
  .ui.button.upload-cancel {
    display: flex;
  }

  .ui.button.upload-button {
    border-radius: 5px;
    width: 150px;
  }
}

@mixin pseudo-icon($icon, $color, $size: '15px') {
  display: inline-block;
  background-image: svg-load($icon, fill=#{$color}, stroke=#{$color});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  content: '';
  height: $size;
  vertical-align: sub;
  width: $size;
}

@mixin interface-button(
  $color: $color--darks-black,
  $icon: null,
  $icon-size: 16px,
  $icon-position: left,
  $standalone: false) {
  @include antialias;
  @include primary-font($fw-bold);

  border: 0;
  cursor: pointer;
  color: $color;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.1;
  padding: 0;
  text-decoration: none;
  outline: none;
  white-space: nowrap;

  // normalize button element

  &:hover {
    text-decoration: underline;
  }

  @if $icon {
    @if $icon-position == 'left' {
      &::before {
        @include pseudo-icon($icon, $color, $icon-size);
        margin-right: 8px;
      }
    }

    @if $icon-position == 'right' {
      &::after {
        @include pseudo-icon($icon, $color, $icon-size);
        margin-left: 8px;
      }
    }
  }

  @if $standalone {
    font-size: 1.6rem;
  }
}

.logout-button {
  margin-top: 0;
  padding: 8px 0;
  white-space: nowrap;
  width: 100px;
  font-size: 24px;

  @media (min-width: 992px) {
    display: none !important;
  }
}

.logout-button-super-admin {
  float: right;

  @media (min-width: 991px) {
    display: none;
  }
}

.logout-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agree-terms {
  float: right;
  margin-bottom: 20px !important;
}

.active-filter-button {
  background-color: $color--accent-koala !important;
}
