.ui.popup.personalize-popup {
  min-width: 500px;
  min-height: 188px;
  padding: 20px 30px;

  .keywords-header {
    font-size: 14px;
    font-family: $font-family;
    font-style: normal;
  }

  .keywords {
    padding: 10px 0;

    .keyword {
      margin: 5px 10px 5px 0;
      font-weight: $fw-medium;
      padding: 3px 10px !important;
    }
  }

  .keyword-example {
    color: $color--dark-scorpion;
    font-family: $font-family;
    font-size: 12px;
    font-style: italic;
    line-height: 17px;
    text-align: left;
  }

  @media (max-width: 500px) {
    min-width: 400px;
    padding: 20px;
  }

  @media (max-width: 450px) {
    min-width: 340px;
    padding: 18px;
  }

  @media (max-width: 410px) {
    min-width: 320px;
    padding: 16px;
  }

  @media (max-width: 380px) {
    min-width: 270px;
    padding: 14px;
  }
}

.ui.popup.error-popup {
  max-width: unset;

  &::before {
    right: 7px !important;
  }
}

.ui.top.left.mini.popup.transition.visible {
  padding: 0.5rem 0;

  .role-option {
    padding: 3px 0.3rem !important;
  }
}
