.notifications-tc {
  left: 10% !important;
  width: 80% !important;
  margin: initial !important;

  .notification {
    border-top: none !important;
    height: initial !important;
    padding: 15px !important;
    border-radius: 7px !important;
    box-shadow:
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;

    &.notification-success {
      background-color: #8CE2FE !important;
      color: $color--darks-black !important;

      .notification-dismiss {
        background-color: transparent !important;
        border-radius: 0 !important;
        color: $color--darks-black !important;
        font-size: 20px !important;
      }
    }

    &.notification-error {
      background-color: $color--red-main !important;
      color: $color--white !important;

      .notification-dismiss {
        background-color: transparent !important;
        border-radius: 0 !important;
        color: $color--white !important;
        font-size: 20px !important;
      }
    }

    .notification-message {
      text-align: center;
      font-size: 18px;
      font-weight: $fw-bold;
    }
  }
}

.cookie-policy {
  &--container {
    background: $color--blue-royal !important;
    justify-content: flex-end !important;
    align-items: center !important;
  }

  &--content {
    color: $color--white;
    font-size: 17px;
  }

  &--button {
    background: $color--white !important;
    color: $color--blue-royal !important;
    border-radius: 8px !important;
    width: 131px;
    font-size: 17px;
    font-family: $font-family;
    font-weight: $fw-bold;
  }
}

.low-warning {
  background-color: $color--blue-light;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 57px;
  width: 100%;
  color: $color--white;
  font-size: 18px;
  padding: 10px;
  text-align: center;

  &__refill {
    text-decoration: underline;
    cursor: pointer;
    display: contents;
  }

  &__close {
    background: svg-load('../svg/not-check-white.svg') no-repeat;
    background-size: contain;
    height: 18px;
    width: 18px;
    margin-left: 10px;
    cursor: pointer;

    @media (max-width: 767px) {
      height: 30px;
      width: 30px;
    }
  }
}

.expired-user-warning {
  background-color: $color--red-main;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 57px;
  width: 100%;
  color: $color--white;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
