.app-content {
  &__anonymous {
    padding-bottom: 50px;
  }

  &__main {
    min-height: calc(100vh - 91px);
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 991px) {
    &__main {
      padding-bottom: 160px;
    }
  }

  &__editor {
    padding-bottom: 60px;

    .body__input-fields input {
      pointer-events: none;
    }

    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
  }
}
