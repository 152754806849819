.app-form {
  &__already {
    color: $color--blue-main;
    font-weight: $fw-medium;
    font-size: 12px;
    text-align: center;

    div {
      display: inline-block;
    }
  }

  &__already-link {
    color: $color--blue-main;
    text-decoration: underline;
    font-weight: $fw-medium;
    font-size: 12px;
    padding: 0 5px;
    margin-bottom: 10px;

    &.right-floated {
      float: right;
    }
  }

  &__header {
    color: $color--blue-main !important;
    font-size: 30px !important;
    padding-bottom: 0 !important;
  }

  &__container {
    margin: 0.5rem auto !important;
  }

  &__list {
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 1.5rem !important;
  }

  &__field {
    position: relative;

    .input .label {
      margin-bottom: 6px !important;
    }

    &.dropdown {
      .text {
        width: stretch;
      }
    }
  }
}

form {
  .Select-control {
    width: 20em;

    .Select-value {
      .Select-value-label {
        max-width: 200px;
        overflow-wrap: break-word;
      }
    }
  }

  .react-select {
    float: left;
    text-align: initial;
  }
}

.ui.popup > .header {
  color: $color--darks-brown;
  font-family: $font-family-headline;
  font-size: 20px;
  font-weight: $fw-bold;
}

.contact-filter-form--mobile {
  &__segment-box {
    padding: 1em;
    width: 100%;
  }

  &__where {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > i {
      float: right;
      cursor: pointer;
    }
  }

  &__input {
    .ui.input {
      padding: 1em;
      width: 100%;
    }

    .field.input {
      position: relative;

      .input-error-icon {
        bottom: 23px;
        right: 20px;
      }
    }
  }
}

.properties-error {
  display: flex;
  justify-content: flex-end;
  color: $color--red-main;
}

.contact-filter-form {
  &__input {
    input {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: $color--darks-black;
    font-size: 14px;
  }

  .ui.selection.active.dropdown .menu,
  .ui.selection.dropdown {
    border: 0;
    font-size: 14px;
    font-style: italic;
    min-width: 9em;
  }

  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .ui.grid > .row {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    align-items: center;
    min-height: 66px;
  }

  .ui.button {
    border-radius: 8px;
  }

  &__custom-column {
    display: flex;
    align-items: center;
    min-height: 60px;

    &--where {
      width: 85px;
      font-size: 14px;
      color: rgba(0, 17, 34, 0.48);
      position: relative;
      bottom: 1px;

      > i {
        font-size: 18px;
        margin-right: 10px;
        color: $color--darks-black;
        cursor: pointer;
      }
    }

    &--property {
      min-width: 170px;
      font-size: 14px;

      .ui.dropdown {
        display: flex;
        justify-content: space-between;
        min-width: 160px;
      }
    }

    &--operator {
      min-width: 145px;
      font-size: 14px;

      .ui.dropdown {
        display: flex;
        justify-content: space-between;
        min-width: 135px;
      }
    }

    &--value {
      min-width: 180px;

      .field.input {
        position: relative;

        input::placeholder {
          font-style: italic;
        }

        .error-message {
          position: absolute;
          top: 40px;
          left: 12px;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  }

  .add-filter-row {
    width: 100%;
    text-align: center;
    padding: 1em 0 2em;

    .add-filter {
      font-size: 14px;
      color: $color--teal-main;
      cursor: pointer;

      > i {
        position: relative;
        top: 4px;
        font-size: 18px;
      }
    }

    @media (max-width: 767px) {
      .add-filter {
        padding-left: 2em;
      }
    }
  }
}

.add-list-form {
  padding: 10px 8px;

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    font-family: Dosis;
    float: left;
    font-size: 20px;
    font-weight: $fw-bold;
    letter-spacing: 0.4px;
    color: $color--blue-main;
  }

  &__input {
    margin: 10px auto;

    > div {
      width: 100%;
    }
  }

  &__select {
    width: 100%;

    .text {
      display: flex !important;
      width: calc(100% - 2em);
      font-size: 14px;
      font-weight: $fw-medium;
      letter-spacing: -0.15px;
    }

    .visible.menu.transition {
      width: 100%;
    }
  }
}

.login-company {
  color: $color--darks-black;
  font-family: $font-family;

  &__subheader {
    font-size: 18px;
    line-height: 22px;
  }

  &__radio-label {
    font-weight: $fw-medium;
  }
}

.quote-form {
  margin-bottom: 30px;
}

.ui.popup.popup-filter {
  position: absolute !important;
  left: 250px !important;
  right: unset !important;
  top: 205px !important;
  bottom: unset !important;
  min-width: 630px !important;

  &.popup-filter--with-warning {
    top: 270px !important;
  }
}

.ui.popup.popup-lists-add {
  position: absolute !important;
  left: 170px !important;
  right: unset !important;
  top: 100px !important;
  bottom: unset !important;
  min-width: 380px !important;
}

.two-column-form {
  &__input-field {
    display: flex;
    align-items: center;
    color: $color--darks-black;
    font-family: $font-family;
    font-size: 18px;
    font-weight: $fw-medium;
    padding: 10px 0;

    &__label {
      width: 50%;
      text-align: right;
      padding-right: 13px;
    }

    &__input {
      width: 50%;

      > input {
        box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1) !important;
        color: $color--darks-dark-accent !important;
        font-family: $font-family !important;
        font-weight: $fw-medium !important;
        font-size: 14px !important;
        border: none !important;
        background-color: $color--grey-input !important;
        border-top-right-radius: 4px !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-bottom: 1px solid transparent;
        height: 40px !important;
        max-width: 245px !important;

        &:focus,
        &:active {
          border-color: $color--teal-light !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        &::placeholder {
          color: $color--blue-wildblue;
          font-weight: $fw-medium;
        }
      }

      &--short {
        > input {
          max-width: 80px !important;
        }
      }
    }

    &--align-top {
      align-items: flex-start;
      padding-bottom: 0;

      &__label {
        width: 50%;
        text-align: right;
        padding-right: 13px;
      }
    }
  }

  &__section-header {
    width: 51%;
    text-align: right;

    &__title {
      color: $color--darks-black;
      font-family: $font-family;
      font-size: 20px;
      font-weight: $fw-bold;
      padding: 5px 0;
    }

    &--centered {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 500px;
      margin: auto;
    }

    &__back {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      font-weight: $fw-regular;
      color: $color--darks-black;
      font-family: $font-family;

      &::before {
        content: "";
        background: svg-load('../svg/arrow-black.svg') no-repeat;
        background-size: contain;
        height: 16px;
        width: 16px;
        transform: rotate(180deg);
      }
    }
  }

  .align-top {
    display: flex;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    &__section-header {
      width: unset;
      text-align: center;
    }

    &__input-field {
      flex-direction: column;

      &__label {
        text-align: left;
        padding-bottom: 10px;
      }

      &__input {
        > input {
          max-width: unset !important;
          width: 100% !important;
        }

        &--short {
          > input {
            width: 80px !important;
            max-width: 80px !important;
          }
        }
      }

      &--align-top {
        align-items: center;

        &__label {
          text-align: left;
          padding-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    &__input-field {
      &__label {
        width: 75%;
      }

      &__input {
        width: 75%;

        > input {
          max-width: unset !important;
          width: 100% !important;
        }
      }

      &--align-top {
        &__label {
          width: 75%;
        }
      }
    }
  }

  @media (max-width: 400px) {
    &__input-field {
      &__label {
        width: 90%;
      }

      &__input {
        width: 90%;

        > input {
          max-width: unset !important;
          width: 100% !important;
        }

        &--short {
          > input {
            width: 80px !important;
            max-width: 80px !important;
          }
        }
      }

      &--align-top {
        &__label {
          width: 90%;
        }
      }
    }
  }
}

.total-amount {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 50%;

    label {
      width: unset;
      padding-bottom: 0;
    }
  }

  @media (max-width: 600px) {
    width: 75%;
  }

  @media (max-width: 400px) {
    width: 90%;
  }
}

.billing-actions {
  margin-right: 10px;
  justify-content: flex-end !important;
}

.export-data-form {
  &__info {
    color: $color--teal-main;
  }

  &__label {
    font-size: 16px;
    font-weight: $fw-medium;
    letter-spacing: -0.32px;
    color: $color--accent-obsidian;
  }

  &__select {
    float: unset !important;
    width: 100%;
    margin: 8px 0 20px;
    min-width: unset !important;
    max-width: unset !important;

    .Select-control {
      width: 100%;
      height: 40px;

      .Select-placeholder {
        line-height: 40px;
      }

      .Select-input {
        height: 40px;
      }

      .Select-value {
        height: 30px;
        margin-bottom: 5px;
        font-size: 14px;

        .Select-value-label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    div:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__action {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    &__label {
      font-weight: $fw-bold;
      color: $color--blue-main;
      text-align: right;
    }

    &__button {
      min-width: 150px !important;
      margin-left: 1.5rem !important;
    }
  }
}
