.ui.menu {
  .ui.dropdown {
    .menu {
      .item {
        font-size: 18px !important;
        font-family: $font-family;
        font-weight: $fw-medium !important;
        color: $color--darks-black;

        &.active {
          color: $color--blue-cerulean !important;
        }
      }
    }
  }
}

.ui.menu {
  background: transparent;
  border: 0;
  box-shadow: none;
  font-family: $font-family;

  .item {
    cursor: pointer;
  }

  &.header-navigation {
    max-width: 500px;

    .item {
      font-weight: $fw-medium;
    }

    a.item:hover,
    .active.item,
    .active.item:hover {
      background: none;
      color: $color--blue-cerulean;
      font-weight: $fw-bold;
    }
  }
}

@media (max-width: 480px) {
  .ui.menu {
    flex-direction: column;
    margin: 0 1em;

    > div {
      margin: 0.5em 0;
      max-width: none;
    }
  }

  .ui.dropdown > .dropdown.icon {
    position: absolute;
    right: 1em;
  }
}

.app-footer {
  .ui.menu {
    .item {
      color: $color--white;
      font-size: 12px;
      padding: 1em 0 0;
    }

    &.labeled {
      flex-direction: row;
    }
  }
}

.account-navigation-menu {
  display: flex;
  flex-wrap: wrap;

  &.ui.menu {
    .item {
      font-family: $font-family;
      font-size: 16px;
      font-weight: $fw-bold;
      color: $color--darks-brown;
    }
  }

  &.ui.secondary.menu .active.item {
    background: transparent;
    color: $color--blue-cerulean;
  }
}

.editor-device-menu.ui.secondary.menu {
  justify-content: flex-end;

  .item {
    background-color: $color--white;
    border: 1px solid $color--teal-light;
    border-radius: 10px;
    color: $color--teal-light;
    padding: 7px 5px;
  }

  .active.item {
    background-color: $color--teal-light;
    border-radius: 10px;
    color: $color--white;
  }

  .item:hover {
    background-color: $color--teal-light;
    color: $color--white;
  }
}

.ui.menu .ui.dropdown .menu {
  > .item {
    font-weight: $fw-medium !important;

    .dropdown-logout {
      color: $color--blue-royal !important;
    }
  }
}

.ui.menu .ui.dropdown .menu > .active.item {
  font-weight: $fw-medium !important;
}

@media (max-width: 991px) {
  .navigation-header-div {
    display: contents !important;
  }
}

@media (max-width: $mobile) {
  .navigation-header-logo {
    display: none !important;
  }
}
