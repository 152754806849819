@import "../../../../../assets/styles/base/variables-color";
@import "../../../../../assets/styles/base/fonts";

.SurveyQuestionsController {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__question-controller {
    padding: 20px 30px;
    border-top: 1px solid $color--grey-border;
    border-bottom: 1px solid $color--grey-border;
    background-color: $color--accent-koala;
  }

  &__question-controller-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    @media (min-width: 650px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0;
    }
  }

  &__question-controls {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    @media (min-width: 650px) {
      width: auto;
    }
  }

  &__question-positioning {
    display: flex;
    align-items: center;
  }

  &__question-positioning-button {
    background: transparent;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &--up {
      background-image: svg-load('../../../../../assets/svg/survey-reorder.svg');
      background-repeat: no-repeat;
      border: none;
      transform: rotate(270deg);

      &:disabled,
      &[disabled] {
        background-image: svg-load('../../../../../assets/svg/survey-reorder-disabled.svg');
        cursor: default;
      }
    }

    &--down {
      background-image: svg-load('../../../../../assets/svg/survey-reorder.svg');
      background-repeat: no-repeat;
      transform: rotate(90deg);
      border: none;

      &:disabled,
      &[disabled] {
        background-image: svg-load('../../../../../assets/svg/survey-reorder-disabled.svg');
        cursor: default;
      }
    }
  }

  &__question-name {
    color: $color--blue-main;
    font-family: $font-family;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  &__toggle-input-container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
  }

  &__toggle-input-label {
    color: $color--darks-brown;
    font-size: 16px;
    font-weight: 600;
  }

  &__question-controller-footer {
    display: flex;
    justify-content: flex-end;
  }

  &__question-delete-button {
    background: transparent;
    font-weight: 400;
    cursor: pointer;
    border: none;
    font-size: 14px;
    text-decoration: underline;
  }
}
