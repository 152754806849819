@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.TextInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__input-label {
    background-color: transparent;
    color: $color--darks-black;
    font-size: 16px;
    font-weight: 600;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
  }

  &__input-field {
    box-sizing: border-box;
    color: $color--accent-obsidian;
    background-color: $color--accent-splashedwhite;
    border: 1px solid $color--grey-border;
    border-radius: 8px;
    font-family: $font-family;
    font-weight: 600;
    font-size: 14px;
    padding: 9.5px 15px;
    line-height: 1;
  }
}
