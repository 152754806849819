.SurveyWidgetController {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__controller-body {
    display: flex;
    flex-direction: column;
    max-width: 934px;
    margin: 0 auto;
    width: 100%;
  }

  &__button-wrapper {
    padding: 30px 20px;
  }
}
