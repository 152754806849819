.phone-input {
  .ui.label {
    margin-bottom: 6px !important;
  }

  .react-international-phone-country-selector {
    margin-right: 8px;

    button {
      border: 1px solid #CBD6E2;
      border-radius: 8px;
      box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
      background-color: #F5F8FA;
      height: 40px;
    }

    button:active {
      border-style: solid !important;
    }
  }

  .react-international-phone-input {
    width: 100%;
  }

  &__focused {
    .react-international-phone-country-selector button {
      border-color: #1CB59F;
    }
  }

  &__error {
    .react-international-phone-country-selector button {
      border: solid 1px #DF494F !important;
      box-shadow: 0 0 0 1px rgba(223, 73, 79, 0.5);
    }
  }
}
