.add {
  height: 12px;
  width: 12px;
  display: inline-block;
  background-image: svg-load('../svg/add_black.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100%;
  margin-right: 10px;
}
