@import '../../../assets/styles/base/variables-color';

.RegularEditorView {
  height: calc(100vh - 120px);

  &__editor-layout {
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: 992px) {
      flex-direction: row;
      position: static;
    }
  }

  &__editor-preview-side {
    height: calc(100vh - 120px);
    padding: 16px;
    width: 100%;

    @media (min-width: 992px) {
      width: 50%;
    }
  }

  &__widget-controller-side {
    box-shadow: -2px 0 13px 0 rgba(0, 0, 0, 0.26);
    position: absolute;
    height: auto;
    width: 100%;
    bottom: 0;
    overflow-y: auto;
    background: $color--white;
    z-index: 50;

    @media (min-width: 992px) {
      width: 50%;
      height: calc(100vh - 124px);
      position: static;
    }

    &--active-element {
      background-color: rgba(0, 0, 0, 0.85);
      height: calc(100vh - 120px);
      display: flex;
    }

    .OptInWidgetController,
    .SurveyWidgetController {
      height: calc(100vh - 128px);
      overflow-y: auto;
    }
  }

  &__toolbar-wrapper {
    width: 100%;
    background: $color--white;
    margin: auto;
    border-radius: 8px;
    z-index: 50;

    @media (min-width: 400px) {
      width: 88%;
    }
  }

  &__display-toolbar-button {
    background-color: $color--teal-light;
    border-radius: 35px;
    border: none;
    position: fixed;
    right: 30px;
    max-height: 70px;
    max-width: 70px;
    height: 70px;
    width: 70px;
    min-height: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color--white;
    cursor: pointer;
    bottom: 90px;
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.14),
      0 3px 4px 0 rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: $color--teal-main;
    }

    p {
      font-size: 40px;
      margin-bottom: 4px;
    }
  }

  &__hide-toolbar-button {
    position: absolute;
    right: 0;
    margin-top: 6px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .ui.toggle.checkbox input:checked ~ label::before {
    background-color: $color--teal-light !important;
    height: 13px;
    top: 5px;
  }
}
