.site-logo {
  height: 33px;
  min-width: 146px;
  margin-left: 49px;
  overflow: inherit;
  background-image: svg-load("../svg/rispons-logo-new.svg");
  background-repeat: no-repeat;

  @media (max-width: 500px) {
    margin-left: 25px;
  }

  &--admin {
    margin-left: 0 !important;
  }

  &__user {
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
