.ris-form {
  margin: 0 auto;
  max-width: 900px;

  &.column {
    max-width: 1400px;
  }

  &--white {
    width: 100%;
  }

  .ui.field.input {
    width: 100%;
    display: inline-block;

    .ui.labeled.input {
      width: 100%;
    }
  }

  .ui.input input {
    border: 1px solid $color--grey-border;
    // cornered labels semantic-ui bug, need to override with custom color
    border-left-color: $color--grey-border !important;
    background-color: $color--grey-input;
    border-radius: 8px;
    font-family: $font-family;
    font-weight: $fw-medium;
    font-size: 14px;
    height: 40px;
    box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
    color: $color--accent-obsidian;
    letter-spacing: -0.15px;

    &:focus,
    &:active {
      border-color: $color--teal-light;
      // cornered labels semantic-ui bug, need to override with custom color
      border-left-color: $color--teal-light !important;
    }

    &::placeholder {
      letter-spacing: -0.2px;
      color: $color--blue-wildblue;
      font-weight: $fw-medium;
    }
  }

  .ui.input.error input {
    border: solid 1px $color--faded-red !important;
    // cornered labels semantic-ui bug, need to override with custom color
    border-left-color: $color--red-main !important;
    color: $color--darks-black;
    box-shadow: 0 0 0 1px rgba(223, 73, 79, 0.5);

    &::placeholder {
      color: $color--red-main;
    }

    &:active,
    &:focus {
      box-shadow: 0 0 1px 2px rgba(223, 73, 79, 0.5);
      border: solid 1px $color--faded-red;

    }
  }

  .ui.labeled.input > .label {
    background-color: transparent;
    color: $color--darks-black;
    font-size: 16px;
    font-weight: $fw-medium;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
  }

  .ui.label {
    background-color: transparent;
    color: $color--darks-black;
    font-weight: $fw-medium;
    padding-left: 0;
  }

  .ui.labeled {
    background-color: transparent;
    display: flex;
    flex-direction: column;
  }

  .field.input {
    .input-error-icon {
      color: $color--red-main;
      cursor: pointer;
      position: absolute;
      bottom: 8px;
      right: 10px;
      left: unset !important;
      top: unset !important;
    }

    .password-input-icon {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 30px;

      &__error {
        right: 40px;
      }
    }
  }

  &--editor {
    .ui.input {
      width: 100%;
    }

    .field.input {
      &.selected-quiz-answer-input {
        .ui.input input {
          border: 2px solid $color--teal-light !important;
          border-left-color: $color--teal-light !important;
        }
      }

      .ui.input input {
        border-color: $color--grey-border;
        border-left-color: $color--grey-border !important;
        background-color: $color--grey-input;
        border-radius: 8px;
        font-family: $font-family;
        font-weight: $fw-medium;
        font-size: 14px;
        padding-left: 15px;
        box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
        line-height: 1;

        &:focus,
        &:active {
          border-color: $color--teal-light;
        }

        &::placeholder {
          color: $color--blue-wildblue;
          font-style: italic;
          font-weight: $fw-regular;
        }
      }

      .ui.input.error input {
        border-color: $color--red-main !important;
        color: $color--darks-black;

        &::placeholder {
          color: $color--red-main;
        }
      }
    }

    .inline-input {
      .ui.labeled.input {
        flex-direction: row;
        align-items: center;
      }

      &.mobile-two-lined {
        @media (max-width: 768px) {
          .ui.labeled.input {
            flex-direction: column;

            input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.nobackground-flex {
  .ui.label {
    background-color: transparent;
    color: $color--darks-black;
  }

  .ui.labeled {
    display: flex;
  }
}

.error-message {
  color: $color--red-main !important;
  font-family: $font-family;
  font-size: 14px !important;
  font-style: italic;
}

.login-company {
  &__radio-label {
    margin-bottom: 1em;
    cursor: pointer;
  }

  .ui.checkbox {
    label {
      font-size: 1.3em;
    }
  }
}

.keywords-header {
  color: $color--darks-black;
  font-size: 13px;
  font-style: italic;
}

.keywords-available {
  color: $color--blue-royal;
  font-size: 13px;
  font-style: italic;
  font-weight: 500;
}

.keywords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.keyword {
  color: $color--teal-main;
  cursor: pointer;
  font-family: $font-family;
  font-size: 12px;
  background-color: $color--white;
  border: 1px solid $color--teal-light;
  border-radius: 10px;
  font-weight: $fw-regular;
  text-align: center;
  margin-right: 8px;
  padding: 4px 10px;
  margin-bottom: 5px;

  &:hover {
    background-color: $color--teal-main;
    color: $color--white;
  }
}

.invite {
  &__field {
    &.ui.input {
      float: right;
      height: 40px;
      max-width: 500px;
      min-width: 270px;
      width: 45%;

      > input {
        border-radius: 8px !important;
        border: solid 1px $color--accent-spindle !important;
        background-color: $color--accent-splashedwhite !important;
        color: $color--accent-obsidian !important;

        &:active,
        &:focus {
          box-shadow: 0 0 4px 1px $color--teal-darker !important;
          border-color: $color--teal-main !important;
          transition: all 500ms ease;
        }

        &::placeholder {
          color: $color--blue-wildblue;
        }
      }

      > button {
        border-bottom-left-radius: unset !important;
        border-top-left-radius: unset !important;
      }
    }

  }

  &__form {
    height: 40px;
    margin: 10px 0;
  }

  &__error {
    clear: both;
    float: right;
    margin-right: 350px;
  }
}

.ris-textarea {
  min-height: 210px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: $color--grey-input;
  box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.2);
  padding: 1em;
  font-family: $font-family;
  font-weight: $fw-medium;
  font-size: 14px;
  resize: none;
  color: $color--darks-black;

  &::placeholder {
    color: $color--blue-wildblue;
  }

  &:focus,
  &:active {
    border-color: 1px solid $color--teal-main;
  }
}

.flex-baselined {
  display: flex;
  align-items: baseline;
}

.flex-aligned {
  display: flex;
  align-items: center;
}

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + input {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
