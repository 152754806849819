.footer-icon {
  height: 20px;
  width: 19px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  margin-bottom: 4px;

  &.messages {
    background-image: svg-load("../svg/mail.svg");
  }

  &.contacts {
    background-image: svg-load("../svg/inbox_contacts.svg");
  }

  &.analytics {
    background-image: svg-load("../svg/assessment.svg");
  }

  &.account {
    background-image: svg-load("../svg/person.svg");
  }

  &.media-library {
    background-image: svg-load("../svg/photo-library.svg");
  }

  &.automation {
    background-image: svg-load("../svg/storage.svg");
  }
}
