.arrow-down-white {
  height: 50px;
  width: 50px;
  display: inline-block;
  background-image: svg-load('../svg/arrow_down_white.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 10px;
  position: relative;
  top: 15px;
  left: 3px;
}

.arrow-down-blue {
  height: 50px;
  width: 50px;
  display: inline-block;
  background-image: svg-load('../svg/arrow_down_blue.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 10px;
  position: relative;
  top: 15px;
  left: 3px;
}
