@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.WidgetControllerHeader {
  font-family: $font-family-headline;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  padding: 20px 30px;
  color: $color--teal-main;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);

  z-index: 20;
  position: sticky;
  top: 0;
  background: $color--white;
}
