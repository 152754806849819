@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.WidgetFileUI {
  padding: 8px;

  &__section-title {
    font-family: $font-family-message;
    font-size: 18px;
    line-height: 24px;
  }

  &__attachments-container {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0;
    column-gap: 20px;
  }

  &__attachments-button {
    border-radius: 8px;
    background-color: $color--white;
    border: 1px solid #C0C0C0;
    box-shadow: 0 2px 4px 0 $color--02;
    padding: 20px 2%;
    width: 45%;
    margin: 10px 0;
    cursor: pointer;
    text-decoration: none;
  }

  &__attachment-action-title {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: $color--blue-glow;
  }

  &__attachment-title {
    font-size: 10px;
    display: flex;
    justify-content: center;
    word-break: break-all;
  }
}
