.media-library {
  display: flex;
  width: 100%;

  &--modal {
    display: flex;
    width: 100%;
    height: calc(100vh - 2rem);
  }

  &__main {
    padding: 30px 40px;
    width: 100%;

    &--preview {
      padding: 30px 40px;
      width: 60%;
    }

    @media (max-width: 768px) {
      padding: 30px 10px;
    }

    &__header {
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        margin: 0 10px;
      }

      &__editor {
        display: flex;
        align-items: center;
        font-weight: $fw-medium;
        font-size: 14px;
        color: $color--darks-black;
        cursor: pointer;
        margin-bottom: 20px;

        &::before {
          content: "";
          background: svg-load("../svg/arrow-black.svg") no-repeat;
          background-size: contain;
          height: 16px;
          width: 16px;
          transform: rotate(180deg);
        }
      }

      &__upload {
        margin-right: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 118px;
        height: 40px;
        border-radius: 7px;
        background-color: $color--blue-main;
        color: $color--white;
        font-size: 16px;
        font-weight: $fw-bold;
        cursor: pointer;

        &::before {
          content: '';
          background: svg-load("../svg/file-upload.svg") no-repeat;
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        @media (max-width: 768px) {
          margin-right: 0;
        }
      }
    }

    &__filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        margin-left: 10px;
      }

      &__container {
        display: flex;
        align-items: center;
      }

      &__delete {
        width: 97px;
        height: 40px;
        border-radius: 8px;
        border: 2px solid $color--red-main;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color--red-main;
        font-size: 16px;
        font-weight: $fw-bold;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    &__container {
      &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;

        .empty-media-list {
          background-color: $color--white;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 10%;
          padding-top: 7.5%;
          margin-bottom: 20px;

          &__text {
            color: $color--blue-main;
            font-size: 36px;
            display: flex;
            font-weight: $fw-bold;
            font-family: $font-family-headline;
            justify-content: center;
            line-height: 46px;
            text-align: center;
          }

          .ui.button {
            border-radius: 14.74px;
          }
        }

        &__item {
          height: 190px;
          width: 190px;
          margin-left: 5px;
          margin-top: 5px;
          overflow: hidden;
          cursor: pointer;

          @media (max-width: 768px) {
            height: 170px;
            width: 170px;
          }

          &__video {
            height: 100%;
            max-width: 100%;
            background-size: contain;
            object-fit: cover;
          }

          &__img {
            &--horizontal {
              height: 100%;
              max-width: 100%;
              background-size: contain;
              object-fit: cover;
            }

            &--vertical {
              max-height: 100%;
              width: 100%;
              background-size: contain;
              object-fit: cover;
            }
          }

          &__active {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            bottom: 194px;
            height: 190px;

            @media (max-width: 768px) {
              bottom: 174px;
              height: 170px;
            }

            &__add {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              width: 115px;
              border-radius: 8px;
              background-color: $color--aqua-blue;
              font-size: 16px;
              font-weight: $fw-bold;
              color: $color--white;
              cursor: pointer;
              margin-bottom: 5px;
            }

            &__preview {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
              width: 115px;
              border-radius: 8px;
              border: 2px solid $color--aqua-blue;
              font-size: 16px;
              font-weight: $fw-bold;
              color: $color--aqua-blue;
              cursor: pointer;
            }
          }

          &__selected {
            background: svg-load("../svg/media-selected.svg") no-repeat;
            height: 24px;
            width: 24px;
            position: relative;
            bottom: 188px;
            left: 7px;

            @media (max-width: 768px) {
              bottom: 168px;
            }

            &--not {
              display: none;
              background: svg-load("../svg/media-not-selected.svg") no-repeat;
              height: 24px;
              width: 24px;
              position: relative;
              bottom: 188px;
              left: 7px;

              @media (max-width: 768px) {
                bottom: 168px;
              }
            }
          }

          &__duration {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 12px;
            width: 40px;
            border-radius: 6px;
            background-color: $color--darks-black;
            font-size: 8px;
            line-height: 8px;
            letter-spacing: 0.2px;
            color: $color--white;
            position: relative;
            left: 145px;
            bottom: 183px;

            &::before {
              margin-right: 5px;
              content: "";
              background: svg-load('../svg/play-icon.svg') no-repeat;
              background-size: contain;
              height: 6px;
              width: 6px;
            }

            @media (max-width: 768px) {
              left: 125px;
              bottom: 163px;
            }
          }

          &:hover {
            .media-library__main__container__list__item {
              &__active {
                display: flex;
                background-color: $color--dark-navy;
                border: 2px solid $color--aqua-blue;
              }

              &__selected {
                bottom: 378px;

                @media (max-width: 768px) {
                  bottom: 338px;
                }

                &--not {
                  display: inherit;
                  bottom: 378px;

                  @media (max-width: 768px) {
                    bottom: 338px;
                  }
                }
              }

              &__duration {
                bottom: 397px;

                @media (max-width: 768px) {
                  bottom: 357px;
                }
              }
            }
          }

          &--selected {
            height: 190px;
            width: 190px;
            margin-left: 5px;
            margin-top: 5px;
            overflow: hidden;

            @media (max-width: 768px) {
              height: 170px;
              width: 170px;
            }

            .media-library__main__container__list__item {
              &__active {
                display: flex;
                border: 2px solid $color--aqua-blue;

                &__add {
                  display: none;
                }

                &__preview {
                  display: none;
                }
              }

              &__selected {
                bottom: 378px;

                @media (max-width: 768px) {
                  bottom: 338px;
                }
              }

              &__duration {
                bottom: 397px;

                @media (max-width: 768px) {
                  bottom: 357px;
                }
              }
            }

            &:hover {
              .media-library__main__container__list__item {
                &__active {
                  background-color: $color--dark-navy;

                  &__add {
                    display: flex;
                  }

                  &__preview {
                    display: flex;
                  }
                }
              }
            }
          }
        }

        .empty-media-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 190px;
          width: 190px;
          margin-left: 5px;
          margin-top: 5px;
          background-color: $color--white-splashed;
          border: 1px dashed $color--darks-black;
          cursor: pointer;

          @media (max-width: 768px) {
            height: 170px;
            width: 170px;
          }

          &__icon {
            margin-top: 54px;
            width: 40px;
            height: 40px;
            background: svg-load("../svg/add_black.svg") no-repeat;
            background-size: contain;

            @media (max-width: 768px) {
              margin-top: 44px;
            }
          }

          &__text {
            font-size: 18px;
            color: $color--darks-black;
            margin-top: 35px;

            @media (max-width: 768px) {
              margin-top: 30px;
            }
          }
        }
      }

      &__more {
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;
        box-shadow: 0 2px 4px 0 $color--03 !important;
        color: $color--accent-obsidian !important;
        font-size: 18px !important;
        font-weight: $fw-bold !important;
        letter-spacing: 0.38px;
        width: 40% !important;
        max-width: 580px;
        min-width: 190px;
        height: 64px !important;
        cursor: pointer !important;
        margin: 30px auto !important;
        background-color: $color--white !important;

        &::after {
          content: "";
          background: svg-load("../svg/arrow-black.svg") no-repeat;
          background-size: contain;
          height: 24px;
          width: 24px;
          transform: rotate(90deg);
          margin-left: 5px;
        }

        &--loading {
          display: flex !important;
          align-items: center;
          justify-content: center;
          border-radius: 8px !important;
          box-shadow: 0 2px 4px 0 $color--03 !important;
          color: $color--accent-obsidian !important;
          font-size: 18px !important;
          font-weight: $fw-bold !important;
          letter-spacing: 0.38px;
          width: 40% !important;
          max-width: 580px;
          min-width: 190px;
          height: 64px !important;
          cursor: pointer !important;
          margin: 30px auto !important;
          background-color: $color--white !important;

          .spinner__content {
            height: 64px;
            min-height: unset;
          }
        }
      }
    }
  }

  &__preview {
    width: 40%;
    box-shadow:
      0 2px 4px -1px $color--02,
      0 1px 10px 0 $color--blacky,
      0 4px 5px 0 $color--14;
    padding: 30px 40px;
    min-height: calc(100vh - 191px);
    height: 100vh;

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 991px) {
      min-height: calc(100vh - 251px);
    }

    &--modal {
      width: 40%;
      box-shadow:
        0 2px 4px -1px $color--02,
        0 1px 10px 0 $color--blacky,
        0 4px 5px 0 $color--14;
      padding: 30px 40px;
      min-height: calc(100vh - 2rem);
      height: 100vh;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__back {
        display: flex;
        align-items: center;
        font-weight: $fw-medium;
        font-size: 14px;
        color: $color--darks-black;
        cursor: pointer;

        &::before {
          content: "";
          background: svg-load("../svg/arrow-black.svg") no-repeat;
          background-size: contain;
          height: 16px;
          width: 16px;
          transform: rotate(180deg);
        }
      }

      &__actions {
        display: flex;

        &__add {
          width: 97px;
          height: 40px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: $color--aqua-blue;
          color: $color--white;
          font-size: 16px;
          font-weight: $fw-bold;
          cursor: pointer;
        }

        &__delete {
          width: 97px;
          height: 40px;
          border-radius: 8px;
          border: 2px solid $color--red-main;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color--red-main;
          font-size: 16px;
          font-weight: $fw-bold;
          cursor: pointer;
        }
      }
    }

    &__image {
      margin: 15px auto 0;
      max-width: 100%;
      object-fit: contain;
      display: flex;
      max-height: 50vh;
    }

    &__video {
      width: 100%;
      margin-top: 15px;
      display: flex;
      justify-content: center;

      .video-js {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;

        .vjs-big-play-button {
          top: calc(50% - 1.25em);
          left: calc(50% - 1.25em);
          height: 2.5em;
          width: 2.5em;
          background: svg-load('../svg/play-icon.svg') no-repeat;
          background-size: contain;
          border: none;
          box-shadow: none;

          > span::before {
            content: "";
          }
        }
      }

      &:hover {
        .vjs-big-play-button {
          background-color: unset !important;
        }
      }
    }

    &__data {
      margin-top: 10px;

      &__row {
        display: flex;
        color: $color--darks-black;
        margin-top: 20px;

        &__title {
          font-size: 16px;
          font-weight: $fw-medium;
          width: 110px;
        }

        &__value {
          font-size: 14px;
        }

        &__notification {
          font-size: 16px;
          font-weight: $fw-medium;
        }
      }
    }
  }
}

.media-library-filter {
  display: flex !important;
  align-items: center;
  height: 40px;
  max-width: 139px;
  white-space: nowrap;
}

.media-library-modal {
  width: 99vw !important;
  min-height: calc(100vh - 2rem);
}

.sticky-preview {
  position: fixed;
  left: 60%;
  top: 0;
  overflow: auto;

  &--bottom {
    position: absolute;
    left: 60%;
    bottom: 0;
    height: calc(100vh + 100px);
  }
}

.media-modal {
  overflow-y: auto;
}
