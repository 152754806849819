.ui.modal {
  min-width: 320px;

  &.medium {
    width: 600px;
  }

  &.large {
    width: 800px;
  }

  > .header {
    border: 0;
    color: $color--blue-main;
    font-size: 20px;
    font-weight: $fw-book;
    font-family: $font-family-headline;
    padding: 32px 40px 1.25rem;
  }

  > .content {
    padding: 1.5rem 40px;
  }

  > .description {
    padding: 0 40px;
  }

  > .actions {
    padding: 1rem 40px;
  }

  &.upload-modal {
    margin-top: 0 !important;

    .header {
      padding-left: 35px;
      padding-top: 35px;
      padding-bottom: 0;
    }

    .contact-upload-modal {
      .ui.segment {
        border: 1px dashed $color--blue-main;
      }

      &__subheader {
        color: $color--teal-main;
        font-family: $font-family;
        font-size: 14px;
        font-style: italic;
        font-weight: $fw-regular;
        margin-top: 10px;
      }

      &__select-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem 7rem 0;

        &__tags {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          padding: 10px 0;
          width: calc(50% - 8px);

          &--text {
            letter-spacing: -0.3px;
            color: $color--darks-black;
            font-weight: $fw-medium;
            font-size: 16px;
            min-width: 120px;
            margin-bottom: 8px;
          }

          &:first-child {
            margin-right: 8px;
          }

          &:last-child {
            margin-left: 8px;
          }
        }

        @media (max-width: 768px) {
          &__tags {
            min-width: calc(50% - 8px);
            width: unset;

            &:first-child {
              margin-right: 0;
            }

            &:last-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .editor-modal.editor-modal.editor-modal {
    height: 100%;
    margin: 0 !important;
    width: 100%;
  }

  &.editor-modal.editor-modal.editor-modal {
    margin-top: 0 !important;
  }

  .content.contact-upload-modal {
    padding: 1rem 7rem 2rem;
  }

  &.message-test-modal {
    > .header {
      > span {
        display: block;
        color: #007A69;
        font-family: $font-family;
        font-size: 14px;
        font-weight: $fw-book;
      }
    }

    > .content {
      #recipient {
        width: 100%;
      }
    }
  }

  .actions {
    background: inherit;
    display: flex;
    justify-content: flex-end;
    background-color: $color--accent-splashedwhite;

    &__confirmation {
      background: inherit;
      display: flex;
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    &.contact-filter-modal {
      width: 100%;
      max-width: 570px;
      overflow: auto;
    }

    > div.contact-upload-modal.content > div > ul {
      color: $color--red-password;
    }
  }

  @media only screen and (max-width: 767px) {
    .ui.modal > .content.contact-upload-modal {
      padding: 1rem 7rem !important;
    }
  }

  @media only screen and (max-width: 450px) {
    .ui.modal > .content.contact-upload-modal {
      padding: 1rem 3rem !important;
    }
  }

  .contact-upload-modal--uploaded {
    padding: 0 !important;
    border: 0;

    .ui.dropdown {
      min-width: 160px;
      display: flex;
      justify-content: space-between;
    }

    .ui.input {
      border: 0;
    }

    .ui.input input {
      border-bottom: 0;
    }

    .segment {
      border: 0;
      box-shadow: none;
    }

    .ui.grid {
      margin: 0 auto;
      min-width: 300px;
      max-width: 500px;
    }

    .ui.selection.dropdown {
      border: 0;
    }

    form {
      .row {
        justify-content: space-between !important;
        align-items: center !important;
        padding: 0.5em 0;

        .column:first-child > div,
        .column:last-child > div > .dropdown > .text {
          color: $color--darks-black;
          font-family: $font-family;
          font-weight: $fw-medium;
          font-size: 14px;

          &.default {
            color: $color--teal-main;
          }
        }

        .column {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        .column:first-child > div {
          text-align: right;
          white-space: nowrap;
          line-height: 38px;
        }

        .column > .rispons-arrow-right {
          background: svg-load("../svg/next.svg") no-repeat;
          color: transparent;
          width: 12px;
          height: 17px;
          margin: auto;
        }
      }
    }
  }

  .contact-upload-modal.content {
    min-width: 320px;

    .ui.segment.border {
      border: 2px dashed $color--blue-main;
      min-width: 240px;
    }
  }

  .contact-modal {
    padding: 12px 0;
    overflow: auto;

    .contact-filter-form {
      min-height: 300px;

      .ui.default.dropdown:not(.button) > .text,
      .ui.dropdown:not(.button) > .default.text {
        color: $color--darks-black;
        font-size: 14px;
        font-style: italic;
      }
    }

    .property-array {
      margin-bottom: 25px;
      width: 100%;
    }

    &__label {
      &--desktop {
        min-width: inherit;
        display: flex !important;
        align-items: flex-start;
        white-space: nowrap;
        font-size: 16px;
      }

      &--mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 285px;
        width: 90%;
        margin: 0 auto;
        font-size: 16px;

        .field-remove {
          right: 50px;
          margin-top: unset;
        }
      }

      .ui.selection.dropdown,
      .ui.input input,
      .ui.input.error input {
        border: 0;
      }

      .ui.selection.dropdown {
        border: 0;
        min-width: 10em;

        &.active.menu {
          border: 0;
          border-color: transparent;
        }
      }

      .ui.dropdown {
        .default.text {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__input {
      &--desktop {
        white-space: nowrap;

        > div {
          display: inline-block;
          position: absolute;
        }
      }

      &--mobile {
        > div {
          display: block !important;
          margin: 0 auto;
          width: 90% !important;
        }
      }

      > div {
        min-width: 250px;

        @media (min-width: 768px) {
          max-width: 250px;
        }
      }

      .ui.selection.dropdown,
      .ui.input input,
      .ui.input.error input {
        width: 100%;
      }

      .ui.input input:focus {
        border-bottom: 1px solid $color--teal-main;
      }

      .ui.input {
        width: 100%;

        input {
          box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
        }
      }

      .error {
        display: block;
      }
    }
  }

  .add-more {
    &--desktop {
      margin-bottom: 2em;
    }

    &--mobile {
      margin-bottom: 2em;
      margin-left: 2em;
    }
  }

  &.quote-modal {
    @media (min-width: 1200px) {
      min-width: 1100px;
    }

    .header {
      color: $color--blue-main;
      margin-top: 20px;
      margin-left: 40px;

      @media (max-width: 768px) {
        margin-left: 20px;
      }

      > div:first-child {
        font-family: $font-family-headline;
        font-size: 26px;
        font-weight: $fw-bold;
        line-height: 30px;
      }

      > div:last-child {
        font-family: $font-family;
        font-size: 20px;
        font-style: italic;
        font-weight: $fw-book;
        line-height: 30px;
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      border-top: none;
      padding-top: 0;
      padding-bottom: 60px;
    }
  }

  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    color: $color--darks-black;
    font-size: 14px;

    @media only screen and (max-width: 767px) {
      .ui.modal > .actions {
        padding: 1rem !important;
      }
    }
  }

  .ui.dimmer {
    background-color: $color--darks-black;
  }

  .copy-modal-header {
    font-family: Dosis;
    color: $color--blue-main;
    font-size: 26px;
    font-weight: $fw-medium;
    padding-left: 95px;
    padding-top: 12px;
  }

  .copy-modal-subheader {
    color: $color--teal-main;
    font-style: italic;
    padding-left: 95px;
    padding-top: 20px;
    padding-bottom: 32px;
  }

  @media (max-width: 767px) {
    .copy-modal-header {
      text-align: center;
      padding: 0;
    }

    .copy-modal-subheader {
      text-align: center;
      padding: 0;
    }
  }
}

.ris-modal {
  &__header {
    padding: 32px 24px 0 40px !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;

    .modal-close-row {
      height: 16px;
      width: 100%;
      margin-bottom: 7px;
    }
  }

  &__content {
    .ui.dropdown {
      width: 168px;
      display: flex;
      justify-content: space-between;

      @media (min-width: 768px) {
        width: 100%;
      }
    }
  }
}

.modal-close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  height: 16px !important;
  width: 16px !important;
}

.modal-close-row {
  width: 100%;
}

.uploaded-filename {
  color: $color--blue-light;
  font-style: italic;
  font-size: 19px;
  display: block;
  margin: 5px 0;
}

.contacts-upload {
  margin: 0 auto;
  width: 100%;
  text-align: center;

  .material-icons {
    font-size: 65px;
    display: block;
    margin: 10px 0 15px;
    color: $color--blue-main;
  }

  span {
    color: $color--teal-main;
    display: block;
    font-size: 14px;
    margin: 5px 0;
  }

  button {
    display: block;
    margin: 5px auto !important;
  }
}

.flex-between {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  > span:first-child {
    padding: 8px 0;
  }
}

.account-settings-modal {
  max-width: 500px;
}

.contact-upload-help {
  width: calc(100% - 14rem);
  border-radius: 8px;
  background-color: $color--accent-koala;
  margin: 32px 7rem 16px;
  padding: 16px;

  &__info {
    font-size: 16px;
    font-weight: $fw-medium;
    letter-spacing: -0.32px;
    color: $color--accent-obsidian;
    margin-bottom: 16px;
  }

  &__link {
    font-size: 14px;
    letter-spacing: -0.15px;
    color: $color--accent-obsidian;
    cursor: pointer;
    text-decoration: underline;

    &:hover,
    &:active {
      color: $color--accent-obsidian;
      text-decoration: underline;
    }
  }
}
