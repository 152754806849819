.SentMessageEditorPreview {
  height: calc(100vh - 120px);

  &__editor-layout {
    display: flex;
    flex-direction: row;
  }

  &__editor-preview-side {
    height: calc(100vh - 129px);
    width: 100%;
    padding: 16px;
  }
}
