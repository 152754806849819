@import "../../../../../assets/styles/base/variables-color";
@import "../../../../../assets/styles/base/fonts";

.MultipleAnswerQuestionUI {
  &__answer-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0 7px;
    margin: 7px 10px 0;
    border-bottom: 1px solid $color-light-pink;

    input[type="radio"],
    input[type="checkbox"] {
      visibility: hidden;
      display: none;
    }

    p {
      font-size: 16px;
      font-family: $font-family-message;
      line-height: 22px;
      overflow: hidden;
      overflow-wrap: break-word;
      width: calc(100% - 36px);
      min-height: 34px;
      display: inline-flex;
      align-items: center;
    }

    div {
      background: url('../../../../../assets/svg/new-check-inactive-empty.svg');
      background-repeat: no-repeat;
      background-size: contain;
      border: 0;
      cursor: pointer;
      min-width: 24px;
      min-height: 24px;
      display: inline-block;
    }

    input[type="radio"]:checked + div,
    input[type="checkbox"]:checked + div {
      background: url('../../../../../assets/svg/new-check.svg');
      background-repeat: no-repeat;
      background-size: contain;
      border: 0;
      cursor: pointer;
      min-width: 24px;
      min-height: 24px;
      display: inline-block;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
