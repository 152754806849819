.automation-list {
  padding: 28px 102px 32px 50px;

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 40px 48px 32px 28px;
  }

  @media (max-width: 767px) {
    padding: 64px 8px 32px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    &__title {
      font-family: $font-family-headline;
      font-size: 26px;
      font-weight: $fw-bold;
      letter-spacing: 0.36px;
      color: $color--darks-black;
    }
  }

  &__empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 3rem;

    &__text {
      color: $color--blue-main;
      font-size: 36px;
      display: flex;
      font-weight: $fw-bold;
      font-family: $font-family-headline;
      justify-content: center;
      text-align: center;
    }

    &__button {
      font-size: 24px !important;
      font-weight: 600 !important;
      padding: 1.2em 1em !important;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    .loaded-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.automation-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  border-radius: 4px;
  box-shadow:
    0 0 5px 0 $color--02,
    0 2px 1px -2px $color--blacky,
    0 2px 2px 0 $color--14;
  background-color: $color--white;
  margin-bottom: 10px;
  padding: 0 60px;
  font-family: $font-family;
  font-size: 14px;
  font-weight: $fw-medium;
  color: $color--accent-obsidian;

  &--mobile {
    @extend .automation-item;
    flex-direction: column;
    justify-content: center;
    min-height: 122px;
    height: fit-content;
    padding: 16px 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 350px;

    > span:first-child {
      display: flex;
      align-items: center;
    }

    @media (max-width: 991px) {
      width: 300px;
    }

    &__status {
      display: flex;
      align-items: center;
      width: fit-content;

      &__wrapper {
        width: 132px;
      }

      &.bordered-status {
        border: solid 2px;
        border-radius: 8px;
        padding: 4px 16px 4px 0;

        &--completed {
          border-color: $color--teal-light;
        }

        &--in_progress {
          border-color: $color--teal-main;
        }

        &--stopped,
        &--failed {
          border-color: $color--red-main;
        }

        &--waiting {
          border-color: $color--blue-light;
        }

        &--paused {
          border-color: $color--blue-hoverblue;
        }
      }

      &__icon {
        margin-right: 8px;
        margin-left: 16px;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        display: block;

        &--completed {
          background: svg-load('../svg/new-check.svg') no-repeat;
          background-size: contain;
          border-radius: unset;
          margin-left: 12px;
          width: 16px;
          height: 16px;
        }

        &--in_progress {
          background-color: $color--teal-main;
        }

        &--stopped {
          background-color: $color--red-main;
        }

        &--failed {
          background: svg-load('../svg/notification.svg') no-repeat;
          background-size: contain;
          border-radius: unset;
          margin-left: 12px;
          width: 16px;
          height: 16px;
        }

        &--waiting {
          background-color: $color--blue-light;
        }

        &--paused {
          background-color: $color--blue-hoverblue;
        }
      }

      &__text {
        letter-spacing: -0.15px;
        width: fit-content;

        &.automation-editor {
          color: $color--white;
          width: auto;
        }
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    width: 200px;

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 12px;
      font-weight: $fw-regular;
      width: 175px;
    }
  }

  &__actions {
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    width: 72px;

    @media (max-width: 991px) {
      margin-right: 0;
    }

    &__edit {
      background: svg-load('../svg/ic_edit_24px.svg') no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
      display: block;
      cursor: pointer;
    }

    &__delete {
      background: svg-load('../svg/ic_delete_24px.svg') no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
      display: block;
      cursor: pointer;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    &.info-row {
      margin-top: 10px;
      justify-content: space-between;

      &--mobile {
        flex-wrap: wrap;
      }
    }

    &.actions-row {
      margin-top: 20px;
      justify-content: flex-end;
    }
  }
}

.automation-search-popup {
  padding: 0 !important;
  overflow: hidden;

  &__header {
    line-height: 41px;
    background: $color--accent-splashedwhite;
    font-size: 12px;
    color: $color--accent-obsidian;
    letter-spacing: 0.6px;
    font-weight: $fw-medium;
    padding: 0 16px;
  }

  &__content {
    padding: 16px;

    &__container {
      margin-top: 16px;
      overflow-y: auto;
      max-height: 500px;

      .spinner__content {
        min-height: unset;
        height: 64px;
      }
    }

    &__contact {
      position: relative;
      margin-top: 8px;

      &__error {
        position: absolute;
        top: 0;
        right: 5px;

        background: svg-load('../svg/notification.svg') no-repeat;
        background-size: contain;
        border-radius: unset;
        width: 24px;
        height: 24px;
      }

      &__row {
        &:first-child {
          color: $color--accent-obsidian;
        }

        font-size: 12px;
        color: $color--accent-flint;
        line-height: 2;
      }
    }

    .automation-search-input {
      > input {
        border-radius: 8px !important;
        border: solid 1px $color--accent-spindle !important;
        background-color: $color--accent-splashedwhite !important;
        color: $color--accent-obsidian !important;

        &:active,
        &:focus {
          box-shadow: 0 0 4px 1px $color--teal-darker !important;
          border-color: $color--teal-main !important;
          transition: all 500ms ease;
        }

        &::placeholder {
          color: $color--blue-wildblue;
        }
      }

      > button {
        border-bottom-left-radius: unset !important;
        border-top-left-radius: unset !important;
      }
    }
  }
}

.automation-issues {
  position: fixed;
  width: 248px;
  right: 48px;
  top: 88px;
  text-align: center;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0 -1px 8px 0 rgba(81, 111, 144, 0.3);

  &__header,
  &__footer {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: $color--accent-splashedwhite;
    font-weight: 600;
  }

  &__header {
    height: 41px;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: $color--accent-obsidian;
    padding-left: 24px;

    &.disabled {
      cursor: auto;
      pointer-events: none;
    }
  }

  &__footer {
    height: 48px;
    font-size: 14px;
    letter-spacing: -0.15px;
    color: $color--teal-main;
    justify-content: center;
    line-height: 1.71;
  }

  &__rows {
    overflow: hidden;
    max-height: 352px;
  }

  &__row {
    position: relative;
    min-height: 64px;
    padding-top: 9px;
    background-color: $color--white;

    &__error-icon {
      position: absolute;
      top: 11px;
      left: 17px;
      background: svg-load('../svg/notification.svg') no-repeat;
      background-size: contain;
      border-radius: unset;
      width: 22px;
      height: 18px;
    }

    &__message,
    &__url {
      font-size: 12px;
    }

    &__message {
      font-weight: 600;
      line-height: 1.67;
      color: $color--red-main;
      padding-left: 56px;
      padding-right: 17px;
      text-align: left;
    }

    &__url {
      padding-left: 56px;
      line-height: 2;
      color: $color--accent-flint;
      display: flex;
    }
  }

  &__issues-box {
    transition: max-height 0.5s ease-in-out;
    max-height: 0;
    overflow: hidden;

    &--opened {
      max-height: 400px;

      .automation-issues__rows {
        overflow: auto;
      }
    }
  }
}

.automation-sidebar {
  &.ui.sidebar {
    display: flex;
    width: 0;
    padding: unset;
    box-shadow: 0 1px 8px 0 rgba(81, 111, 144, 0.3) !important;
    height: calc(100% - 128px) !important;
    z-index: 9 !important;
    transition: width 1s;

    top: 50% !important;
    transform: translateY(-50%) !important;
    border-bottom: unset !important;

    &.open {
      width: 1000px;
    }

    &.only-list {
      width: 328px;
    }
  }

  &__messages {
    width: 328px;
    background-color: $color--accent-splashedwhite;
    border-right: 1px solid $color--accent-spindle !important;
    min-height: calc(100% - 128px);
    overflow-y: auto;

    &--new {
      color: $color--blue-main;
      width: 100%;
      padding: 16px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: $fw-medium;

      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $color--blue-main;
        width: 20px;
        height: 20px;
        margin-right: 8px;

        &::before {
          content: "";
          background-image: svg-load('../svg/add_white.svg');
          background-repeat: no-repeat;
          background-size: contain;
          width: 12px;
          height: 12px;
          display: block;
        }
      }

      &:hover {
        background-color: $color--accent-koala;
      }
    }

    &__message-block {
      min-height: 184px;
      padding-top: 16px;
      border-radius: 7px;
      border: 2px solid $color--blue-main;

      &--name {
        display: flex;
        align-items: center;
        margin-left: 16px;
        font-weight: $fw-medium;
        letter-spacing: -0.32px;
        font-size: 16px;
        color: $color--accent-obsidian;
        cursor: pointer;

        &.edit::after {
          content: '';
          display: block;
          background-image: svg-load('../svg/ic_edit_24px.svg');
          height: 20px;
          width: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          margin-left: 4px;
        }
      }

      &--remove {
        width: 24px;
        height: 24px;
        margin: 0 16px;
        background-image: svg-load('../svg/ic_delete_24px.svg');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .message-nav {
        text-indent: 38px;
        width: 100%;
        padding: 20px 0;

        &__nav-step {
          display: flex;
          height: 32px;
          padding-left: 52px;
          align-items: center;
          width: 100%;
          cursor: pointer;

          &.selected {
            background-color: $color--accent-koala;

            .label {
              font-weight: $fw-bold;
            }

            .icon {
              background-image: svg-load('../svg/arrow-black.svg');
            }
          }

          &.disabled {
            cursor: default;
            pointer-events: none;

            .label {
              color: $color--accent-spindle;
            }
          }

          &:hover {
            background-color: $color--accent-koala;
          }

          .icon {
            display: block;
            background-image: svg-load('../svg/dot.svg');
            height: 24px;
            width: 24px;
            background-size: contain;
            background-repeat: no-repeat;
          }

          .label {
            font-size: 14px;
            letter-spacing: -0.15px;
            color: $color--accent-obsidian;
            display: contents;
          }
        }
      }

      .input {
        align-items: center;

        &__wrapper {
          padding-left: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .message-name-container {
            width: calc(100% - 56px);
            display: flex;
          }

          .input-icon {
            &--sms {
              &::before {
                content: '';
                display: block;
                background-image: svg-load('../svg/phonelink-ring-black.svg');
                height: 24px;
                width: 24px;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }

            &--email {
              &::before {
                content: '';
                display: block;
                background-image: svg-load('../svg/email-icon.svg');
                height: 24px;
                width: 24px;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
  }

  &__message-editor {
    width: 100%;
    position: relative;
    overflow-y: auto;
    transition: width 1s;

    &.closed {
      width: 0;
    }

    &--close {
      position: absolute;
      top: 40px;
      right: 40px;
      display: block;
      background-image: svg-load('../svg/close.svg');
      height: 32px;
      width: 32px;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      z-index: 20;
    }

    &--save {
      position: absolute;
      top: 35px;
      right: 80px;
      display: flex;
      align-items: center;
      justify-self: center;
      background-color: $color--white;
      border: 2px solid $color--blue-main;
      border-radius: 8px;
      color: $color--blue-main;
      cursor: pointer;
      z-index: 20;
      padding: 10px 36px;

      &:hover {
        background-color: $color--accent-koala;
      }
    }
  }
}
