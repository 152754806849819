@import "../../../../../../../../../assets/styles/base/variables-color";

.RatingQuestionController {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__rating-data-container {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__rating-data-label {
    color: $color--darks-black;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }

  &__disclaimer-text {
    color: $color--darks-black;
    font-size: 12px;
    text-align: right;
    font-weight: 600;
  }
}
