@keyframes pulse-heart {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes translate-up {
  0% {
    opacity: 0;
    transform: translate3d(0, 150px, 0);
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.success-gif {
  display: block;
  background: url('../svg/checkbox.gif') no-repeat;
  background-size: contain;
  background-position: center;
  content: '';
  height: 160px;
  width: 200px;
  margin: 0 auto;
}

.spinner {
  &--heart {
    animation: pulse-heart 1s ease-in-out infinite;
    background-image: url('../styles/icons/ripple.gif');
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
  }

  &--heart-small {
    height: 50px;
    width: 50px;
  }

  &--heart-medium {
    height: 75px;
    width: 75px;
  }

  &--heart-big {
    height: 100px;
    width: 100px;
  }

  &__content {
    display: flex;
    min-height: calc(100vh - 376px);
    justify-content: center;
    align-items: center;

    &.upload-modal {
      min-height: 218px;
    }

    &.option {
      height: 60px;
      min-height: unset;
    }
  }
}

.widget-spinner {
  background-color: rgba($color--blue-light, 0.9);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 20;

  &__wrapper {
    position: relative;
    width: 100%;
  }
}

.page-spinner {
  height: 50vh;
}

.loaded-content > div {
  animation: translate-up 0.45s cubic-bezier(0.205, 0.26, 0.275, 1.005) 1;

  > div {
    animation: none;
  }
}

.overlay-spinner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color--background-dimmer;
  cursor: default;
}
