@import "../../../../../../../../../assets/styles/base/variables-color";

.OpenQuestionController {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 0;

  &__answer-example-toggle-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__answer-example-toggle-label {
    color: $color--darks-brown;
    font-size: 16px;
    font-weight: 600;
  }
}
