.ui.segment.delivery-segment {
  padding: 0;
  margin-bottom: 88px;
  width: 75%;
  min-width: 320px;
  max-width: 900px;

  @media (max-width: 992px) {
    width: 80%;
  }

  @media (max-width: 767px) {
    width: 90%;
  }

  .ris-form {
    padding-left: 25px;
    padding-right: 25px;
  }

  .react-datepicker__time-list {
    padding: 0 !important;

    .react-datepicker__time-list-item {
      padding: 5px 0 !important;
    }
  }
}

.ui.segment.upload-segment {
  border: 2px dashed $color--blue-main;
}

.ui.segment {
  &.spinner {
    position: relative;

    &::after {
      display: block;
      content: '';
      height: 200px;
      width: 200px;
      background: svg-load('../svg/ripple.svg') no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .contacts-upload {
      visibility: hidden;
    }
  }
}
