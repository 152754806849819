.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label::before,
.ui.toggle.checkbox input:focus ~ .box::before,
.ui.toggle.checkbox input:focus ~ label::before {
  background-color: $color--white-silver !important;
}

.ui.toggle.checkbox input:checked ~ .box::before,
.ui.toggle.checkbox input:checked ~ label::before {
  background-color: $color--teal-light !important;
  height: 13px;
  top: 5px;
}

.ui.toggle.checkbox .box::before,
.ui.toggle.checkbox label::before,
.ui.toggle.checkbox input:focus ~ .box::before,
.ui.toggle.checkbox input:focus ~ label::before {
  height: 13px;
  top: 5px;
}

.ui.toggle.checkbox input:checked ~ .box::after,
.ui.toggle.checkbox input:checked ~ label::after {
  background-color: $color--teal-main;
}

.ui.toggle.checkbox label {
  color: $color--darks-brown;
  font-size: 16px;
  font-weight: $fw-medium;
}
