.policy-page {
  h1 {
    font-size: 3rem;
    text-align: center;
  }

  p {
    margin-bottom: 0.5rem;
  }

  a {
    text-decoration: underline;
  }

  .read-carefully {
    color: $color--blue-light;
  }

  .last-updated {
    text-align: right;
  }
}
