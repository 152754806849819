.message-column {
  min-width: 373px;
  max-width: 380px;

  @media (max-width: 768px) {
    max-width: 500px;
  }

  @media (max-width: 450px) {
    min-width: 320px;
  }
}

.message-item {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &__header {
    background-color: $color--blue-main;
    color: $color--white;
    font-size: 10px;
    font-family: $font-family;
    height: 30px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      white-space: nowrap;
    }

    &--bolded {
      font-size: 12px;
      font-weight: $fw-medium;
    }

    &--element {
      display: inline-block;
      font-weight: $fw-medium;
      padding: 0 5px;
    }
  }

  &__content {
    background-color: $color--white-dimmed;
    min-height: 226px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;

    &--tag {
      border-radius: 8px;
      margin: 10px 20px;
      padding: 0 15px;
      font-size: 10px;
      height: fit-content;
      font-weight: $fw-bold;

      &.tag-black {
        background-color: $color--darks-black;
        color: $color--white;
      }

      &.tag-email {
        background-color: $color--teal-main;
        color: $color--white;
        text-transform: uppercase;
      }

      &.tag-sms {
        background-color: $color--teal-tag;
        color: $color--white;
        text-transform: uppercase;
      }
    }
  }

  &__description {
    padding-top: 5px;
    box-shadow: 0 -5px 13px 1px rgba(180, 180, 180, 0.5);

    .ui.grid > .column:not(.row) {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &--name {
      font-weight: $fw-bold;
      font-size: 16px;
      color: $color--darks-black;
    }

    &--status {
      font-weight: $fw-medium;
      font-size: 14px;

      &__Sent {
        color: $color--teal-light;
      }

      &__Frozen {
        color: $color--blue-royal;
      }

      &__Draft {
        color: $color--blue-light;
      }

      &__Scheduled {
        color: $color--teal-light;
      }
    }

    &--date {
      font-weight: $fw-medium;
      font-style: italic;
      color: $color--blue-main;
      font-size: 14px;
    }
  }
}

.item {
  > .content {
    &.red {
      color: $color--red-main;
    }

    &.green {
      color: $color--blue-cerulean;
    }
  }

  i {
    &.red.icon {
      color: $color--red-main !important;
    }

    &.green.icon {
      color: $color--blue-cerulean !important;
    }
  }
}

.message-item-edit {
  background-color: $color--white-dimmed;
  min-height: 226px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}
