.ReactTable {
  border-radius: 8px;
  overflow: hidden;

  .rt-thead {
    background: $color--darks-black;
    color: $color--white;

    .ui.checkbox label::before {
      background-color: $color--light-pink;
      content: '';
    }

    .ui.checkbox label::after {
      background-color: $color--light-pink;
    }
  }

  .ui.checkbox {
    background-color: inherit;
  }

  .ui.checkbox input [type="checkbox"] {
    visibility: hidden;
  }

  .ui.checkbox label::before {
    min-width: 19px;
    min-height: 19px;
    border: 2px solid $color--darks-black;
    position: absolute;
    border-radius: 3px;
  }

  .ui.checkbox label::after {
    min-width: 19px;
    min-height: 19px;
    border: 2px solid $color--darks-black;
    position: absolute;
    border-radius: 3px;
  }

  .ui.checkbox input:focus ~ .box::before,
  .ui.checkbox input:focus ~ label::before {
    border-color: $color--darks-black;
  }

  input[type="checkbox"]:checked + label {
    color: $color--darks-black;
  }

  input[type="checkbox"]:not(:checked) + label {
    color: $color--darks-black;
  }

  &.paymentHistory-table {
    margin-top: 36px;

    .rt-table {
      .rt-thead {
        background-color: $color--darks-black;
        min-width: 1000px !important;

        .rt-th {
          > div:first-child {
            color: $color--white;
            font-size: 13px;
            font-weight: $fw-bold;
            line-height: 35px;
            padding-left: 0;
          }
        }
      }

      .rt-tbody {
        font-weight: $fw-medium;
        min-width: 1000px !important;
        max-height: 100%;
      }

      .rt-tr-group {
        line-height: 40px;
      }

      .rt-tr {
        justify-content: stretch;

        > div:first-child {
          border-right: none;

          > div {
            padding-left: 20%;
          }
        }

        > div:nth-child(2) {
          border-right: none;

          > div {
            text-indent: 15%;
            text-align: center;
          }
        }

        > div:nth-child(3) {
          border-right: none;

          > div {
            text-indent: 15%;
            text-align: center;
          }
        }

        > div:nth-child(4) {
          border-right: none;

          > div {
            padding-left: 12%;
            text-align: center;
          }
        }

        > div:nth-child(5) {
          border-right: none;

          div {
            padding-left: 12%;
            text-align: center;
          }
        }

        > div:nth-child(6) {
          border-right: none;

          > div {
            padding-left: 30%;
          }
        }

        > div:nth-child(7) {
          border-right: none;

          > div {
            padding-left: 35%;
          }
        }

        > div:nth-child(8) {
          max-width: 170px;
          border-right: none;

          > div {
            text-align: center;

            i {
              margin: 0 8px;
              position: relative;
              top: 6px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &.userAccess-table {
    .user-role {
      cursor: pointer;
      text-decoration: underline;
    }

    .rt-table {
      .rt-thead {
        background-color: $color--darks-black;
        min-width: 900px !important;

        .rt-th {
          > div:first-child {
            color: $color--white;
            font-size: 16px;
            font-weight: $fw-bold;
            line-height: 35px;
            padding-left: 0;
          }
        }
      }

      .rt-tbody {
        font-weight: $fw-medium;
        min-width: 900px !important;
        max-height: 100%;
      }

      .rt-tr-group {
        line-height: 40px;

        td {
          display: block;
        }
      }

      .rt-tr {
        > div:first-child {
          border-right: none;

          > div {
            padding-left: 25%;
          }
        }

        > div:nth-child(2) {
          border-right: none;

          > div {
            text-indent: 20%;
          }
        }

        > div:nth-child(3) {
          border-right: none;

          > div {
            padding-left: 20%;
          }
        }

        > div:nth-child(4) {
          border-right: none;
          text-align: center;
          max-width: 100px;
        }

        > div:nth-child(5) {
          border-right: none;
          text-align: center;
        }

        > div:nth-child(6) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(7) {
          max-width: 180px;
          border-right: none;

          > div {
            text-align: center;

            i {
              margin: 0 8px;
              position: relative;
              top: 6px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &.messageStats__table {
    margin-top: 2rem;

    .user-role {
      cursor: pointer;
      text-decoration: underline;
    }

    .rt-table {
      .rt-thead {
        min-width: 900px !important;
        background-color: $color--darks-black;

        .rt-th {
          > div:first-child {
            color: $color--white;
            font-size: 16px;
            font-weight: $fw-bold;
            line-height: 35px;
            padding-left: 0;
          }
        }
      }

      .rt-tbody {
        font-weight: $fw-medium;
        min-width: 900px !important;
      }

      .rt-tr-group {
        line-height: 40px;

        td {
          display: block;
        }
      }

      .rt-tr {
        > div {
          text-align: center;
          border-right: none;
        }
      }
    }
  }

  &.accountAccess-table {
    margin-top: 2rem;

    .rt-table {
      .rt-thead {
        background-color: $color--darks-black;
        min-width: 1344px !important;

        .rt-th {
          > div:first-child {
            color: $color--white;
            font-size: 16px;
            font-weight: $fw-bold;
            line-height: 35px;
            padding-left: 0;
          }
        }
      }

      .rt-tbody {
        font-weight: $fw-medium;
        min-width: 900px !important;
        max-height: 100%;
      }

      .rt-tr-group {
        line-height: 40px;

        td {
          display: block;
        }
      }

      .rt-tr {
        > div:first-child {
          border-right: none;

          > div {
            padding-left: 25%;
          }
        }

        > div:nth-child(2) {
          border-right: none;

          > div {
            text-indent: 20%;
          }
        }

        > div:nth-child(3) {
          border-right: none;

          > div {
            padding-left: 20%;
          }
        }

        > div:nth-child(4) {
          border-right: none;
          overflow: visible;

          > div {
            padding-left: 35%;
          }
        }

        > div:nth-child(5) {
          border-right: none;

          div {
            padding-left: 30%;
          }
        }

        > div:nth-child(6) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(7) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(8) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(9) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(10) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(11) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(12) {
          border-right: none;

          > div {
            padding-left: 44%;
          }
        }

        > div:nth-child(13) {
          max-width: 180px;
          border-right: none;

          > div {
            text-align: center;

            i {
              margin: 0 8px;
              position: relative;
              top: 6px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &.contacts-table-class {
    .rt-table {
      .rt-thead {
        .rt-tr {
          height: 40px;
        }

        .rt-th {
          line-height: 30px;
          font-size: 14px;
          border-right: 1px solid $color--darks-dark-accent;

          .contacts-table-header-column {
            display: flex;
            align-items: center;
            min-width: fit-content;
            width: 100%;
            overflow: hidden;

            &::after {
              display: none;
            }

            &--ascending {
              display: flex;
              align-items: center;
              min-width: fit-content;
              width: 100%;
              overflow: hidden;

              &::after {
                content: '';
                background: svg-load('../svg/arrow-drop-down.svg') no-repeat;
                background-size: contain;
                min-height: 25px;
                min-width: 25px;
                margin-left: 10px;
                transform: rotate(180deg);
                display: inline-flex;
                transition: transform 0.25s ease;
              }
            }

            &--descending {
              display: flex;
              align-items: center;
              min-width: fit-content;
              width: 100%;
              overflow: hidden;

              &::after {
                content: '';
                background: svg-load('../svg/arrow-drop-down.svg') no-repeat;
                background-size: contain;
                min-height: 25px;
                min-width: 25px;
                margin-left: 10px;
                display: inline-flex;
                transform: rotate(0deg);
                transition: transform 0.25s ease;
              }
            }
          }

          .ui.checkbox {
            top: 3px;
          }
        }
      }

      .rt-tbody {
        .rt-tr {
          height: 49px;
        }

        .rt-td {
          display: flex;
          align-items: center;

          &:first-child,
          &:last-child {
            justify-content: center;
          }
        }
      }
    }
  }
}

.row-dimmed {
  opacity: 0.5;
}

.pagination-bottom {
  border-radius: 5px;
  margin: 12px auto 0;

  .pagination {
    display: flex;
    list-style-type: none;
    margin: 15px auto;
    padding: 0;

    @media (max-width: 768px) {
      .btn-numbered-page {
        display: none !important;
      }
    }

    .disabled {
      color: $color--white-grey;
    }

    li {
      cursor: pointer;
      display: inline-block;
      margin: 0 20px;

      a {
        color: $color--darks-black;
      }
    }

    .btn-numbered-page {
      font-size: 20px;
      display: flex;
      margin-right: 40px;

      &.active {
        a {
          font-weight: $fw-bold;
          color: $color--blue-light;
        }
      }
    }

    .btn-first-page,
    .btn-prev-page,
    .btn-next-page,
    .btn-last-page {
      a {
        font-weight: $fw-bold;
      }
    }
  }
}

.role-option {
  cursor: pointer;
  margin: auto;
  font-size: 16px;

  &.active {
    color: $color--blue-cerulean;
    font-weight: $fw-medium;
  }
}

.contacts-pager {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }
}
