@import '../../../assets/styles/base/variables-color';
@import '../../../assets/styles/base/fonts';

.PictureWidgetController {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__controller-body {
    padding: 17px;
    max-width: 934px;
    margin: 0 auto;
    width: 100%;
  }

  &__image-data-controller {
    display: flex;
    gap: 8px;
    flex-direction: column;

    @media (min-width: 520px) {
      flex-direction: row;
      gap: 0;
    }
  }

  &__image-wrapper {
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 150px;

    @media (min-width: 520px) {
      margin-right: 24px;
    }
  }

  &__image {
    height: auto;
    width: auto;
    max-height: 100px;
    max-width: 150px;
  }

  &__label-text {
    color: $color--darks-black;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
  }

  &__label-text-alternative {
    font-size: 14px;
    font-weight: 600;
    color: $color--accent-obsidian;
    margin: 8px 0;
  }

  &__cta-container {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__slider-container {
    margin-top: 32px;

    input[type="range"] {
      appearance: none;
      margin: 18px 0;
      width: 100%;
      border-radius: 2.5px;
    }

    input[type="range"]:focus {
      outline: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      background: $color--blue-main;
      border-radius: 1.3px;
      border: 0.2px solid $color--almost-black2;
    }

    input[type="range"]::-webkit-slider-thumb {
      appearance: none;
      box-shadow: 0 0 6px 0 $color--25;
      border: 1px solid $color--03;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background: $color--white;
      cursor: pointer;
      margin-top: -12px;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
      background: $color--blue-main;
    }

    input[type="range"]::-moz-range-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
      background: $color--teal-main;
      border-radius: 1.3px;
      border: 0.2px solid $color--03;
    }

    input[type="range"]::-moz-range-thumb {
      box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
      border: 1px solid $color--03;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      background: $color--white;
      cursor: pointer;
    }

    input[type="range"]::-ms-track {
      width: 100%;
      height: 8.4px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }

    input[type="range"]::-ms-fill-lower {
      background: $color--teal-light;
      border: 0.2px solid $color--almost-black2;
      border-radius: 2.6px;
      box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--very-black;
    }

    input[type="range"]::-ms-fill-upper {
      background: $color--teal-light;
      border: 0.2px solid $color--almost-black2;
      border-radius: 2.6px;
      box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
    }

    input[type="range"]::-ms-thumb {
      box-shadow: 1px 1px 1px $color--very-black, 0 0 1px $color--almost-black;
      border: 1px solid $color--03;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      background: $color--white;
      cursor: pointer;
    }

    input[type="range"]:focus::-ms-fill-lower {
      background: $color--teal-light;
    }

    input[type="range"]:focus::-ms-fill-upper {
      background: $color--teal-light;
    }
  }
}
