.ui.dropdown {
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid $color--grey-border;
  background-color: $color--grey-input;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(230, 235, 241, 0.4);
  display: inline-flex;
  align-items: center;
  font-weight: $fw-medium;
  height: 40px;

  &.inline {
    height: 30px;
    display: flex;
    align-items: center;
  }

  &.select-delivery-method-dropdown {
    background-color: initial !important;
    box-shadow: none;
  }

  &.secondMessagesFilter {
    > .text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.selection {
    min-height: 40px;
    border: 1px solid $color--grey-border;
    background-color: $color--grey-input;
  }
}
