.plans-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px !important;

  &.ui.grid > * {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.current-plan {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;
    justify-content: unset;
  }

  &__warning {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__type {
    &__header {
      font-size: 26px;
      font-weight: $fw-bold;
      padding-bottom: 20px;

      &--warning {
        color: $color--faded-red;
      }

      > span {
        font-size: 16px;
        font-weight: $fw-regular;
        font-style: italic;
        padding-left: 4px;
        color: $color--teal-main;
      }
    }

    &__footer {
      .date {
        span {
          font-weight: $fw-bold;
          color: $color--blue-plans;
        }
      }

      .single {
        font-weight: $fw-bold;
      }

      .multiple {
        font-weight: $fw-bold;

        > span {
          display: block;
        }

        span + span {
          margin-top: 12px;
        }
      }

      &--warning {
        color: $color--faded-red;
      }
    }
  }

  &__sms-status {
    margin-bottom: 31px;

    .ui.progress {
      width: 311px;

      margin: unset;
      margin-bottom: 24px;
      margin-top: 5px;

      @media (max-width: 768px) {
        margin-bottom: 5px;
        margin-top: 45px;
      }

      .bar {
        background-color: $color--blue-plans !important;
      }
    }

    &__text {
      float: right;

      @media (max-width: 768px) {
        float: unset;
      }

      span {
        font-weight: $fw-bold;
        color: $color--accent-obsidian;
      }
    }
  }
}

.plan-cancel {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 139px;
  text-decoration: underline;
}

.plan {
  border-radius: 8px;
  @include box-shadow($border);
  color: $color--blue-main;
  margin: 30px 10px;
  width: 240px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &--active {
    border: 2px solid $color--blue-glow;
    border-radius: 10px;

    .plan__terms {
      background-color: $color--white-splashed;
    }

    .plan__name {
      color: $color--darks-black;
    }

  }

  &__enterprise {
    .plan__name {
      color: $color--blue-royal;
    }

    .plan__terms {
      align-items: center;
      display: flex;
      line-height: 27px;
      padding: 10px 25px;
    }
  }

  &__button.ui.button {
    font-size: 20px;
    padding: 8px 0;
    white-space: nowrap;
    width: 190px;
    border-radius: 8px;
  }

  &__name {
    clear: right;
    font-family: $font-family-headline;
    font-size: 20px;
    margin-top: 10px;
  }

  &__terms {
    background: $color--blue-lilly;
    color: $color--darks-black;
    margin: 6px 0 0;
    min-height: 100px;
    padding: 10px 0;
    width: 100%;

    > div:first-child {
      font-size: 14px;
    }

    > div:nth-child(3) {
      font-size: 10px;
    }

    small {
      font-size: 12px;
    }
  }

  &__price {
    display: block;
    font-size: 22px;

    b {
      font-size: 20px;
    }
  }

  &__meta {
    color: $color--darks-black;
    font-family: $font-family;
    font-size: 16px;
    white-space: nowrap;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 991px) {
  .ui.stackable.grid > .column:not(.row) {
    &.plan {
      min-width: 190px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ui.grid .ui.stackable.grid {
    &.plans-list {
      margin: 0 auto !important;
      width: 50%;
    }
  }

  .ui.stackable.grid > .column:not(.row) {
    &.plan {
      @include box-shadow;
      margin: 15px 0 !important;
      min-width: 315px;
    }
  }
}

@media only screen and (max-width: 515px) {
  .ui.grid .ui.stackable.grid {
    &.plans-list {
      width: 70%;
    }
  }

  .ui.stackable.grid > .column:not(.row) {
    &.plan {
      margin: 15px auto !important;
      max-width: 285px;
      min-width: 0;
    }
  }

  .plans-list .plan__button.ui.button {
    font-size: 16px;
  }
}

.subscription-modal {
  &__content {
    .spinner__content {
      min-height: unset;
    }

    .pricing-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 80px;
      border: 1px solid $color--teal-main;
      background-color: $color--duck-egg-blue;
      border-radius: 8px;
      padding: 16px;
    }

    .plan-info {
      &__header {
        margin: 20px 0;
        font-size: 16px;
        font-weight: $fw-bold;
      }

      &__plan {
        margin-bottom: 10px;
      }

      &__plan,
      &__price {
        text-indent: 5px;
      }
    }
  }
}

.ris-pricing-container {
  width: 1350px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 76px;

  .ris-pricing-menu {
    margin-right: 33px;
    width: 271px;
  }
}

.ris-pricing-container-mobile {
  padding-bottom: 76px;

  .ris-pricing-menu {
    margin: auto;
    margin-bottom: 100px;
    max-width: 800px;
  }

  .ris-pricing-mobile {
    max-width: 500px;
    margin: auto;
  }
}

.ris-save {
  height: 56px;
  width: 126px;
  float: right;
  margin-top: 10px;

  &__line {
    background: svg-load('../svg/line-3.svg') no-repeat;
    height: 32px;
    width: 50px;
    position: relative;
    top: 5px;
    left: -66px;
    transform: rotate(90deg);
  }

  &__text {
    color: $color--accent-charmbray;
    font-family: Noteworthy;
    font-size: 18px;
    font-weight: $fw-bold;
    position: relative;
    bottom: -35px;
    left: 92px;
  }
}

.ris-features-header,
.ris-features,
.ris-features-support {
  .ris-row:last-child {
    .ris-feature-inner-box::before {
      pointer-events: none;
      content: '';
      height: 33px;
      width: 192px;
      position: absolute;
      top: 30px;
    }

    .ris-feature-inner-box {
      &--enterprise::before {
        border-bottom-left-radius: 8px;
      }

      &--silver::before {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.ris-features,
.ris-features-support {
  .ris-row:nth-child(2) {
    .ris-feature-inner-box::before {
      pointer-events: none;
      content: '';
      height: 33px;
      width: 192px;
      position: absolute;
      bottom: 30px;
    }

    .ris-feature-inner-box {
      &--enterprise::before {
        border-top-left-radius: 8px;
      }

      &--silver::before {
        border-top-right-radius: 8px;
      }
    }
  }
}

.ris-features-header,
.ris-features,
.ris-features-support {
  .ris-row:last-child,
  .ris-row:nth-child(2) {
    .ris-feature-inner-box {
      &--enterprise::before {
        background-color: $color--plans-enterprise;
      }

      &--platinum::before {
        background-color: $color--plans-platinum;
      }

      &--gold::before {
        background-color: $color--plans-gold;
      }

      &--silver::before {
        background-color: $color--plans-silver;
      }
    }
  }
}

.ris-row {
  display: flex;

  &:hover {
    background-color: rgba(202, 221, 250, 0.2);
  }
}

.ris-feature {
  width: 240px;
  font-size: 0.9rem;
  color: $color--accent-obsidian;
  height: 32px;
  display: flex;
  align-items: center;
  text-indent: 0.25rem;
}

.ris-feature-inner {
  z-index: -1;
  display: grid;
  padding-left: 64px;
  grid-gap: 0 2px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.ris-feature-inner-box {
  width: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 600;
  letter-spacing: -0.15px;

  .ris-checkbox {
    background-image: svg-load('../svg/check-black.svg');
    height: 16px;
    width: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
  }

  &--enterprise {
    background-color: $color--plans-enterprise;
  }

  &--platinum {
    background-color: $color--plans-platinum;
  }

  &--gold {
    background-color: $color--plans-gold;
  }

  &--silver {
    background-color: $color--plans-silver;
  }

  &--free {
    background-color: $color--plans-free;
  }
}

.ris-pricing-inner {
  width: 192px;
  margin-right: 2px;
}

.ris-pricing-inner-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 300px;

  &--enterprise {
    background-color: $color--plans-enterprise;
    border-top-left-radius: 8px;
  }

  &--platinum {
    background-color: $color--plans-platinum;
  }

  &--gold {
    background-color: $color--plans-gold;
  }

  &--silver {
    background-color: $color--plans-silver;
  }

  &--free {
    background-color: $color--plans-free;
    border-top-right-radius: 8px;
  }
}

.ris-pricing-inner-box {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ris-pricing-button-start {
  cursor: pointer;
  font-size: 16px;
  font-weight: $fw-bold;
  color: $color--blue-plans;
  background: transparent;
  padding: 10px 36px;
  border: 1px solid rgba(7, 57, 106, 0.63);
  border-radius: 4px;
  position: absolute;
  top: 240px;

  &:active {
    border-style: solid !important;
  }

  &--mobile {
    position: unset;
    top: unset;
    margin-bottom: 20px;
  }
}

.ris-pricing-inner-title {
  font-size: 26px;
  font-weight: $fw-bold;
  color: $color--blue-plans;

  margin-top: 16px !important;
  margin-bottom: 26px;
}

.ris-pricing-inner-pick-title {
  font-size: 26px;
  color: $color--blue-main;
  font-family: Dosis;
  font-weight: $fw-bold;
  margin-bottom: 12px !important;
}

.ris-pricing-section-title {
  color: $color--sky-blue;
  font-size: 14px;
  font-weight: $fw-bold;
  padding-top: 40px;
  padding-bottom: 15px;
}

.ris-pricing-inner-price-value {
  color: $color--blue-plans;
  font-size: 48px;
  font-weight: $fw-bold;
  text-align: center;
  position: relative;

  &.get-quote {
    font-size: 24px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.payment-type {
  font-size: 16px;
  font-weight: $fw-bold;
  color: $color--accent-charmbray;
  margin-top: 10px;
}

.ris-checkbox-black {
  background-image: svg-load('../svg/check-black.svg');
  height: 16px;
  width: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}

.mobile-item-title-white {
  font-size: 26px;
  color: $color--white;
  font-weight: $fw-bold;
}

.mobile-item-title {
  color: $color--blue-plans;
  font-size: 26px;
  font-weight: $fw-bold;
}

.ris-pricing-mobile-item {
  padding: 2rem 2rem 1rem;
  margin-bottom: 16px;
  border-radius: 8px;
}

.mobile-item-price-box {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  margin-top: 30px;
}

.mobile-item-price-value {
  color: $color--blue-plans;
  font-size: 48px;
  font-weight: $fw-bold;
  position: relative;

  &::before {
    content: "$";
    position: absolute;
    right: -16px;
    bottom: 31px;
    font-size: 28px;
    font-weight: $fw-regular;
  }
}

.mobile-item-price-label {
  margin-left: 20px;
  color: $color--51;
}

.price-label-first {
  line-height: 18px;
}

.ris-pricing-mobile-main-features {
  margin-bottom: 1rem;
}

.ris-pricing-mobile-main-features > div {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 20px;
}

.ris-pricing-mobile-all-features {
  font-size: 18px;
  font-weight: 600;
  color: $color--51;
  cursor: pointer;
  letter-spacing: 1.1px;
  text-transform: uppercase;
}

.ris-pricing-mobile-all-features-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease;

  &--open {
    max-height: 500px;
  }
}

.ris-pricing-mobile-all-features-click-white {
  color: $color--white;
}

.feature-section-title {
  font-size: 11px;
  text-transform: uppercase;
  color: $color--51;
  letter-spacing: 1.1px;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-weight: 600;
  opacity: 0.5;
}

.feature-section-row > .ris-checkbox-black {
  margin-right: 5px;
  margin-left: 0;
  height: 20px;
  width: 20px;
}

.feature-section-row {
  font-size: 16px;
  display: flex;
}

.monthlyannually {
  width: 100%;
  display: flex;
  border-radius: 4px;
  margin-top: 21px;
  border: 1px solid $color--silver-blue;
  font-size: 16px;
  color: $color--accent-charmbray;
  cursor: pointer;
  overflow: hidden;

  .pricing-monthly,
  .pricing-annually {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    &--active {
      color: $color--white;
      background-color: $color--blue-plans;
    }
  }
}
