@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.ContentAndDesignSwitch {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);

  &__buttons-container {
    display: flex;
    font-size: 20px;
    font-family: $font-family-headline;
    padding: 20px 30px;
  }

  &__button {
    box-sizing: border-box;
    background-color: $color--white;
    color: $color--blue-light;
    border: 2px solid $color--blue-light;
    padding: 7px 5px;
    font-family: $font-family;
    display: block;
    margin: auto;
    letter-spacing: -0.5px;
    cursor: pointer;
    width: 100%;

    &--active {
      background-color: $color--blue-light;
      color: $color--white;
    }
  }
}
