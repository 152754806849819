@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.CreateElementButton {
  align-content: space-between;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: grab;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 120px;
  border: none;
  min-width: 80px;
  height: 80px;
  margin: 10px 15px;
  text-align: center;
  outline: none;
  background-color: $color--white;

  &:hover {
    background-color: $color--blue-cyan;

    i {
      color: $color--white;
    }
  }

  i {
    font-size: 40px;
    color: $color--blue-cyan;
    margin-bottom: 5px;
  }

  p {
    color: $color--darks-martinique;
    font-family: $font-family;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
  }

  @media (min-width: 991px) {
    height: 110px;
    min-width: 120px;

    p {
      font-size: 18px;
      line-height: 24px;
    }

    i {
      font-size: 45px;
      margin-bottom: 0;
    }
  }
}
