.line-chart {
  @include box-shadow;
}

.segment > div {
  border: 0;
}

.period {
  margin: 10px 0;
}
