.accordion {
  background: $color--white-splashed !important;
  border-radius: 0 !important;
  border-left: 6px solid $color--blue-glow;

  .title {
    margin: 0 2em !important;

    &::after {
      border-bottom: 5px solid transparent;
      border-left: 5px solid $color--darks-black;
      border-top: 5px solid transparent;
      content: '';
      display: inline-block;
      margin-left: 8px;
      margin-right: 0.7rem;
      transform: rotate(0deg) translateX(-3px);
      transition: transform 0.2s ease-out;
      vertical-align: middle;
    }

    &.active::after {
      transform: rotate(90deg) translateY(-2px);
    }
  }
}
