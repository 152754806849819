@import "../../../assets/styles/base/fonts";
@import "../../../assets/styles/base/variables-color";

.SelectInput {
  display: flex;
  align-items: center;
  padding: 1px 6px;
  width: 100%;

  &__label {
    font-family: $font-family;
    color: $color--darks-brown;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
  }

  div {
    width: 100%;
  }

  .text {
    width: 100%;
  }
}
