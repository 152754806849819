@import "../../../../../assets/styles/base/variables-color";

.OptInContactsController {
  width: 100%;
  display: flex;
  gap: 14px;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 420px) {
    flex-direction: row;
    align-items: center;
  }

  &__label {
    white-space: nowrap;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color--teal-main;
  }

  .dropdown {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .menu {
      width: 100%;

      .item {
        width: 100%;

        .text {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

      }
    }
  }
}
