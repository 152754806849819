.app-header {
  &__editor {
    background-color: $color--darks-black;
    box-shadow: 0 2px 7px 1px $color--header-shadow;
    font-family: $font-family-headline;
    height: 60px;
    margin: 0 !important;

    .editor-backward-step {
      font-family: $font-family;
      font-weight: $fw-bold;

      @media (min-width: 768px) {
        display: none !important;
      }
    }
  }

  &__automation {
    background-color: $color--darks-black;
    box-shadow: 0 2px 7px 1px $color--header-shadow;
    font-family: $font-family;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color--white;
    z-index: 10;
    position: relative;

    &__heading {
      padding-left: 32px;
      font-size: 18px;
      letter-spacing: 0.38px;
      display: flex;
      align-items: center;

      &__divider {
        height: 32px;
        border: solid 1px $color--white;
        margin: 0 16px;
      }

      &__title {
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
          content: '';
          background: svg-load('../svg/ic_edit_24px-white.svg') no-repeat;
          background-size: contain;
          height: 20px;
          width: 20px;
          display: block;
          margin-left: 4px;
        }
      }
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      &--execution-status {
        margin-right: 20px;
      }

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color--light-sky-blue;
        color: $color--darks-black;
        cursor: pointer;
        font-size: 20px;
        font-weight: $fw-bold;
        font-family: $font-family-headline;
        letter-spacing: 0.38px;
        text-align: center;
        white-space: nowrap;
        height: 100%;
        padding: 0 56px;
        margin-left: 32px;
        width: 267px;

        &:hover {
          background-color: $color--blue-inputs;
        }

        &.no-margin {
          margin-left: 0;
        }
      }
    }
  }

  &__anonymous {
    height: 70px;
    margin-bottom: 2em;
    margin-top: 50px;
    display: block;

    .site-logo {
      margin: 0 auto;
      position: static;
      width: 146px;
      height: 40px;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 7px 1px $color--header-shadow;
    height: 91px;

    &__navigation {
      display: flex;
      height: 100%;
    }

    .header-message-button {
      background-color: $color--blue-main;
      box-shadow: inset 2px 1px 10px 0 rgba(255, 255, 255, 0.21);
      cursor: pointer;

      &:hover {
        background-color: $color--blue-hoverblue;
        transition: background-color 0.1s ease;
      }
    }
  }

  &__expired {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 7px 1px $color--header-shadow;
    height: 91px;

    .navigation-header-logo {
      display: block !important;
    }

    .site-logo {
      left: 0;
    }

    > div:nth-child(2) {
      position: absolute !important;
      right: 0;
      top: 67px;
    }
  }

  &__account {
    color: $color--teal-dark;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-family: $font-family;
    font-size: 18px;
    font-weight: $fw-medium;
    margin-right: 8px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;

    .ui.menu .ui.dropdown .menu > .item {
      font-size: 16px;

      &.active {
        color: $color--blue-cerulean !important;
      }
    }
  }

  .account-navigation-container {
    z-index: 12;
  }
}

.editor-contacts-back {
  color: $color--white;
  display: flex;
  justify-content: flex-end;
  font-weight: $fw-bold;
  letter-spacing: -0.3px;
  font-size: 16px;
  font-family: $font-family;
  cursor: pointer;
}

.app-header__editor .editor-button {
  align-items: center;
  background-color: $color--blue-light;
  color: $color--white;
  cursor: pointer;
  display: flex !important;
  font-size: 24px;
  font-weight: $fw-bold;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  height: 100%;
  padding: 0 40px;

  @media (max-width: 748px) {
    min-width: 123px;
  }

  &--black {
    background: $color--darks-black;
  }

  &.computer {
    max-width: 400px;
  }

  &.mobile {
    min-width: 150px;
  }

  &::after {
    background: svg-load('../svg/arrow_white.svg') no-repeat;
    background-size: contain;
    content: '';
    display: block;
    min-height: 22px;
    min-width: 15px;
    left: 10px;
    position: relative;
    top: 13px;
    transform: translateY(-53%);

    &.left {
      transform: translateY(0);
    }
  }

  &.before {
    &::before {
      background: svg-load('../svg/arrow_white.svg') no-repeat;
      background-size: contain;
    }

    &::after {
      background: none;
    }
  }
}

.message-prompt.ui.header:first-child {
  margin-top: 0;
}

.message-prompt {
  align-items: center;
  background-color: $color--blue-main;
  color: $color--white;
  display: flex;
  font-family: $font-family-headline;
  font-size: 26px;
  font-weight: $fw-bold;
  height: 97px;
  justify-content: center;

  &--arrow span::after {
    background: svg-load('../svg/rispons-arrow-new.svg') no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 22px;
    left: 10px;
    margin-right: 15px;
    position: relative;
    bottom: 10px;
    width: 15px;
  }

  .ui.button {
    height: 40px;
    width: 176px;
    border: 2px solid $color--white;
    background: $color--blue-main;
    color: $color--white;
    border-radius: 10px;
    font-size: 16px;
    font-weight: $fw-bold;
    margin-left: 40px;
    padding: 5px 12px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $color--white;
      color: $color--blue-main;
    }

    &:active {
      border-style: solid !important;
    }
  }
}

.success-page-message-prompt {
  align-items: center;
  color: $color--blue-main;
  display: flex;
  font-family: $font-family-headline;
  font-size: 64px;
  font-weight: $fw-bold;
  justify-content: center;
}

.both-texts {
  padding-top: 20px;

  .success-text {
    color: $color--teal-light;
    line-height: initial;
  }
}

.second-arrow-margins {
  margin-top: 200px;

  &--arrow span::after {
    background: url('../svg/arrow-blue-down.png') no-repeat;
    background-size: 31px;
    content: '';
    display: inline-block;
    height: 20px;
    position: relative;
    width: 30px;
  }
}

.smaller-text {
  font-size: 24px;
  font-weight: $fw-medium;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: $font-family;
  line-height: 32px;
}

.centered-content {
  .arrow-success-page {
    span::after {
      background-size: 100%;
      height: 43px;
      width: 60px;
    }
  }
}

.buttons-alignment {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 350px;

  @media (max-width: 768px) {
    height: 220px;
  }

  .another-message-button {
    margin: 0 10px;
  }

  @media (max-width: 715px) {
    flex-wrap: wrap;

    .another-message-button {
      justify-content: center;
    }
  }

  .ui.button {
    align-self: center;
    border-radius: 14.74px;
    display: flex;
  }
}

@media (max-width: $mobile) {
  .arrow-success-page {
    display: none;
  }
}

@media (max-width: 835px) {
  .message-prompt {
    flex-direction: column;
    height: 118px;
    font-size: 20px;

    &--arrow span::after {
      height: 16px;
    }

    .ui.button {
      margin-top: 17px;
      margin-left: 0;
    }
  }
}

.editor-header-nav {
  color: $color--white;

  &--enable {
    cursor: pointer;
  }
}
