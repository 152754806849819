.close {
  height: 14px;
  width: 14px;
  display: inline-block;
  background-image: svg-load('../svg/close.svg');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.field-remove {
  vertical-align: top;
  margin-top: 13px;
  position: absolute;
}
