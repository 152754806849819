@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.WidgetControllerFooter {
  display: flex;
  align-items: center;
  margin: auto 0 0;
  border-top: 2px solid rgba(34, 36, 38, 0.15);
  padding: 20px 30px;
  background: #F5F8FA;
  gap: 18px;
  z-index: 20;
  position: sticky;
  bottom: 0;

  &__save-button {
    margin-right: 1em;
    border: none;
    height: 40px;
    width: 92px;
    font-size: 18px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: $color--blue-main;
    color: $color--white;
    cursor: pointer;
  }

  &__cancel-button {
    border: none;
    font-weight: 600;
    font-size: 18px;
    margin-left: 1em;
    cursor: pointer;
    background: transparent;
    color: rgba(0, 0, 0, 0.87);
    font-family: $font-family;
  }
}
