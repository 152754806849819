@import "../../../../../../../assets/styles/base/variables-color";
@import "../../../../../../../assets/styles/base/typography";

.NPSAnswerUI {
  padding: 24px 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 360px;
  width: 100%;

  .nps-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 7%;

    label {
      background-color: $color--white !important;
      display: flex;
      align-items: inherit;
      width: 100%;
      cursor: pointer;
      justify-content: center;
    }
  }

  div {
    label input {
      opacity: 0;
      width: 0.5px;
      position: relative;
      left: 15px;
      top: 10px;
      margin: 0;
    }

    label [type="radio"] + span {
      cursor: pointer;
      background-color: $color--grey-survey;
      color: $color--darks-black;
      width: 95%;
      font-size: 10px;
      max-width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:active {
        background-color: $color--white-grey;
        color: $color--darks-black;
      }
    }

    input[type="radio"]:checked + span {
      cursor: pointer;
      background-color: $color--darks-black;
      color: $color--white;
      width: 100%;
      height: 30px;
      max-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 6px 0 $color--02;

      &:hover,
      &:active {
        background-color: $color--darks-black;
        color: $color--white;
      }
    }
  }

}
