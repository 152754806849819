button.link {
  font: inherit;
  font-size: 14px;
  font-weight: $fw-medium;
  padding: 0;
  cursor: pointer;
  color: $color--darks-black;
  border: none;
  border-bottom: none;
  background: none;
}
