@import "../../../../../assets/styles/base/variables-color";
@import "../../../../../assets/styles/base/fonts";

.QuestionLayout {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow:
    0 1px 5px 0 $color--02,
    0 3px 1px -2px $color--blacky,
    0 2px 2px 0 $color--14;
  background-color: $color--white;

  &__header {
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    font-family: $font-family-message;
    word-break: break-word;
    line-height: 22px;
    background-color: $color--grey-survey;
    border-radius: 8px 8px 0 0;
    padding: 15px 10px 15px 20px;
    text-indent: -20px;
  }

  &__question-number {
    background-color: $color--white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-indent: initial;
    box-shadow: 0 0 4px 0 $color--24;
    left: -9px;

    p {
      text-align: center;
      font-size: 14px;
    }
  }

  &__question-title {
    font-weight: 500;
    font-size: 16px;
    font-family: $font-family-message;
    word-break: break-word;
    line-height: 22px;
    text-indent: -20px;
    margin-left: 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
}
