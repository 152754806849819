.automation-editor-area {
  width: 100%;
  height: calc(100vh - 128px);
  overflow: auto;
  padding: 40px;
  margin-bottom: 64px;
  background-color: $color--accent-splashedwhite;
  cursor: grab;
  transition: width 1s;

  &--no-drag {
    cursor: default;
  }

  &--split-screen {
    width: calc(100% - 1000px);

    &.only-list {
      width: calc(100% - 328px);
    }

    &--toggle {
      background-color: $color--blue-main;
      position: absolute;
      width: 36px;
      height: 36px;
      padding: 4px;
      top: 140px;
      right: 0;
      border-radius: 8px 0 0 8px;
      z-index: 20;
      display: flex;
      cursor: pointer;
      align-items: center;
      transition: right 1s;

      &::before {
        content: '';
        display: block;
        background-image: svg-load('../svg/email.svg');
        height: 12px;
        width: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 4px;
      }

      &::after {
        content: '';
        display: block;
        background-image: svg-load('../svg/arrow_white.svg');
        height: 12px;
        width: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 4px;
        transform: rotate(180deg);
        transition: transform 1s;
      }

      &.open {
        right: 1000px;

        &::after {
          transform: rotate(0deg);
        }

        &.only-list {
          right: 328px;
        }
      }
    }
  }

  &__container {
    margin: 0 auto;
    position: relative;
    top: 60px;

    @media (min-width: 991px) {
      top: 0;
    }
  }
}

.add-edit-node-content {
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }

  .node-type-select {
    width: 100%;

    @media (min-width: 992px) {
      width: 30%;
    }

    .text {
      width: calc(100% - 24px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .menu.transition.visible {
      width: 100%;
    }
  }

  .subject-input-select {
    display: flex;
    align-items: center;
    padding-top: 8px;
    width: 100%;
    height: 40px;

    @media (min-width: 992px) {
      width: 70%;
      padding-left: 8px;
      padding-top: 0;
    }

    &--two-column {
      padding-top: 8px;
      width: 100%;

      @media (min-width: 992px) {
        width: 35%;
        padding-left: 8px;
        padding-top: 0;
      }
    }

    &--three-column {
      padding-top: 8px;
      width: 100%;

      @media (min-width: 992px) {
        width: calc(70% / 3);
        padding-left: 8px;
        padding-top: 0;
      }
    }

    &__select {
      width: 100%;
      display: flex;
      align-items: center;
      min-width: unset;
      max-width: unset;
      height: 100%;

      .react-select {
        &__control {
          background-color: $color--grey-input;
          border-color: $color--grey-border;
          border-radius: 8px;
          height: 100%;
          width: 100%;
        }

        &__input {
          width: calc(100% - 24px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &__single-value {
          width: calc(100% - 24px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    &__dropdown {
      width: 100%;
      display: flex !important;
      align-items: center;

      &--time {
        margin: 0 4px;
      }

      .search {
        top: 11px !important;
      }

      .item {
        position: relative;
        cursor: pointer;
        display: block;
        border: none;
        height: auto;
        line-height: 1em;
        padding: 0.7857rem 1.1429rem !important;
        font-size: 1rem;
        text-transform: none;
        font-weight: $fw-book;
        box-shadow: none;
        pointer-events: all;

        &.disabled.loading-option-invisible {
          padding: 0 !important;
        }

        &:hover {
          background: $color--05;
          color: $color--95;
        }
      }

      .text {
        width: calc(100% - 24px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        line-height: 1em;
        font-size: 1rem;
        text-transform: none;
        font-weight: $fw-book;
        pointer-events: all;
      }

      .menu.transition.visible {
        width: 100%;
      }

      .time-input {
        visibility: visible;
        box-sizing: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        overflow: visible;
        background: none transparent;
        border: none;
        box-shadow: none;
        width: 100%;
        outline: 0;
        padding: inherit;
        z-index: 2;
        cursor: pointer;
      }

      .react-datepicker {
        width: 314px;

        &__time-container {
          width: 101px;
        }

        &__time {
          width: 100%;
        }

        &__time-box {
          width: 100%;
        }

        &__time-list {
          white-space: nowrap;
          padding-inline-start: 10px;
        }

        &__time-list-item {
          width: fit-content;
        }

        &__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
          right: 110px;
        }
      }
    }

    &__creatable {
      max-width: unset;

      .Select-control {
        width: 100%;
        height: 40px;

        .Select-placeholder {
          line-height: 40px;
        }

        .Select-input {
          height: 40px;

          > input {
            padding: 12px 0;
          }
        }

        .Select-value {
          height: 30px;
          margin-bottom: 5px;
          font-size: 14px;

          .Select-value-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    &__delay {
      margin-left: 4px;

      .menu.transition.visible {
        width: 100%;
      }
    }

    &__time-label {
      white-space: nowrap;
      width: fit-content;
    }
  }
}

.answers-select {
  width: 100%;
  padding: 8px 16px;
  margin-top: 8px;
  border-radius: 8px;
  border: solid 1px $color--accent-spindle;
  background-color: $color--accent-splashedwhite;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__container {
      display: flex;
      align-items: center;

      &__toggle {
        display: block;
        background: svg-load('../svg/arrow-drop-down-black.svg') no-repeat;
        background-size: contain;
        height: 24px;
        width: 24px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: transform 0.25s ease;

        &--hidden {
          transform: rotate(-90deg);
          transition: transform 0.25s ease;
        }
      }

      &__text {
        font-size: 14px;
        font-weight: $fw-medium;
        letter-spacing: -0.15px;
        color: $color--accent-obsidian;
        margin-left: 8px;
      }

      &__constraint {
        font-size: 12px;
        color: $color--accent-flint;
        margin-left: 8px;
      }
    }

    &__clear {
      font-size: 12px;
      color: $color--accent-obsidian;
      cursor: pointer;
    }
  }

  &__choices {
    margin-left: 28px;
    padding-top: 8px;

    &__row {
      margin-top: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &--disabled {
        cursor: default;
        pointer-events: none;

        > span {
          opacity: 0.5;
        }
      }

      &__checkbox {
        display: block;
        background: svg-load('../svg/automation-check-not.svg') no-repeat;
        background-size: contain;
        height: 20px;
        width: 20px;

        &--selected {
          display: block;
          background: svg-load('../svg/automation-check.svg') no-repeat;
          background-size: contain;
          height: 20px;
          width: 20px;
        }
      }

      &__text {
        font-size: 14px;
        letter-spacing: -0.15px;
        color: $color--accent-obsidian;
        margin-left: 8px;
      }
    }
  }
}

.additional-path {
  margin-top: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.15px;
  color: $color--accent-obsidian;

  &__choice {
    display: flex;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      background: svg-load('../svg/automation-check-not.svg') no-repeat;
      background-size: contain;
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }

    &--selected {
      display: flex;
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        background: svg-load('../svg/automation-check.svg') no-repeat;
        background-size: contain;
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }
    }
  }
}

.delete-node-modal {
  width: 480px !important;

  .description {
    padding-bottom: 1.5rem !important;
  }

  .content {
    padding-top: 0 !important;
  }

  .node-type-select {
    margin-top: 8px;
    width: 100%;

    .text {
      width: calc(100% - 20px);
    }

    .visible.menu.transition {
      width: 100%;
    }
  }
}

.node-modal {
  max-width: 100vw;
}
