.contact {
  &__menu {
    bottom: 5px;
    left: 15px;
  }

  &__popup > div {
    width: 500px;
  }

  &__action_menu {
    margin: 5px 0 0 14px;

    > div:first-child {
      font-size: 16px;
    }
  }

  .header {
    margin-left: 20%;
  }
}

.contacts {
  position: relative;
  height: 128px;
  z-index: 11;

  @media (max-width: 991px) {
    height: 180px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    margin-bottom: 10px;
    height: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;

    @media (max-width: 767px) {
      height: 90px;
    }

    &--disabled {
      pointer-events: none;
    }

    &--up {
      animation-name: fadeInUpHeader;
      animation-duration: 0.2s;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }

    &--down {
      animation-name: fadeInDownHeader;
      animation-duration: 0.2s;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
      animation-delay: 0.3s;
    }

    @keyframes fadeInUpHeader {
      from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      to {
        transform: translate3d(0, 40px, 0);
        opacity: 0;
      }
    }

    @keyframes fadeInDownHeader {
      from {
        transform: translate3d(0, 40px, 0);
        opacity: 0;
      }

      to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    &__search {
      height: 40px;
      width: 220px;

      > input {
        border-radius: 8px !important;
        border: solid 1px $color--accent-spindle !important;
        background-color: $color--accent-splashedwhite !important;
        color: $color--accent-obsidian !important;

        &:active,
        &:focus {
          box-shadow: 0 0 4px 1px $color--teal-darker !important;
          border-color: $color--teal-main !important;
          transition: all 500ms ease;
        }

        &::placeholder {
          color: $color--blue-wildblue;
        }
      }
    }
  }

  &__amount {
    white-space: nowrap;
    font-family: $font-family;
    font-size: 18px;
    font-style: italic;
    font-weight: $fw-medium;
    justify-content: right;
    margin-top: 13px;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &__filterbox {
    display: flex;
    align-items: baseline;
    margin-top: 10px;
  }

  &__actionbtn {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-top: 10px !important;
    }

    .button {
      white-space: nowrap;
    }
  }
}

.contact-select-menu {
  display: flex;
  justify-content: flex-start;
  height: 50px;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  position: absolute;
  bottom: 0;

  @media (max-width: 767px) {
    height: 90px;
  }

  &.sticky-contact-menu {
    background-color: $color--white;
    position: fixed;
    top: 0;
    z-index: 11;
    height: 80px;
    align-items: center;
    box-shadow: 0 4px 5px 0 $color--14, 0 1px 10px 0 $color--blacky, 0 2px 4px -1px $color--02;
    padding-left: 20px;
    margin-left: -20px;

    @media (max-width: 767px) {
      height: 120px;
      margin: 0 -8px;
      padding-left: 8px;
    }

    @media (max-width: 991px) and (min-width: 768px) {
      margin: 0 -15px;
      padding-left: 15px;
    }

    &.contact-select-menu--animation {
      animation-name: fadeSelectionOpacity;
      animation-duration: 0.6s;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
    }

    @keyframes fadeSelectionOpacity {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  }

  &--animation-up {
    animation-name: fadeInUpSelection;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  &--animation-down {
    animation-name: fadeInDownSelection;
    animation-duration: 0.2s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
  }

  @keyframes fadeInUpSelection {
    from {
      transform: translate3d(0, -40px, 0);
      opacity: 0;
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @keyframes fadeInDownSelection {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    to {
      transform: translate3d(0, -40px, 0);
      opacity: 0;
    }
  }

  &__content {
    color: $color--accent-obsidian;
    display: flex;
    align-items: center;

    @for $i from 1 through 6 {
      > span:nth-child(#{$i}) {
        animation-delay: #{($i - 1) * 0.1}s;
      }
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    &__count {
      font-size: 12px;
      display: inline-flex;
      white-space: nowrap;
      margin-top: 16px;

      &::after {
        content: '';
        background: svg-load('../svg/arrow-black.svg') no-repeat;
        background-size: contain;
        height: 18px;
        width: 18px;
        margin: 0 16px;

        @media (max-width: 767px) {
          margin: 0 4px;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-items: center;
      margin-right: 8px;
      padding: 0 8px;
      border: solid 1px $color--accent-charmbray;
      border-radius: 4px;
      color: $color--accent-obsidian;
      font-size: 12px;
      font-weight: $fw-medium;
      letter-spacing: -0.15px;
      height: 32px;
      cursor: pointer;
      white-space: nowrap;
      margin-top: 16px;

      @media (max-width: 767px) {
        margin-right: 4px;
      }

      &:hover {
        background-color: $color--accent-koala;
      }
    }

    .delete::before {
      content: '';
      background: svg-load('../svg/ic_delete_24px.svg') no-repeat;
      background-size: contain;
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }

    .tag::before {
      content: '';
      background: svg-load('../svg/tag-black.svg') no-repeat;
      background-size: contain;
      height: 18px;
      width: 18px;
      margin-right: 5px;
    }

    .list::before {
      content: '';
      background: svg-load('../svg/list-black.svg') no-repeat;
      background-size: contain;
      height: 18px;
      width: 18px;
      margin-right: 5px;
    }
  }
}

.contacts-container {
  &__editor {
    margin-bottom: 80px !important;
  }

  .ui.header {
    margin-bottom: 0;
  }

  .ReactTable {
    border-radius: 8px;
    font-size: 12px;
    font-weight: $fw-medium;
    margin-bottom: 20px;

    .rt-table {
      overflow: hidden !important;
    }

    .rt-thead,
    .rt-tbody {
      min-width: auto !important;
    }

    .rt-thead {
      overflow-x: hidden !important;
      overflow-y: auto !important;

      .rt-tr {
        text-align: left;

        .rt-th {
          padding: 5px 16px;

          &:first-child {
            text-align: center;
          }
        }
      }
    }

    .rt-tbody {
      .rt-td {
        padding: 7px 16px;
      }
    }
  }

  .ui.button {
    margin-right: 0.5em;
  }

  .ui.dropdown {
    color: $color--darks-brown;
    font-size: 12px;

    .item {
      color: $color--darks-brown;
    }
  }

  @media (max-width: 991px) {
    .ui.dropdown {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .button-text {
      display: none;
    }

    .ReactTable {
      margin-top: 15px;
    }
  }
}

.button-text {
  display: inline-block;
  vertical-align: top;

  small {
    color: $color--darks-answer;
    display: block;
    font-size: 10px;
    font-style: italic;
    line-height: 22px;
  }
}

.contacts-stats-table {
  background: inherit !important;
  box-shadow: none !important;
  border: 0;

  &.accordion.ui {
    > .title {
      font-size: 14px;
      font-family: $font-family;
      font-weight: $fw-bold;
    }
  }
}

.page-size {
  margin-left: 2em;

  &.ui.dropdown {
    min-width: 5em !important;
    font-size: 16px;
    font-family: $font-family;
    font-weight: $fw-medium;
    color: $color--darks-black;
    box-shadow: none !important;
    outline: none;

    .text {
      font-size: 16px !important;
      font-family: $font-family !important;
      font-weight: $fw-medium !important;
      color: $color--darks-black !important;
      cursor: pointer !important;

      &.default {
        font-size: 16px;
        font-family: $font-family;
        font-weight: $fw-medium;
        color: $color--darks-black;
        cursor: pointer !important;
      }
    }

    .visible.menu.transition {
      border: none;
      box-shadow: none;
      outline: none;

      .item {
        font-size: 16px;
        font-family: $font-family;
        font-weight: $fw-medium;
      }
    }
  }
}

.space-arround {
  display: flex;
  justify-content: flex-start !important;
  width: 100%;

  > div {
    margin-right: 5px;
  }
}

.contacts-delete-icon {
  background-image: svg-load('../svg/ic_delete_24px_Grey.svg');
  width: 25px;
  height: 29px;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  cursor: pointer;
  margin: 0 15px;
}

.contact-filter-form .Select-control {
  width: 200px;
}

.table-row-hover {
  .ReactTable .rt-tr:hover .rt-td {
    background: rgba(0, 0, 0, 0.08);

    .ui.checkbox label::before {
      background: rgba(0, 0, 0, 0.08);
    }
  }
}

.create-contact-label {
  height: 40px;
  width: 150px;
  display: flex;
  align-items: center;
  padding: 5px 16px;
  border: 1px solid $color--grey-border;
  background-color: $color--grey-input;
  font-size: 14px;
  color: $color--darks-black;
  font-weight: $fw-medium;
  box-shadow: 0 1px 3px 0 $color--label-shadow;
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 100%;
  }
}

.contact-page {
  display: flex;
  flex-direction: row;

  > div:not(.contact-lists) {
    width: calc(100vw - 230px);

    @media (max-width: 991px) {
      width: 100vw;
    }
  }
}

.contact-lists {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100vh - 251px);
  max-height: calc(100vh - 60px);
  box-shadow:
    0 1px 18px 0 $color--blacky,
    0 6px 10px 0 $color--14,
    0 3px 5px -1px $color--02;
  position: sticky;
  top: 0;
  align-self: flex-start;

  @media (min-width: 992px) {
    min-height: calc(100vh - 191px);
    max-height: 100vh;
  }

  &__content {
    width: 230px;
    padding: 35px 0;
    overflow-y: auto;
    overflow-x: hidden;

    &__header {
      margin-bottom: 15px;
      padding: 0 35px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      &__title {
        font-size: 26px;
        font-weight: $fw-bold;
        letter-spacing: 0.4px;
        color: $color--darks-black;
      }

      &__icon {
        background: svg-load('../svg/add_black.svg') no-repeat;
        background-position: center;
        background-color: $color--accent-koala;
        height: 25px;
        width: 25px;
        cursor: pointer;
        border: 1px solid $color--accent-spindle;
        border-radius: 12px;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__list-item {
      padding: 5px 35px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &--active {
        border-left: 4px solid $color--blue-main;
      }

      &:hover {
        background-color: $color--accent-koala;
        border-left: 4px solid $color--blue-main;
      }

      &__title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__title {
        font-size: 16px;
        font-weight: $fw-medium;
        letter-spacing: -0.3px;
        color: $color--darks-black;
        word-break: break-word;
      }

      &__count {
        font-size: 12px;
        font-weight: $fw-regular;
        letter-spacing: 0.1px;
        color: $color--dark-scorpion;
      }
    }
  }
}

.dropdown-list-menu {
  visibility: hidden !important;
  padding: 5px 0 !important;
  background: none !important;
  border: none !important;
  height: auto !important;
}

.contact-lists__content__list-item:hover .dropdown-list-menu,
.contact-lists__content__list-item--active .dropdown-list-menu {
  visibility: visible !important;
}

.contacts-list-expand {
  background: svg-load('../svg/menu.svg') no-repeat;
  background-size: contain;
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin: 28px 0 0;
}

.list-name {
  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;

    > i {
      margin-left: 8px;
    }
  }

  &__header--input {
    display: flex;
    align-items: center;
    margin: 0;
    max-width: unset;

    &__wrapper {
      position: relative;

      .material-icons.input-error-icon {
        bottom: 32px !important;
        right: 20px !important;
      }
    }
  }

  &__input {
    margin: 24px 16px 24px 0;
    display: inline-flex;
    align-items: center;
    height: 40px;
    width: 440px;

    > input {
      border-radius: 8px !important;
      border: solid 1px $color--accent-spindle !important;
      background-color: $color--accent-splashedwhite !important;
      color: $color--accent-obsidian !important;

      &:active,
      &:focus {
        box-shadow: 0 0 4px 1px $color--teal-darker !important;
        border-color: $color--teal-main !important;
        transition: all 500ms ease;
      }

      &::placeholder {
        color: $color--blue-wildblue;
      }
    }
  }
}

.contacts-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-contacts-header {
  cursor: pointer;

  &.ui.dropdown {
    width: 120px !important;
    height: 40px !important;
    font-size: 16px;
    font-family: $font-family;
    font-weight: $fw-medium;
    color: $color--blue-main;
    border: solid 2px $color--blue-main;
    box-shadow: none !important;
    outline: none;
    border-radius: 8px;
    background-color: $color--white;

    .text {
      font-size: 16px !important;
      font-family: $font-family !important;
      font-weight: $fw-medium !important;
      color: $color--darks-black !important;
      cursor: pointer !important;

      &.default {
        font-size: 16px;
        font-family: $font-family;
        font-weight: $fw-medium;
        color: $color--darks-black;
        cursor: pointer !important;
      }
    }

    .visible.menu.transition {
      border: none;
      box-shadow: 0 2px 4px -1px $color--02, 0 1px 10px 0 $color--blacky, 0 4px 5px 0 $color--14;
      outline: none;
      border-radius: 0;

      .item {
        font-size: 16px;
        font-family: $font-family;
        font-weight: $fw-medium;
      }
    }
  }
}

.contact-table-list {
  cursor: pointer;
}

.column-order {
  display: flex;
  padding: 0 20px;

  &__modal-actions {
    display: flex;
    margin-right: 20px;
  }

  &__selector {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 40px;

    &__container {
      max-height: calc(100vh - 289px);
      margin-top: 10px;
      overflow: auto;

      &:last-child {
        margin-bottom: 0;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 4px;
        height: 26px;
        justify-content: space-between;

        &__select {
          display: flex;
          align-items: center;
          width: stretch;

          &__label {
            font-size: 14px;
            letter-spacing: -0.15px;
            color: $color--accent-obsidian;
            margin-left: 15px;
            cursor: pointer;

            &--disabled {
              cursor: unset;
            }
          }

          &__input {
            width: stretch;

            > input {
              border: none !important;
              width: 100% !important;
              color: $color--accent-obsidian !important;
              font-family: $font-family !important;
              padding-left: 15px !important;
            }
          }
        }

        &__actions {
          width: 64px;
          display: flex;
          justify-content: space-between;

          > i {
            cursor: pointer;
          }

          .input-error-icon {
            color: $color--red-main;
          }
        }
      }
    }

    &__search {
      height: 40px;
      width: 100%;

      > input {
        border-radius: 8px !important;
        border: solid 1px $color--accent-spindle !important;
        background-color: $color--accent-splashedwhite !important;
      }
    }

    &__add {
      font-size: 14px;
      font-weight: $fw-bold;
      color: $color--teal-main;
      display: flex;
      align-items: center;
      cursor: pointer;

      &::before {
        content: "";
        background: svg-load('../svg/add_teal.svg') no-repeat;
        background-size: contain;
        height: 10px;
        width: 10px;
        margin-right: 10px;
      }
    }
  }

  &__order {
    width: 50%;
    margin-left: 40px;

    &__title {
      font-size: 16px;
      font-weight: $fw-bold;
      letter-spacing: 0.8px;
      color: $color--darks-black;
      margin-bottom: 15px;
    }

    &__container {
      max-height: calc(100vh - 237px);
      overflow: auto;

      &__item {
        z-index: 1002;
        margin-bottom: 15px;
        margin-right: 8px;
        border-radius: 8px;
        border: solid 1px $color--border-accent;
        display: flex;
        padding: 7px;
        height: 40px;
        justify-content: space-between;
        align-items: center;
        background-color: $color--white;

        &__left {
          display: flex;

          &__draggable {
            background: svg-load('../svg/ink-selection.svg') no-repeat;
            background-size: contain;
            height: 18px;
            width: 18px;
            cursor: pointer;
            margin-right: 5px;
            display: inline-flex;

            &--disabled {
              margin-right: 23px;
            }
          }

          &__text {
            font-size: 14px;
            letter-spacing: -0.15px;
            color: $color--accent-obsidian;
            cursor: pointer;

            &--disabled {
              font-size: 14px;
              letter-spacing: -0.15px;
              color: $color--accent-obsidian;
            }
          }
        }

        &__remove {
          background: svg-load('../svg/close.svg') no-repeat;
          background-size: contain;
          height: 10px;
          width: 10px;
          cursor: pointer;
          margin-right: 5px;
        }
      }
    }
  }

  &__divider {
    position: relative;

    .ui.vertical.divider {
      &::before {
        height: 100% !important;
      }

      &::after {
        height: 100% !important;
      }
    }
  }
}

.popup-columns-add {
  width: 380px;
  max-width: 380px !important;
}

.delete-column-modal {
  width: 600px !important;

  &__header {
    font-family: $font-family-headline;
    font-size: 26px;
    font-weight: $fw-bold;
    letter-spacing: 0.36px;
    color: $color--faded-red;
    margin: auto 20px;
  }

  &__actions {
    display: flex;
    margin-right: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    &--column {
      display: flex;
      flex-direction: column;
    }

    &__disclaimer {
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: -0.15px;
      line-height: 1.71;
      color: $color--accent-obsidian;
      margin-bottom: 28px;
    }

    &__input {
      width: 100%;
    }
  }
}
