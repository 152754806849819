@import "../../../../../assets/styles/base/variables-color";

.OptInInputsController {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-title {
    padding: 20px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color--teal-main;
  }

  &__text-editor-wrapper {
    margin-top: 20px;
  }

  &__inputs-container {
    background-color: $color--grey-input;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    @media (min-width: 550px) {
      align-items: center;
      flex-direction: row;
      gap: 12px;
    }
  }

  &__input-inner-container {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    .SelectInput {
      padding: 1px 0;
    }

    @media (min-width: 550px) {
      width: 40%;

      .SelectInput {
        padding: 1px 6px;
      }
    }
  }

  &__close-button-wrapper {
    width: 15px;
  }

  &__select-input-wrapper {
    width: 100%;
  }

  &__text-input-wrapper {
    width: 100%;

    @media (min-width: 550px) {
      width: 60%;
    }
  }

  &__add-input-button {
    border: none;
    background: $color--white;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    color: $color--teal-main;
    font-weight: 700;
    position: relative;
    margin-left: 10px;

    &::before {
      display: block;
      content: "+";
      font-size: 25px;
      position: absolute;
      left: -10px;
      top: 2px;
    }
  }
}
