.WidgetVideoUI {
  margin: 15px 0;
  width: 100%;

  .video-js {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  .vjs-big-play-button {
    top: calc(50% - 1.25em);
    left: calc(50% - 1.25em);
    height: 2.5em;
    width: 2.5em;
    background: url('../../../assets/svg/play-icon.svg') no-repeat;
    background-size: contain;
    border: none;
    box-shadow: none;

    .vjs-icon-placeholder {
      display: none;
    }
  }

  &:hover {
    .vjs-big-play-button {
      background-color: unset;
    }
  }

  &__video-player-placeholder {
    background-color: #000;
    background-size: contain;
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;

    &::before {
      content: "";
      display: block;
      top: calc(50% - 2.75em);
      left: calc(50% - 2.75em);
      position: absolute;
      height: 5.5em;
      width: 5.5em;
      background: url('../../../assets/svg/play-icon.svg') no-repeat;
    }
  }
}
