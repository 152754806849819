.account-settings-tab {
  .account-settings {
    margin-top: 25px;

    &__reset-pass {
      @media (min-width: 992px) {
        .ui.grid > .centered.column,
        .ui.grid > .row > .centered.column {
          width: 80% !important;
        }
      }
    }

    .account-settings-form {
      max-width: 480px;
      margin-top: 10px;

      .account-settings-grid {
        > .row > .column {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      .two.column.row {
        > .column:first-child {
          padding-left: 0 !important;
        }

        > .column:last-child {
          padding-right: 0 !important;
        }
      }
    }

    .ris-form .ui.input input::placeholder {
      font-weight: $fw-medium;
    }

    .ui.medium.header .widget__header {
      text-align: left;
      text-indent: 10%;
    }

    &__subheader {
      align-items: center;
      color: $color--darks-black;
      display: flex;
      flex-wrap: wrap;
      font-family: $font-family;
      font-size: 20px;
      font-weight: $fw-bold;
      white-space: nowrap;
      margin: 0 auto;
      max-width: 490px;
      margin-bottom: 10px;

      span {
        display: inline-block;
      }

      .ui.button {
        max-width: 220px;
        white-space: nowrap;
      }

      @media (max-width: 1170px) {
        justify-content: flex-start;
        align-items: flex-start;

        button {
          align-self: flex-end;
          margin-right: 2em !important;
          margin-bottom: 30px !important;
        }
      }

      @media (max-width: 767px) {
        padding: 0;

        span {
          margin: 10px 0;
        }
      }
    }

    &__reset-password {
      width: 80%;
      padding: 1rem;
      margin: 0 auto;

      > button {
        float: right;
      }
    }

    &__submit-column {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 10px 0;

      > button {
        margin-right: 0 !important;
      }
    }

    &__credit-info {
      .card-flex {
        display: inherit;
        align-items: baseline;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;

        .billing-input {
          box-shadow: 1px 1px 4px 2px rgba(0, 17, 34, 0.1);
          padding: 5px 0;
          width: calc(100% - 115px);
          min-width: 270px;
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }

      .cards {
        display: flex;
        margin-top: 20px;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        .card.inline {
          font-size: 18px;
          margin-bottom: 10px;

          > i {
            color: $color--blue-main;
          }
        }
      }

      .StripeElement {
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
        min-width: 100%;
        position: relative;
        top: 3px;
      }
    }
  }

  .ui.button {
    border-radius: 8px !important;
  }

  @media (max-width: 768px) {
    > div:first-child {
      border: none;
    }
  }
}

.select-bar {
  display: flex;

  @media (min-width: 900px) {
    width: 40%;
  }
  justify-content: space-between;

  > div {
    height: 22px;
    color: $color--darks-brown;
    font-size: 14px;
    font-weight: $fw-bold;
    line-height: 22px;
    cursor: pointer;
  }
}

.selected-tab {
  color: $color--blue-light !important;
}

.usage {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    color: $color--darks-black;
    font-size: 16px;
    font-weight: $fw-medium;
    padding-top: 20px;
    text-align: center;
  }

  &__number {
    color: $color--teal-light;
    font-size: 48px;
    font-weight: $fw-bold;
    line-height: 65px;
    padding-top: 10px;
  }

  &__button-width {
    padding: 8px 16px !important;
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-items: center;
    margin-top: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.sms-pricing-text {
  color: $color--teal-main;
  font-size: 16px;
  font-weight: $fw-medium;
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin: 20px 0 10px;
  }
}

.usage-parent {
  display: flex;
  flex-direction: column;
}

.usage-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.capitalize {
  text-transform: capitalize !important;
}

.plans-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  padding: 0;
  width: 100%;
  margin: 0 auto !important;
  max-width: 1100px;
  justify-content: space-evenly;

  &__header {
    height: 38px;
    color: $color--blue-main;
    font-size: 27px;
    font-weight: $fw-bold;
    line-height: 38px;
    text-align: center;
    margin-bottom: 10px;

    &--mobile {
      height: 24px;
      color: $color--blue-main;
      font-size: 20px;
      font-weight: $fw-bold;
      line-height: 24px;
      text-align: center;
      margin-bottom: 25px;
    }
  }

  &__payment-select {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 96px;
    font-size: 20px;
    font-weight: $fw-medium;
    margin-bottom: 36px;
    padding-left: 90px;

    &--mobile {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 96px;
      font-size: 20px;
      font-weight: $fw-medium;
      margin-bottom: 80px;
      padding-left: 90px;
    }

    &__discount {
      &__line {
        background: svg-load('../svg/line-3.svg') no-repeat;
        height: 32px;
        width: 59px;
        position: relative;
        top: 5px;
        left: 10px;

        &--mobile {
          background: svg-load('../svg/line-3.svg') no-repeat;
          height: 32px;
          width: 59px;
          position: relative;
          top: 50px;
          right: 130px;
          z-index: -1;
          transform: rotate(90deg);
        }
      }

      &__text {
        color: $color--accent-charmbray;
        font-family: Noteworthy;
        font-size: 18px;
        font-weight: $fw-bold;
        position: relative;
        bottom: 55px;
        left: 30px;

        &--mobile {
          color: $color--accent-charmbray;
          font-family: Noteworthy;
          font-size: 18px;
          font-weight: $fw-bold;
          position: relative;
          top: 50px;
          right: 75px;
        }
      }
    }

    &__choice {
      height: 40px;
      width: 191px;
      border: 1px solid $color--accent-charmbray;
      background-color: $color--white;
      color: $color--accent-charmbray;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &--mobile {
        height: 40px;
        width: 130px;
        border: 1px solid $color--accent-charmbray;
        background-color: $color--white;
        color: $color--accent-charmbray;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      &--selected {
        height: 40px;
        width: 191px;
        border: 1px solid $color--accent-charmbray;
        background-color: $color--accent-koala;
        color: $color--accent-charmbray;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &--mobile {
          height: 40px;
          width: 130px;
          border: 1px solid $color--accent-charmbray;
          background-color: $color--accent-koala;
          color: $color--accent-charmbray;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .monthly {
      border-radius: 4px 0 0 4px;
      margin-right: -1px;
    }

    .annually {
      border-radius: 0 4px 4px 0;
    }
  }

  &__plans {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 35px;

    &__info {
      width: 30%;
      padding: 50px 2em;
      border-radius: 8px;
      box-shadow: 0 4px 5px 0 $color--14, 0 1px 10px 0 $color--blacky, 0 2px 4px -1px $color--02;
      justify-content: center;
      text-align: center;

      &__header {
        &__plan-name {
          color: $color--blue-main;
          font-size: 26px;
          font-weight: $fw-bold;
          line-height: 26px;
          height: 50px;
        }

        &__price {
          margin-top: 20px;
          display: flex;
          justify-content: center;

          &--sign {
            color: $color--darks-black;
            font-size: 32px;
            font-weight: $fw-medium;
            margin-top: 10px;
          }

          &--price {
            color: $color--darks-black;
            font-size: 60px;
            font-weight: $fw-bold;
            line-height: 60px;
          }
        }

        &__info {
          height: 55px;
          font-size: 16px;
          font-weight: $fw-medium;
          margin-top: 5px;

          &--green {
            color: $color--teal-main;
          }

          &--grey {
            color: $color--dark-scorpion;
            opacity: 0.41;
          }
        }
      }

      &__properties {
        text-align: left;

        &__property {
          display: flex;
          align-items: center;
          margin-top: 5px;

          &__text {
            color: $color--darks-black;
            font-size: 14px;

            &--dimmed {
              color: $color--darks-black;
              opacity: 0.5;
              font-size: 14px;
            }
          }

          &--check {
            background: svg-load('../svg/new-check.svg') no-repeat;
            height: 14px;
            width: 25px;
          }

          &--uncheck {
            background: svg-load('../svg/new-not-check.svg') no-repeat;
            height: 14px;
            width: 25px;
          }
        }
      }

      &--mobile {
        width: 100%;
        padding: 50px 2em;
        border-radius: 8px;
        box-shadow: 0 4px 5px 0 $color--14, 0 1px 10px 0 $color--blacky, 0 2px 4px -1px $color--02;
        justify-content: center;
        text-align: center;
        margin-bottom: 35px;
      }
    }

    &--mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 290px;
      min-width: 250px;
      margin-bottom: 0;
    }

    &__divider {
      margin: 14px 0 9px;
    }

    &__accordion {
      background: $color--white !important;
      border-radius: 0 !important;
      border-left: none !important;

      .title {
        font-size: 16px !important;
        font-weight: $fw-medium;
        color: $color--teal-main !important;
        margin: 0 !important;

        .accordion-title {
          display: flex;
          align-items: center;
          justify-content: center;

          &__icon {
            background: svg-load('../svg/arrow_down_green.svg') no-repeat;
            height: 16px;
            width: 16px;
            margin-right: 10px;
            transition: transform 0.5s ease-out;
          }
        }

        &::after {
          display: none !important;
        }
      }

      .active {
        .accordion-title {
          &__icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__notification {
    width: 100%;
    color: $color--darks-black;
    font-size: 14px;
    font-style: italic;
  }
}

.input-box {
  &--mobile {
    .ui.input {
      width: 40%;
      height: 38px;
      border-radius: 4px;

      > input {
        background-color: $color--accent-splashedwhite;
        border-radius: 8px;
      }
    }
  }

  .ui.input {
    width: 142px;
    height: 49px;
    border-radius: 8px;
    border: solid 1px $color--accent-spindle;

    > input {
      background-color: $color--accent-splashedwhite;
      border-radius: 8px;
    }
  }

  &--frequency {
    width: 20%;
    min-width: 85px;

    &--mobile {
      .ui.input {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
      }
    }

    .ui.input {
      width: 100%;
      height: 35px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
    }
  }
}

.buy-sms-link {
  height: 25px;
  font-family: $font-family;
  color: $color--teal-main;
  font-size: 14px;
  font-weight: $fw-medium;
  text-decoration: underline;
  cursor: pointer;
  line-height: 25px;
  margin-top: 10px;
}

.buy-sms-subheader {
  height: 25px;
  font-family: $font-family;
  color: $color--teal-main;
  font-size: 14px;
  font-weight: $fw-regular;
  line-height: 25px;
  margin-top: 10px;
  font-style: italic;
}

.buy-sms-grid {
  margin: 0 !important;
}

.prices {
  &--real {
    height: 27px;
    color: $color--teal-main;
    font-size: 20px;
    font-weight: $fw-bold;
    line-height: 27px;
  }

  &--discount {
    height: 24px;
    color: $color--blue-royal;
    font-size: 18px;
    font-weight: $fw-medium;
    line-height: 24px;
    text-decoration: line-through;
  }

  &--discount-text {
    height: 19px;
    color: $color--blue-light;
    font-size: 14px;
    font-style: italic;
    font-weight: $fw-medium;
    line-height: 19px;
  }
}

.custom-buttons {
  color: $color--white !important;
  font-size: 16px !important;
  font-weight: $fw-bold;
  line-height: 22px;
  text-align: center;
  width: 100%;
  margin-top: 30px !important;
  height: 38px;
  border-radius: 6px !important;
  box-shadow: 0 4px 5px 0 $color--14, 0 1px 10px 0 $color--blacky, 0 2px 4px -1px $color--02 !important;

  &.spinner {
    color: transparent !important;
    position: relative;

    &::after {
      display: block;
      content: '';
      height: 50px;
      width: 50px;
      background: svg-load('../svg/ripple.svg') no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--downgrade {
    background-color: $color--blue-royal !important;
  }

  &--current {
    background-color: $color--white-inactive !important;
  }

  &--upgrade {
    background-color: $color--blue-main !important;
  }
}

.plan-prices {
  color: $color--blue-main;
  font-weight: $fw-bold;
  text-align: center;
  font-size: 40px;
  margin-bottom: 15px;
}

.plan-prices-mobile {
  @extend .plan-prices;
  font-size: 30px;
}

@media only screen and (max-width: 991px) {
  .plan-prices {
    font-size: 30px;
    margin-bottom: 10px;
  }
}

.payment-select {
  height: 22px;
  display: flex;
  flex-wrap: wrap;

  &__label {
    font-size: 16px;
    vertical-align: top;
    cursor: pointer;
  }

  &__discount {
    color: $color--red-main;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.43px;
    font-weight: $fw-regular;
  }

  label [type="radio"] + span {
    cursor: pointer;
    position: relative;
    border: double 2px;
    background-color: transparent;
    white-space: nowrap;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    display: inline-block;
    margin-right: 5px;
    top: 15%;
    left: 0;

    &__label {
      position: relative;
      bottom: 10%;
    }
  }

  input[type="radio"]:checked + span::before {
    content: '';
    width: 0;
    height: 0;
    display: inline-block;
    border: 3px solid;
    position: relative;
    border-radius: 50%;
    left: 25%;
    bottom: 60%;
  }

  input[type="radio"] {
    display: none;
  }
}

.month {
  margin-bottom: 25px;
}

.mobile-plan-names {
  width: 100%;
  display: flex;
  justify-content: center;

  &__button {
    width: 33%;
    height: 50px;
    border: 2px solid $color--blue-light;
    border-radius: 2px;
    background-color: $color--white;
    color: $color--blue-light;
    font-weight: $fw-bold;
    display: grid;
    text-align: center;
    align-items: center;
    margin: 0 -1px;
  }

  &__button-selected {
    width: 33%;
    height: 50px;
    background-color: $color--blue-light;
    border: 2px solid $color--blue-light;
    border-radius: 3px;
    color: $color--white;
    font-weight: $fw-bold;
    display: grid;
    text-align: center;
    align-items: center;
    margin: 0 -1px;
  }
}

.professional-services {
  padding: 20px 10px 70px 0;

  &__text {
    margin-bottom: 30px;
    color: $color--darks-black;
    font-size: 18px;
    font-weight: $fw-medium;
  }

  &__button {
    font-size: 18px !important;
  }
}

@media (max-width: 768px) {
  .professional-services {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}

.buy-professional {
  &__title {
    color: $color--blue-main;
    font-family: Dosis;
    font-size: 26px;
    font-weight: $fw-bold;
  }

  &__header {
    color: $color--teal-main;
    font-size: 14px;
    font-style: italic;
    margin-top: 10px;
  }

  &__content {
    font-weight: $fw-bold;

    &__title {
      color: $color--blue-main;
      font-family: Dosis;
      font-size: 20px;
      font-weight: $fw-bold;
    }

    &__list {
      width: fit-content;
      margin-top: 20px;

      &__title {
        color: $color--darks-black;
        font-size: 16px;
      }

      &__item {
        color: $color--darks-black;
        font-size: 16px;
        font-style: italic;
        margin-bottom: 10px;
      }
    }

    &__pricing {
      &__text {
        color: $color--blue-royal;
        font-size: 18px;
      }

      &__price {
        color: $color--teal-main;
        font-size: 24px;
        margin-left: 70px;
      }
    }
  }
}

.billing-modal {
  margin: 0 auto 30px !important;
  justify-content: center;
}

.sms-modal-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: max-content;
  margin: 0 auto;

  &__label {
    font-size: 16px;
    font-weight: $fw-medium;
    letter-spacing: -0.3px;
    color: $color--darks-black;
    margin-bottom: 10px;
    width: fit-content;
  }

  &__value {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    &__input {
      > input {
        box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1) !important;
        color: $color--darks-dark-accent !important;
        font-family: $font-family !important;
        font-weight: $fw-medium !important;
        font-size: 14px !important;
        border: none !important;
        background-color: $color--grey-input !important;
        border-top-right-radius: 4px !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-bottom: 1px solid transparent;
        height: 40px !important;

        &:focus,
        &:active {
          border-color: $color--teal-light !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        &::placeholder {
          color: $color--blue-wildblue;
          font-weight: $fw-medium;
        }
      }

      &--bigger {
        max-width: 245px !important;
      }

      &--smaller {
        max-width: 80px !important;
      }
    }

    &__period {
      margin-left: 15px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: $color--darks-black;
      width: fit-content;
    }
  }

  &__disclaimer {
    width: fit-content;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.1px;
    color: $color--dark-scorpion;
    margin-bottom: 10px;
  }

  &__price {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &__price {
      margin-left: 10px;
      width: fit-content;
    }
  }
}

.domain {
  padding: 24px 0 40px;

  &__actions {
    display: flex;
    gap: 1rem;

    &__add {
      @media (max-width: 400px) {
        font-size: 14px !important;
      }
    }
  }

  &__container {
    display: flex;

    &__identities {
      width: 75%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__info {
    background-color: $color--accent-koala;
    padding: 24px;
    width: 100%;

    &__header {
      margin-bottom: 24px;

      &__title {
        font-size: 16px;
        font-weight: $fw-bold;
        letter-spacing: 0.5px;
        color: $color--darks-black;
        padding-bottom: 24px;
      }

      &__domain {
        font-size: 14px;
        font-weight: $fw-medium;
        letter-spacing: -0.15px;
        color: $color--accent-obsidian;
      }
    }

    &__section {
      &__title {
        margin: 0 !important;
        padding: 0 0 16px !important;
        font-size: 16px;
        font-weight: $fw-medium;
        letter-spacing: -0.32px;
        color: $color--accent-obsidian !important;
      }

      &__content {
        margin: 0 !important;
        padding: 0 0 16px !important;
        font-size: 14px;
        letter-spacing: -0.15px;
        color: $color--accent-obsidian;
        line-height: 24px;

        > div > div:last-child {
          margin-top: 16px;
        }
      }
    }

    &__bottom {
      margin-top: 24px;
    }

    &__sidebar {
      margin-top: 16px;
      margin-left: 16px;
      width: calc(25% - 16px);
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 40px;

    &__text {
      font-size: 24px;
      font-family: $font-family-headline;
      font-weight: $fw-bold;
      color: $color--blue-main;
      margin-bottom: 24px;
      text-align: center;
      text-transform: uppercase;
    }

    &__actions {
      display: flex;
      gap: 1rem;

      @media (max-width: 400px) {
        flex-direction: column;
      }
    }
  }

  .spinner__content {
    min-height: 100px;
  }
}

.identity {
  display: flex;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 $color--accent-obsidian-03;
  margin-top: 16px;
  padding: 1.5rem 2rem;

  @media (max-width: 420px) {
    padding: 1.5rem 1rem;
  }

  &--modal {
    display: flex;
  }

  &__icon {
    margin-right: 24px;

    @media (max-width: 420px) {
      margin-right: 10px;
    }

    &__domain {
      background: svg-load('../svg/email-icon.svg') no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
      display: block;
    }

    &__email {
      background: svg-load('../svg/email-icon.svg') no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
      display: block;
    }

    &__sms {
      background: svg-load('../svg/sms-icon.svg') no-repeat;
      background-size: contain;
      height: 24px;
      width: 24px;
      display: block;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    &__title {
      font-size: 16px;
      font-weight: $fw-medium;
      letter-spacing: -0.32px;
      color: $color--accent-obsidian;

      @media (max-width: 420px) {
        font-size: 1rem;
      }
    }

    &__actions {
      display: flex;
      align-items: center;

      &__delete {
        margin-left: 16px;
        background: svg-load('../svg/ic_delete_24px.svg') no-repeat;
        background-size: contain;
        height: 18px;
        width: 18px;
        cursor: pointer;
      }
    }
  }

  &__validity {
    font-size: 14px;
    font-weight: $fw-medium;
    color: $color--teal-light;

    &--pending {
      font-size: 14px;
      font-weight: $fw-medium;
      color: $color--aqua-blue;
    }
  }

  &__validate-domain {
    margin-top: 16px;
    font-size: 14px;
    color: $color--accent-obsidian;
  }

  &__verified {
    margin-top: 16px;
    font-size: 14px;
    letter-spacing: -0.15px;
    color: $color--accent-obsidian;
  }

  &__validation {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 16px;
      font-weight: $fw-bold;
      letter-spacing: 0.8px;
      color: $color--darks-black;
      margin-bottom: 16px;
    }

    &__instructions {
      margin-top: 16px;
      font-size: 14px;
      letter-spacing: -0.15px;
      color: $color--accent-obsidian;
    }

    &__list {
      margin-top: 16px;
      font-size: 14px;
      font-weight: $fw-medium;
      letter-spacing: -0.15px;
      color: $color--accent-obsidian;
    }
  }

  &__domain {
    padding-top: 32px;
  }
}

.verify-domain-type {
  &__label {
    font-size: 14px;
    font-weight: $fw-medium;
    letter-spacing: -0.15px;
    color: $color--accent-obsidian;
    padding-bottom: 8px;
    padding-top: 16px;
  }

  &__select {
    width: 92px !important;
  }

  &__info {
    padding-top: 20px;
    font-size: 12px;
    line-height: 2;
    color: $color--accent-flint;
  }
}

.verify-identity-modal {
  max-width: 600px !important;

  .header {
    padding: 32px 40px 0 !important;
  }

  &__content {
    padding: 35px 40px 32px;
  }

  .actions {
    padding: 24px 40px !important;
  }
}

.verify-domain {
  &__text {
    font-size: 14px;
    letter-spacing: -0.15px;
    color: $color--accent-obsidian;
    line-height: 1.71;
  }

  &__domain {
    font-size: 14px;
    letter-spacing: -0.15px;
    color: $color--accent-obsidian;
    line-height: 1.71;
    cursor: pointer;
    text-decoration: underline;
  }

  &__verification {
    display: flex;
    align-items: flex-end;

    &__button {
      margin-left: 8px !important;
    }

    &__input {
      margin-top: 16px;
      width: 100%;

      > div > div > input {
        border: solid 1px $color--accent-spindle !important;
        background-color: $color--accent-splashedwhite !important;
      }
    }
  }

  &__domain-note {
    font-size: 12px;
    line-height: 2.08;
    color: $color--accent-flint;
    margin-top: 8px;

    &--margin {
      margin-top: 16px;
    }
  }

  &__error {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: $fw-medium;
    letter-spacing: 0.12px;
    color: $color--red-main;
    line-height: 1.71;
  }

  &__disclaimer {
    margin-top: 8px;
    font-size: 12px;
    letter-spacing: 0.12px;
    color: $color--accent-wildblue;
  }
}

.successful-validation {
  width: 100%;
  border-radius: 8px;
  border: solid 1px $color--teal-main;
  background-color: $color--teal-transparent;
  padding: 16px;
  color: $color--accent-obsidian;

  &__header {
    font-size: 16px;
    font-weight: $fw-medium;
    letter-spacing: -0.32px;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    letter-spacing: -0.15px;
  }
}
