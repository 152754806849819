.MessagePreview {
  height: 100%;

  &__attachments-block {
    margin-top: auto;
  }

  &__elements-wrapper {
    margin: auto;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 1em - 120px);
    padding-bottom: 120px;
  }
}
