.WidgetsDesign {
  padding: 17px;
  height: auto;

  .ColorSelector {
    flex-direction: column;

    @media (min-width: 460px) {
      flex-direction: row;
    }
  }

  .ColorSelector__color-palette-container {
    top: 0;
    position: static;

    @media (min-width: 992px) {
      position: absolute;
    }
  }
}
