@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.EditorView {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input-container {
    display: flex;
    align-items: center;
  }

  &__project-name-input {
    font-family: $font-family;
    font-size: 20px;
    font-weight: 600;
    color: $color--darks-martinique;
    border: none;
    outline: none;
    padding-right: 1em;
    background-color: transparent;
  }

  &__buttons-container {
    display: flex;
    gap: 10px;
  }

  &__preview-mobile {
    background-repeat: no-repeat;
    background-size: cover;
    margin: 25px auto;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    background-image: none;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
      max-height: 1063px;
      max-width: 500px;
      width: 36vh;
      background-image: svg-load('../../../assets/svg/iphonex.svg');
      height: 72vh;
      padding-left: 2.4vh;
      padding-right: 2.4vh;
      padding-top: 5vh;
      padding-bottom: 2.1vh;
    }

    .MessageElementsDistributor {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      max-height: calc(96vh - 215px);
      min-height: calc(96vh - 215px);

      @media (min-width: 768px) {
        border-bottom-left-radius: 2.8vh;
        border-bottom-right-radius: 2.8vh;
        max-height: unset;
        min-height: unset;
      }
    }
  }

  &__preview-tablet {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 50vh;
    min-height: 403px;
    max-height: 680px;
    width: 95%;
    min-width: 485px;
    max-width: 70vh;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-radius: 2vh;
    margin: 10px auto;
    padding-left: 0;
    padding-right: 0;

    @media (min-width: 992px) {
      border-top: 3vh solid $color--darks-black;
      border-bottom: 3vh solid $color--darks-black;
      border-left: 5vh solid $color--darks-black;
      border-right: 5vh solid $color--darks-black;
    }
  }

  &__preview-desktop {
    width: 100%;
    min-height: calc(100% - 82px);
    max-width: 1440px;
    margin: 16px auto 0;

    .MessageElementsDistributor {
      min-height: calc(96vh - 215px);
    }
  }
}
