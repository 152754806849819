@import "../../../assets/styles/base/variables-color";

.LayoutButton {
  background-color: $color--white;
  border: 1px solid $color--teal-light;
  border-radius: 10px;
  color: $color--teal-light;
  padding: 7px 5px;

  &__active {
    background-color: $color--teal-light;
    color: $color--white;
  }

  &__desktop {
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }
}
