.ui.header {
  &.delivery-method-header {
    background-color: $color--blue-main;
    color: $color--white;
    min-height: 77px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .white-bordered {
      min-width: unset;
      width: max-content;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;

      > .white-bordered {
        margin-top: 10px;
        margin-bottom: 5px;
      }
    }
  }

  &.delivery-method-footer {
    font-family: $font-family;
    background-color: $color--white-dimmed;
    align-items: center;
    margin-left: -26px;
    margin-right: -26px;
    padding-bottom: 25px;
  }
}

.select-delivery-method {
  display: flex;
  align-items: center;
  font-family: $font-family;
  font-size: 18px;
  font-weight: $fw-medium;
  margin-left: 3%;
  margin-right: 3%;

  .select-delivery-method-label {
    margin: 10px 10px 10px 0;
    font-weight: $fw-bold;
    font-size: 18px;
  }

  > div {
    font-size: 14px;
    font-weight: $fw-bold;
    color: $color--darks-black;

    .visible.menu.transition {
      width: 100px;

      > div > span {
        font-size: 14px !important;
        font-weight: $fw-medium !important;
      }
    }
  }
}

.ui.selection.dropdown.select-delivery-method-dropdown {
  background-color: transparent;
  box-shadow: none;

  > div:first-child {
    margin-right: 2%;
  }

  i.dropdown.icon {
    margin-left: 1%;

    &::before {
      color: $color--white;
    }
  }
}

.delivery-hashtags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > span {
    margin: 0 5px;
  }
}

.charactersCountAfter {
  color: $color--red-main;
}

.characters-count {
  float: right;
  display: inline-flex;
  justify-content: flex-end;
}

.charactersCountBefore {
  color: $color--darks-brown;
}

.character-limit {
  color: $color--red-main;
  display: flex;
  float: right;
}

.keyword-header-character-limit {
  display: flex;
  justify-content: space-between;
}

.character-count-limit {
  display: flex;
  flex-direction: column;
}

.hashtags {
  cursor: pointer;
}

.company-logo-p {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.image-logo {
  max-height: 100px;
  max-width: 100px;
}

.logo-name-delete-span {
  display: flex;
  align-items: center;

  .logo-name {
    margin-right: 4px;
    max-width: 260px;
  }

  .delete-logo {
    background-size: contain;
  }
}

.expirationDate {
  cursor: pointer;
}

.colorPopup {
  cursor: pointer;
}

.delivery-contact-count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 0 5px !important;

  &__label {
    font-size: 18px;
    min-width: 84px;
    font-weight: $fw-medium;
    color: $color--darks-black;
    text-align: left;
  }

  &__value {
    font-size: 14px;
    font-style: italic;
    font-weight: $fw-book;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $color--white-splashed;
    color: $color--white-inactive;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color--white-inactive;
    text-indent: 15px;

    &__notification {
      height: 18px;
      width: 18px;

      &--info {
        background: svg-load("../svg/info_outline.svg") no-repeat;
        position: absolute;
        right: 195px;
        align-self: center;
        height: 15px;
      }

      &--error {
        background: svg-load("../svg/error_outline.svg") no-repeat;
        background-size: contain;
      }
    }
  }
}

@media (max-width: 480px) {
  .delivery-contact-count {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto auto auto;

    &__label {
      padding-bottom: 0.5em;
      align-content: center;
      grid-column: 1 / 2;
    }

    .ui.blue.small.button.tertiary.delivery-method-lists {
      margin-top: 0.5em;
      margin-left: 0 !important;
      float: right;
      grid-row: 3 / 4;
      grid-column: 3 / 4;
    }
  }

  .delivery-list-select {
    grid-column: 1 / 4;
  }
}

.delivery-email-input {
  padding: 0 !important;
  display: flex;
  align-items: center;

  > .field.input {
    width: 100%;
    overflow: hidden;
  }

  .ui.labeled {
    flex-direction: row !important;
    padding: 5px 0 !important;
    align-items: center;

    > input {
      color: $color--darks-black;
      opacity: 1 !important;
      height: 40px;
      background-color: $color--grey-input;
      box-shadow: 0 1px 3px 0 rgba(0, 17, 34, 0.1);
    }

    > .ui.label {
      display: flex;
      align-items: center;
      padding-bottom: 0 !important;
      min-width: 84px;
      font-size: 18px;
    }
  }

  .ui.labeled.disabled {
    border-bottom: none;
    padding: 5px 0 !important;
    opacity: 1 !important;

    > input {
      font-style: italic;
      opacity: 1 !important;
      height: 40px;
      font-size: 14px;
      font-weight: $fw-book;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: $color--white-splashed;
      color: $color--white-inactive;
      border-bottom: 1px solid $color--white-inactive;
    }

    > .ui.label {
      min-width: 84px;
      font-weight: $fw-medium;
      font-size: 18px;
    }
  }

  .ui.labeled.disabled.input > .label {
    color: $color--white-inactive !important;
    font-weight: $fw-medium !important;
    opacity: 1 !important;
  }

  .ui.labeled.input > .label {
    padding-bottom: 0 !important;
  }

  &.field-subject {
    @media (max-width: 525px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 12px !important;

      > button.ui.button {
        margin-left: 84px !important;
        margin-top: 5px !important;
      }
    }
  }

  .personalize-button {
    margin-left: 15px;
  }
}

@media (max-width: 480px) {
  .delivery-email-input {
    display: grid;

    .ui.labeled {
      flex-direction: column !important;
      align-items: flex-start;

      > .ui.label {
        padding: 0 0 0.5em !important;
        text-align: right !important;
        min-width: 0;
      }

      > input {
        min-width: 100%;
      }
    }

    &.field-subject {
      > button.ui.button {
        margin-left: 50% !important;
      }
    }
  }
}

.delivery-link {
  display: inline-flex;
  align-items: center;
  color: $color--blue-light;
  font-size: 12px;
  font-family: $font-family;

  &__add {
    display: flex;
    height: 23px;
    width: 45px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: solid 1px $color--accent-spindle;
    background-color: $color--accent-splashedwhite;
    color: $color--darks-black;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .delivery-link {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
}

.delivery-credits {
  font-family: $font-family;
  font-size: 12px;
  margin-right: 10px;
  white-space: nowrap;

  &--hidden {
    visibility: hidden;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: 605px) {
      flex-direction: column-reverse;

      .delivery-characters-preview {
        padding-bottom: 10px !important;
      }
    }

    .personalize-sms {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
      align-items: center;

      @media (max-width: 768px) {
        justify-content: space-between;
      }

      &.row {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        > button {
          margin-bottom: 10px;
          margin-top: 10px;
          height: 40px;
        }
      }

      &__personalize {
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap-reverse;
        align-items: center;

        > button {
          margin-bottom: 10px;
          margin-top: 10px;
          height: 40px;
        }
      }

      &__left {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap-reverse;
        align-items: center;

        @media (max-width: 768px) {
          justify-content: space-between;
        }

        &.row {
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          > button {
            margin-bottom: 10px;
            margin-top: 10px;
            height: 40px;
          }
        }
      }
    }
  }

  &__values {
    margin-left: 7px;

    &--danger {
      color: $color--red-main;
    }
  }
}

.credits-spinner {
  &__container {
    display: flex;
    align-items: center;

    .spinner__content {
      min-height: 60px !important;
    }

    &__text {
      margin-left: 4px;
    }
  }
}

.from-rules {
  font-size: 12px;
  font-style: italic;
  font-family: $font-family;
  text-align: left;
  line-height: 14px;
  color: $color--dark-scorpion;
  padding-left: 84px;

  &__identity {
    display: flex;
    margin-left: 84px;
    font-size: 14px;
    font-weight: $fw-medium;
    color: $color--faded-red;
  }

  &__verified {
    display: flex;
    margin-left: 84px;
    font-size: 12px;
    letter-spacing: 0.12px;
    color: $color--accent-wildblue;

    &--reload {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media (max-width: 480px) {
  .from-rules {
    padding-left: 0;
  }
}

.personalize-email {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% + 50px);
  margin-right: -25px;
  margin-left: -25px;
  padding: 0 105px 18px;

  @media (max-width: 768px) {
    padding: 0 40px 18px;
    justify-content: space-between;
  }
}

.custom-divider-bottom {
  border-bottom: 1px solid #979797;
  background-color: $color--white-dimmed;
}

.visibility-hidden {
  visibility: hidden;
}

.expiration-wrapper {
  display: flex;
  align-items: inherit;
  margin-top: -10px;

  .react-datepicker-wrapper {
    margin-top: 10px;
  }
}

.expiration-disclaimer {
  color: $color--teal-main;
  font-style: italic;
  font-weight: $fw-regular;
  margin-top: 10px;
}

.wizard-complete {
  margin-bottom: 20px;
  color: $color--blue-main;
}

.another-message-button {
  background-color: $color--white !important;
  color: $color--blue-main !important;
  border: 2px solid $color--blue-main !important;

  .another-text {
    font-size: 20px;
    font-weight: $fw-medium;
    line-height: 33px;
    text-align: center;
  }

  &:hover {
    color: $color--blue-hover !important;
    border: 2px solid $color--blue-hover !important;
  }
}

.success-border {
  width: 60%;
  min-width: 531px;
  max-width: 560px;
  margin: 50px auto 70px;
  border-radius: 14.74px;
  background: radial-gradient(circle, $color--white 0%, $color--success-back1 59.85%, $color--success-back2 100%);
  box-shadow: 0 0 30px 0 $color--success-shadow;

  @media (max-width: 600px) {
    width: unset;
    min-width: unset;
  }
}

.success-background {
  margin-bottom: -10px;
  display: flex;
  justify-content: space-evenly;
  background: svg-load("../svg/success_waves.svg") no-repeat bottom;
  height: 100%;
}

.success-nest {
  background-color: $color--white-splashed;
  border-radius: 14.74px;

  @media (max-width: 600px) {
    padding: 25px;
  }
}

.delivery-email-settings {
  width: calc(100% + 50px);
  margin-right: -25px;
  margin-left: -25px;
  padding: 20px 25px;
  text-align: left;
  font-size: 16px;
  font-weight: $fw-medium;
  border-top: 1px solid $color--white-inactive;

  &__field {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    max-width: 280px;
    flex-wrap: nowrap !important;
    margin-left: 85px;
    word-break: break-all;

    .ui.label {
      min-width: 170px !important;
      align-self: center;
    }

    .ui.labeled.input {
      flex-direction: row !important;
    }

    @media (max-width: 525px) {
      margin-left: 0;
    }
  }

  p {
    margin-bottom: 10px;
  }

  .ui.label {
    font-size: 16px;
    font-family: $font-family;
    font-weight: $fw-medium;
    min-width: 115px;
  }

  .color-field {
    display: flex;
    align-items: center;

    .color-block {
      border: 1px solid $color--white-inactive;
      border-radius: 4px;
      height: 20px;
      margin-right: 5px;
      padding-right: 8px;
      min-width: 20px;
    }

    > input {
      background-color: transparent;
      outline: none;
      border: none;
      font-size: 14px;
      font-family: $font-family;
      font-weight: $fw-medium;
      color: $color--darks-brown;
      text-transform: uppercase;
    }
  }

  > button.ui.basic.blue {
    height: 26px !important;
    width: 85px !important;
    padding: 0;
  }

  &__uploaded-image {
    width: 100%;
    height: auto;
  }

  &__uploaded-image-text {
    margin: 20px 85px;
    display: flex;
    align-items: center;

    > span:first-child {
      margin-right: 12px;
    }

    .modal-close-icon {
      background-size: contain;
      float: initial;
    }

    @media (max-width: 768px) {
      margin: 20px;
    }
  }
}

.delivery-margins {
  margin: 0 85px !important;
  width: calc(100% - 170px);

  @media (max-width: 768px) {
    margin: 0 20px !important;
    width: calc(100% - 40px);
  }
}

.delivery-margins-inputs {
  margin: 0 85px 0 0 !important;
  width: calc(100% - 85px);

  @media (max-width: 768px) {
    margin: 0 20px 0 0 !important;
    width: calc(100% - 20px);
  }
}

.margin-top-10 {
  margin-top: 10px;
}

.delivery-list-select {
  width: 100%;
  white-space: nowrap;

  .ui.dropdown {
    width: 100%;

    .text {
      width: calc(100% - 8px);
      overflow: hidden;
      white-space: nowrap;
    }

    .visible.menu.transition {
      width: 100%;
      max-height: 200px;
      overflow: auto;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 $color--02, 0 1px 10px 0 $color--blacky, 0 4px 5px 0 $color--14;

      .contact-list-selection-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
          font-size: 16px;
          font-weight: $fw-medium;
          letter-spacing: -0.3px;
          color: $color--darks-black;

          &--active {
            font-size: 16px;
            font-weight: $fw-medium;
            letter-spacing: -0.3px;
            color: $color--blue-light;
          }
        }

        &__count {
          font-size: 12px;
          font-weight: $fw-regular;
          letter-spacing: 0.1px;
          color: $color--dark-scorpion;
        }

        &__new {
          font-size: 14px;
          font-weight: $fw-bold;
          color: $color--teal-main;
          display: flex;
          align-items: center;

          &::before {
            background: svg-load('../svg/add_teal.svg') no-repeat;
            background-size: contain;
            height: 10px;
            width: 10px;
            content: "";
            margin-left: 7px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.dropdown-icon-margin {
  .text {
    max-width: calc(100% - 30px);
  }

  .dropdown.icon {
    margin-right: 25px !important;
  }
}

.contact-list-selected-count {
  font-size: 12px;
  font-weight: $fw-regular;
  letter-spacing: 0.1px;
  color: $color--dark-scorpion;
  position: absolute;
  right: 200px;

  &--margin {
    right: 225px;
  }

  &__mobile {
    font-size: 12px;
    font-weight: $fw-regular;
    letter-spacing: 0.1px;
    color: $color--dark-scorpion;
  }
}

.delivery-method-email {
  width: 57.5%;
  margin-left: 8px;
  margin-right: 4px;

  .ui.dropdown.search {
    width: 100%;

    .text {
      width: calc(100% - 8px);
      overflow: hidden;
      white-space: nowrap;
    }

    .visible.menu.transition {
      min-width: 100%;
    }
  }

  &__name {
    width: 42.5% !important;
  }
}

@media (max-width: 480px) {
  .delivery-method-email {
    margin: 0;

    &__name {
      width: 57.5% !important;
    }
  }
}

.delivery-method-lists {
  white-space: nowrap;
  margin-left: 16px !important;
  min-width: 115px;
}

.delivery-method-url {
  padding-top: 5px;
  min-height: 60vh;

  section {
    padding: 20px 0;
  }

  .delivery-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DBDBDB;

    .status {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__label {
        color: $color--accent-obsidian;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        margin-right: 1rem;
      }

      &__badge {
        display: flex;
        align-items: center;
      }

      &__icon {
        width: 9px;
        height: 9px;
        margin-right: 0.5rem;
        background-size: contain;
        background-position: center;
      }

      &__text {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
      }

      &--live {
        color: $color--teal-main;

        .status__icon {
          background: svg-load('../svg/circle-green.svg') no-repeat;
        }
      }

      &--draft {
        color: $color--white-silver;

        .status__icon {
          background: svg-load('../svg/circle-grey.svg') no-repeat;
        }
      }
    }
  }

  .delivery-url-output {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .url {
      &__label {
        color: $color--accent-obsidian;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        margin-right: 1rem;
      }

      &__field {
        width: 100%;

        &.disabled {
          cursor: not-allowed;
        }

        button {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .delivery-url-output {
    flex-direction: column;
    align-items: flex-start !important;

    .url {
      &__field {
        width: 50% !important;
      }
    }
  }
}

.publishedUrlDisclaimer {
  background-color: #6E8CA9 !important;
  color: #FFF !important;
}

/*
  Styles for Automation Sidebar
  Delivery Method
*/
.automation-sidebar {
  .delivery-method-header {
    button {
      font-size: 10px !important;
    }
  }

  .delivery-method-email {
    &__name {
      width: 80% !important;
      overflow: visible;
    }
  }
}
