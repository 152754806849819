.message-mobile {
  width: 90%;
  max-width: 399px;
  border-radius: 8px;
  background-color: $color--white;
  box-shadow: 0 2px 2px 0 $color--14, 0 3px 1px -2px $color--blacky, 0 1px 5px 0 $color--02;
  margin-top: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div:not(:first-child) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__image {
    background-color: $color--white-dimmed;
    background-repeat: no-repeat;
    height: 120px;
    width: 100%;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-size: cover;
    background-position: top;
    cursor: pointer;
  }

  &__description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    flex-wrap: wrap-reverse;
    margin-top: 15px;

    &__name {
      font-weight: $fw-bold;
      font-size: 16px;
      color: $color--darks-black;
      text-align: left;
      margin-right: 15px;
      cursor: pointer;
      width: 100%;
      word-break: break-word;
    }

    &__tag-and-name {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    &__tag {
      border-radius: 8px;
      font-size: 10px;
      font-weight: $fw-bold;
      padding: 0.5px 6.5px;
      min-width: 41px;

      &--black {
        background-color: $color--darks-black;
        color: $color--white;
        word-break: break-word;
        max-width: 175px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &--email {
        background-color: $color--teal-main;
        color: $color--white;
        margin-right: 6px;
      }

      &--sms {
        background-color: $color--teal-tag;
        color: $color--white;
        text-transform: uppercase;
        margin-right: 6px;
      }
    }
  }

  &--status {
    font-weight: $fw-medium;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 15px;

    &__Sent {
      color: $color--teal-light;
    }

    &__Frozen {
      color: $color--blue-royal;
    }

    &__Draft {
      color: $color--blue-light;
    }

    &__Scheduled,
    &__Active-sending {
      color: $color--teal-light;
    }
  }

  &__date {
    &__name {
      width: 100%;
      color: $color--dark-scorpion;
      font-size: 12px;
      line-height: 17px;
      text-align: left;
      margin-bottom: 7px;
    }

    &__time {
      width: 100%;
      color: $color--darks-black;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
    }

    &--small {
      min-width: 60px;
    }
  }

  .icons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;

    > span {
      height: 20px;
      width: 20px;
    }

    .message-icon {
      background-repeat: no-repeat;
      height: 20px;
      width: 19px;
      background-size: 100%;
      display: block;
      margin: 0 auto;
      cursor: pointer;

      &__edit {
        background-image: svg-load('../svg/ic_edit_24px.svg');
      }

      &__preview {
        background-image: svg-load('../svg/baseline-visibility-24px.svg');
        width: 23px;
        background-position: bottom;
      }

      &__chart {
        background-image: svg-load('../svg/ic_insert_chart_24px.svg');
      }

      &__chart_disabled {
        background-image: svg-load('../svg/ic_insert_chart_disabled_24px.svg');
        cursor: default;
      }

      &__email {
        background-image: svg-load('../svg/ic_stacked_email_24px.svg');
        width: 22px;
      }

      &__linkoff {
        background-image: svg-load('../svg/ic_link_off_24px.svg');
        fill: $color--darks-black;
        width: 22px;
      }

      &__link {
        background-image: svg-load('../svg/ic_link_24px.svg', 'fill=#{$color--blacky}');
        background-position: center top 5px;
        width: 22px;
      }

      &__link-draft {
        background-image: svg-load('../svg/ic_link_off_24px_for_draft.svg');
        width: 22px;
        cursor: default;
      }

      &__delete {
        background-image: svg-load('../svg/ic_delete_24px.svg');
        width: 16px;
      }
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    &__data {
      display: flex;
      margin-top: 10px;

      &__value {
        margin-right: 16px;
        min-width: 80px;
      }
    }
  }

  &__description-wrapper {
    padding-left: 15px;
  }
}
