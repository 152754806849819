@import "../../../../../../../assets/styles/base/variables-color";

.MultipleAnswerQuestionController {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 0;

  &__answers-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__answer-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__answer-button-oneAnswer {
    background: transparent;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-bottom: 4px;
    border: none;
    background-image: svg-load('../../../../../../../assets/svg/new-check-inactive-empty.svg');
    background-repeat: no-repeat;
    background-size: cover;

    &:disabled {
      cursor: auto;
    }

    &--correct-answer {
      background-image: svg-load('../../../../../../../assets/svg/new-check.svg');
    }
  }

  &__answer-button-multipleAnswers {
    background: transparent;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    height: 24px;
    width: 24px;
    margin-bottom: 4px;
    cursor: pointer;
    border: none;
    background-image: svg-load('../../../../../../../assets/svg/new-check-inactive.svg');
    background-repeat: no-repeat;
    background-size: cover;

    &:disabled {
      cursor: auto;
    }

    &--correct-answer {
      background-image: svg-load('../../../../../../../assets/svg/new-check.svg');
    }
  }

  &__remove-answer-button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $color--very-black;
  }

  &__add-option-button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $color--teal-main;
    font-size: 14px;
    font-weight: 700;
  }

  &__correct-answer {
    input {
      border: 2px solid $color--teal-light;
    }
  }
}
