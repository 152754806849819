@import "../../../../../assets/styles/base/variables-color";

.SurveyInfoController {
  padding: 17px 17px 34px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    gap: 12px;

    p {
      color: $color--darks-black;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      width: 100%;
    }

    @media (min-width: 500px) {
      flex-direction: row;
      align-items: center;

      p {
        width: 25%;
      }
    }
  }

  &__input-wrapper-alternative {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 12px;

    .ColorSelector__color-palette-container {
      width: unset;
    }
  }

  &__toggle-input-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
  }

  &__toggle-input-label {
    color: $color--darks-brown;
    font-size: 16px;
    font-weight: 600;
  }
}
