.main-nofix__meta {
  .ui.container {
    margin-top: 0;
  }
}

.terms {
  text-decoration: underline;
  color: $color--white;
  cursor: pointer;
}

.editor-grid-container {
  .ui.grid {
    margin: 0;
    align-items: baseline;
  }
}

.ris-form {
  .row {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.row.no-spacing,
.column.no-spacing {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ui.grid.centered.message-wrapper {
  justify-content: space-evenly;
}

.row-flexend {
  justify-content: flex-end !important;
}

.editor-modal {
  .ui.grid {
    margin: 0 !important;
  }
}

.ui.row.nowrap {
  flex-wrap: nowrap;
}

.ui.grid > .row.header-content-row {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }

  > .middle.aligned.column {
    min-width: 140px;
  }
}

.rrv-sb.row {
  justify-content: space-between !important;

  > .ui.icon.input {
    > input {
      font-family: $font-family;
      font-size: 20px;
      font-weight: $fw-medium;
      color: $color--darks-martinique;
      border: none;
      outline: none;
      padding-right: 1em !important;
      background-color: transparent;

      @media (max-width: 992px) {
        font-size: 18px;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    > i.material-icons {
      display: flex;
      align-items: center;
    }
  }
}

.row.delivery-characters-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -10px;
  padding-bottom: 0 !important;

  > span {
    display: flex;
    align-items: center;
  }

  > button {
    width: 117px !important;
    height: 34px;
    padding: 0;
    font-size: 18px !important;

    &:hover {
      background-color: $color--blue-cerulean;
      cursor: pointer;
    }
  }
}

.react-datepicker-time__header {
  color: $color--white !important;
}

.row.delivery-expires {
  justify-content: flex-start !important;
  align-items: flex-end !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 15px !important;
  margin-left: 80px !important;

  @media (max-width: 600px) {
    padding-left: 15px !important;
  }

  > div:first-child {
    margin-right: 20px !important;
  }

  .ui.toggle.checkbox input:checked ~ .box::before,
  .ui.toggle.checkbox input:checked ~ label::before {
    background-color: $color--teal-light !important;
  }

  .ui.toggle.checkbox input:checked ~ .box::after,
  .ui.toggle.checkbox input:checked ~ label::after {
    background-color: $color--teal-main;
  }

  .react-datepicker-wrapper {
    input {
      border: none;
      outline: none;
      background-color: transparent;
      font-family: $font-family;
      color: $color--teal-main;
      font-size: 16px;
      font-weight: $fw-medium;
    }
  }
}

.delivery__actions {
  flex-direction: column !important;
  gap: 1rem;

  &__error-message {
    color: $color--red-password;
  }
}

@media (max-width: 480px) {
  .row.delivery-expires {
    margin-left: 0 !important;
  }
}

.column.delivery-preview-column {
  padding-top: 10px;

  > button {
    width: 117px !important;
    height: 34px;
    padding: 0;
    font-size: 18px !important;

    &:hover {
      background-color: $color--blue-cerulean;
      cursor: pointer;
    }
  }
}

.editor-mobile-modal {
  cursor: pointer;
  float: right;
  padding-right: 1em;
}

.survey-settings-text {
  padding-left: 1em;
  padding-right: 1em;
  color: $color--teal-main;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .ui.doubling.grid > .row > .column,
  .ui.grid > .doubling.row > .column {
    &.message__body__thumb {
      display: flex !important;
    }
  }
}

.ui.grid.no-spacing {
  margin: 0;
}
