.Select {
  min-width: 200px;
  max-width: 352px;

  &.react-select {
    .Select-control {
      border-color: $color--accent-spindle;
      background: $color--accent-splashedwhite;
      background-color: $color--accent-splashedwhite;

      &.is-clearable {
        border-color: $color--accent-spindle;
        background-color: $color--accent-splashedwhite;
      }

      .Select-value {
        color: $color--accent-obsidian;
        border-radius: 8px;
        background-color: $color--accent-splashedwhite;
        border-color: $color--accent-spindle;

        .Select-value-icon {
          padding: 1px 5px;

          &:hover {
            color: $color--almost-black;
          }
        }
      }
    }

    &.is-focused {
      .Select-control {
        border-color: $color--teal-main !important;
        box-shadow: 0 0 4px 1px rgba(28, 181, 159, 0.31);
      }
    }
  }

  &--multi .Select-value {
    display: inline-flex;
    align-items: center;
  }
}
