@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.TextWidgetController {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__controller-body {
    padding: 17px;
    max-width: 934px;
    margin: 0 auto;
    width: 100%;
  }

  &__keywords-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__keyword {
    color: $color--teal-main;
    cursor: pointer;
    font-family: $font-family;
    font-size: 12px;
    background-color: $color--white;
    border: 1px solid $color--teal-light;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    padding: 4px 10px;
  }

  &__label {
    color: $color--darks-black;
    font-size: 13px;
    font-style: italic;
    margin-bottom: 13px;

    &--alternative {
      color: $color--blue-royal;
      font-weight: 500;
    }
  }
}
