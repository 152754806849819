@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.WidgetOptInUI {
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  font-family: $font-family;

  .hidden {
    display: none !important;
  }

  .header {
    position: relative;

    .WidgetTextUI {
      position: absolute;
      top: 40%;
      text-align: center;
    }
  }

  .body {
    padding: 0 21px;

    &__input-fields {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 12px 0;
    }

    &__input-field {
      width: 100%;
      box-sizing: border-box;
      background-color: $color--accent-splashedwhite;
      border: 1px solid $color--grey-border;
      border-radius: 8px;
      font-family: $font-family;
      font-weight: 600;
      font-size: 14px;
      padding: 9.5px 15px;
      line-height: 1;
    }

    &__submit-button {
      margin: 15px auto;
      padding: 0.6em 1em 0.5em;
      font-weight: 700;
      height: auto;
      max-width: unset !important;
      background-color: $color--blue-main;
      color: $color--white;
      width: 100%;
      min-width: 160px;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      font-size: 16px;
      cursor: pointer;
      display: block;
      text-align: center;
      justify-content: center;
      border: none;
      overflow: hidden;
      overflow-wrap: break-word;
      font-family: $font-family-message;
      pointer-events: all;

      &:disabled {
        background-color: $color--white-inactive !important;
        pointer-events: none;
      }
    }

    &__checkbox {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__checkbox {
    margin-bottom: 9px;
  }

  .footer {
    padding: 0 21px;
  }
}
