.automation-node {
  width: 600px;
  display: flex;
  justify-content: center;
  position: absolute;

  &__container {
    position: relative;

    &__icon {
      width: 36px;
      height: 36px;
      position: absolute;
      top: calc(50% - 18px);
      left: -18px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;
      cursor: default;

      &::before {
        content: '';
        background-repeat: no-repeat;
        background-size: contain;
        height: 19px;
        width: 19px;
        display: block;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 264px;
      max-width: 100%;
      border: solid 2px;
      border-radius: 8px;
      color: $color--darks-black;
      background-color: $color--white;
      position: relative;
      z-index: 2;
      cursor: pointer;

      &__title {
        width: 100%;
        font-weight: $fw-medium;
        text-align: center;
        padding: 16px 24px;
      }

      &__info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 4px 0 8px;

        &__column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          &__type {
            opacity: 0.8;
            font-size: 8px;
            line-height: 8px;
            padding-top: 4px;
          }

          &__count {
            font-size: 11px;
            line-height: 11px;
            padding-top: 4px;
          }
        }
      }
    }

    &__actions {
      position: absolute;
      top: 0;
      left: calc(100% - 6px);
      width: 38px;
      height: 64px;
      background-color: $color--darks-black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 4px 0 4px 6px;
      border-radius: 0 4px 4px 0;
      z-index: 1;
      cursor: default;

      &.no-message {
        height: 44px;
      }

      &.flow-final {
        height: 24px;
      }

      &__delete {
        background: svg-load('../svg/ic_delete_24px-white.svg') no-repeat;
        background-size: contain;
        height: 16px;
        width: 16px;
        display: block;
        cursor: pointer;
        z-index: 2;
        margin-left: 2px;
      }

      &__message {
        background: svg-load('../svg/email.svg') no-repeat;
        background-size: contain;
        height: 12px;
        width: 12px;
        display: block;
        cursor: pointer;
        z-index: 2;
      }

      &__contacts {
        background: svg-load('../svg/person.svg') no-repeat;
        background-size: contain;
        height: 16px;
        width: 16px;
        display: block;
        cursor: pointer;
        z-index: 2;
      }
    }

    &.action {
      .automation-node__container {
        &__icon {
          background-color: $color--teal-main;

          &::before {
            background-image: svg-load('../svg/action.svg');
            height: 24px;
            width: 24px;
          }
        }

        &__content {
          border-color: $color--teal-main;
          @include automation-node-box-shadow($color--teal-main, 0.5);

          &__info {
            @include background-opacity($color--teal-main, 0.3);
          }
        }
      }

      &.selected {
        .automation-node__container__content {
          @include automation-node-box-shadow($color--teal-main, 0.8);
        }
      }
    }

    &.filter {
      .automation-node__container {
        &__icon {
          background-color: $color--aqua-blue;

          &::before {
            background-image: svg-load('../svg/decision.svg');
          }
        }

        &__content {
          border-color: $color--aqua-blue;
          @include automation-node-box-shadow($color--aqua-blue, 0.5);

          &__info {
            @include background-opacity($color--aqua-blue, 0.3);
          }
        }
      }

      &.selected {
        .automation-node__container__content {
          @include automation-node-box-shadow($color--aqua-blue, 0.8);
        }
      }
    }

    &.other {
      .automation-node__container {
        &__icon {
          background-color: $color--blue-hoverblue;

          &::before {
            background-image: svg-load('../svg/clock.svg');
          }
        }

        &__content {
          border-color: $color--blue-hoverblue;
          @include automation-node-box-shadow($color--blue-hoverblue, 0.5);

          &__info {
            @include background-opacity($color--blue-hoverblue, 0.3);
          }
        }
      }

      &.selected {
        .automation-node__container__content {
          @include automation-node-box-shadow($color--blue-hoverblue, 0.8);
        }
      }
    }

    &.send-email {
      .automation-node__container {
        &__icon {
          background-color: $color--teal-light;

          &::before {
            background-image: svg-load('../svg/mail.svg');
            height: 16px;
            width: 20px;
          }
        }

        &__content {
          border-color: $color--teal-light;
          @include automation-node-box-shadow($color--teal-light, 0.5);

          &__info {
            @include background-opacity($color--teal-light, 0.3);
          }
        }
      }

      &.selected {
        .automation-node__container__content {
          @include automation-node-box-shadow($color--teal-light, 0.8);
        }
      }
    }

    &.send-sms {
      .automation-node__container {
        &__icon {
          background-color: $color--teal-light;

          &::before {
            background-image: svg-load('../svg/phonelink-ring.svg');
            height: 19px;
            width: 19px;
          }
        }

        &__content {
          border-color: $color--teal-light;
          @include automation-node-box-shadow($color--teal-light, 0.5);

          &__info {
            @include background-opacity($color--teal-light, 0.3);
          }
        }
      }

      &.selected {
        .automation-node__container__content {
          @include automation-node-box-shadow($color--teal-light, 0.8);
        }
      }
    }
  }
}

.automation-flow-final-node {
  margin: 0 276px;
  position: absolute;
  width: 48px;

  &__container {
    width: 48px;
    height: 96px;
    background-color: $color--accent-splashedwhite;
    border: solid 2px $color--faded-red;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;

    &__info {
      @include background-opacity($color--faded-red, 0.3);
      width: 100%;
      height: 44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &::before {
      content: '';
      background: svg-load('../svg/exit-to-app.svg') no-repeat;
      background-size: contain;
      height: 44px;
      width: 44px;
      display: block;
      padding: 9px;
    }
  }
}

.edge {
  position: absolute;
  z-index: 0;

  &__path {
    fill: none;
    stroke: $color--blue-main;
    stroke-width: 2;
  }

  &__text {
    font-family: $font-family;
    font-size: 14px;
    font-weight: $fw-bold;
    color: $color--darks-black;
    height: 33px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      background-color: $color--accent-splashedwhite;
      padding: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  &__insert {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--top {
      flex-direction: column-reverse;
    }

    &__icon {
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background-color: $color--accent-splashedwhite;
      box-shadow: 0 0 2px 0 $color--02;
      border: solid 2px $color--blue-main;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      &::before {
        background-image: svg-load('../svg/add_blue.svg');
        height: 12px;
        width: 12px;
        content: '';
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
      }
    }

    &__path {
      height: 16px;
      border: solid 1px $color--blue-main;

      &--disabled {
        display: flex;
        width: 0;
        height: 100%;
        margin: auto;
      }
    }
  }

  &__new-node-modal {
    max-width: 720px;

    .header {
      padding: 1.5rem 1.5rem 0 !important;
    }

    .content {
      padding: 1rem !important;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      &__item {
        width: 326px;
        border-radius: 4px;
        border: solid 1px $color--accent-spindle;
        padding: 24px 16px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: $color--accent-flint;
        margin: 4px;

        &:hover {
          background-color: $color--accent-spindle;
        }

        &--icon {
          width: 48px;
          height: 48px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: default;
          margin-right: 8px;

          &::before {
            content: '';
            background-repeat: no-repeat;
            background-size: contain;
            height: 19px;
            width: 19px;
            display: block;
          }

          &.action {
            background-color: $color--teal-light;

            &::before {
              background-image: svg-load('../svg/action.svg');
              height: 24px;
              width: 24px;
            }
          }

          &.filter {
            background-color: $color--aqua-blue;

            &::before {
              background-image: svg-load('../svg/decision.svg');
            }
          }

          &.other {
            background-color: $color--blue-hoverblue;

            &::before {
              background-image: svg-load('../svg/clock.svg');
            }
          }
        }

        &--text-container {
          width: calc(100% - 56px);
        }

        &--type {
          font-size: 16px;
          font-weight: $fw-medium;
          letter-spacing: -0.32px;
          color: $color--accent-obsidian;
        }
      }
    }
  }
}

.initial-node {
  display: flex;
  flex-direction: column;
  width: 272px;
  align-items: center;
  position: absolute;
  left: calc(50vw - 136px);

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 88px;
    background-color: $color--accent-koala;
    border: dashed 2px $color--blue-main;
    border-radius: 8px;
    font-size: 14px;
    font-weight: $fw-medium;
    letter-spacing: -0.15px;
    color: $color--blue-main;
    cursor: pointer;
  }
}
