// stylelint-disable
// RISPONS main colors, followed by the style guide:
$color--white: #FFF;    // background
$color--white-splashed: #F8F8F8; // background color of account settings form
$color--white-dimmed: #F3F3F3;
$color--white-grey: #E0E0E0; // table element borders
$color--white-inactive: #CCC;
$color--white-silver: #9B9B9B;
$color--grey-survey: #ECEFF1; // survey question background
$color--grey-border: #CBD6E2; // input normal state border
$color--grey-input: #F5F8FA; // input background-color
$color--darks-martinique: #33334A;
$color--darks-dark-accent: #37474F; // Input placeholder
$color--darks-aliuminium: #808891; // Message unsubscribe text color, form input placeholders
$color--dark-scorpion: #5C5C5C; // editors border
$color--darks-answer: #474747; // Analytics answer
$color--darks-brown: #3B393B; // contact filter form header
$color--darks-black: #012;    // tags
$color--blue-main: #036;    // buttons
$color--blue-hoverblue: #004D99; //
$color--blue-royal: #0052A1; // boxes
$color--blue-science: #0267C9; // button::hover state
$color--blue-hover: #1678C2;
$color--blue-cerulean: #00A5D0; // navigation onhover
$color--blue-light: #00B5E5; // editor header button
$color--blue-glow: #3CF;
$color--blue-celeste: #8CE2FE; // header message button
$color--blue-cyan: #87CEFA;
$color--duck-egg-blue: #D0EFEB;
$color--blue-wildblue: #7C98B6; // input placeholders
$color--blue-lilly: #EBF9FF; // Plans background
$color--blue-cyan-light: #F4F9FF; // dropdowns background
$color--blue-inputs: #E5F0FF; // Input background
$color--teal-dark: #0D6856; // header account name and surname
$color--teal-main: #007A69; // boxes
$color--teal: #00B5AD; // statistics numbers
$color--teal-light: #1CB59F; // message item status
$color--teal-tag: #66D5C6; // sms tag
$color--green-success: #CDF284; // notification success
$color--green-password: #21BA45; // password validity icon
$color--red-password: #DB2828; // password validity icon
$color--red-main: #DF494F; // input error message

// Zeplin styleguide color variables

$color--accent-splashedwhite: #F5F8FA; // Editor form backgrounds

// custom colors, NOT followed by the style guide:
$color--light-pink: #FED; //Contacts table header checkbox for inverting
$color-light-pink: #E9E9E9;
$color--blacky: rgba(0, 0, 0, 0.12);
$color--05: rgba(0, 0, 0, 0.05);
$color--02: rgba(0, 0, 0, 0.2);
$color--14: rgba(0, 0, 0, 0.14);
$color--19: rgba(0, 0, 0, 0.19);
$color--03: rgba(0, 0, 0, 0.3);
$color--23: rgba(0, 0, 0, 0.23);
$color--24: rgba(0, 0, 0, 0.24);
$color--25: rgba(0, 0, 0, 0.25);
$color--95: rgba(0, 0, 0, 0.95);
$color--pitch-black: #1B1C1D;
$color--dimmed-grey: #F3F3F3;
$color--grey--darker: #9B9B9B;
$color--answer: #474747;
$color--blured-grey: #C1C1C1;
$color--grey-scorpion: #5C5C5C; //editors border
$color--blue-hover: #1678C2;
$color--success-back1: rgba(242, 254, 253, 0.23);
$color--success-back2: rgba(28, 181, 159, 0);
$color--success-shadow: rgba(102, 213, 198, 0.3);
$color--label-shadow: rgba(0, 17, 34, 0.1);
$color-select-shadow: rgba(230, 235, 241, 0.4);
$color--aqua-blue: #00B5E5;
$color--dark-navy: rgba(0, 17, 34, 0.7);
$color--ice-blue-06: rgba(236, 239, 241, 0.6);
$color--very-black: #000;
$color--almost-black: #0D0D0D;
$color--almost-black2: #010101;
$color--accent-spindle: #CBD6E2;
$color--accent-obsidian: #33475B;
$color--border-accent: #979797;
$color--accent-obsidian-03: rgba(51, 71, 91, 0.3);
$color--accent-wildblue: #7C98B6;
$color--teal-transparent: #D0EFEB;
$color--accent-charmbray: #425B76;
$color--faded-red: #DF494F;
$color--golden-yellow: #F9C51F;
$color--accent-koala: #EAF0F6;
$color--teal-darker: rgba(28, 181, 159, 0.31);
$color--soft-pink: #F4BEC0;
$color--accent-obsidian-21: rgba(51, 71, 91, 0.21);
$color--header-shadow: #777;
$color--light-sky-blue: #CCE6FF;
$color--background-dimmer: rgba(59, 57, 59, 0.58);
$color--accent-flint: #516F90;
$color--blue-plans: #07396A;
$color--plans-enterprise: #BACFE5;
$color--plans-platinum: #D4E1EF;
$color--plans-gold: #E6EEF6;
$color--plans-silver: #F4F7FA;
$color--plans-free: #FFF;
$color--silver-blue: #7994AE;
$color--sky-blue: #00ABE7;
$color--51: rgb(51, 51, 51);

// Overlay colors
$color--transparent: rgba(255, 255, 255, 0);

$chart-shadow: 0 4px 5px 0 $color--14, 0 1px 10px 0 $color--blacky, 0 2px 4px 0 $color--02;
$border: 0 4px 8px 0 $color--14, 0 6px 20px 0 $color--19;

// shadows
$message: 0 4px 5px 0, 0 1px 10px 0 $color--blacky, 0 2px 4px 0 $color--02;
$block: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

@mixin box-shadow($type: 'message') {
  box-shadow: $type;
  border-radius: 8px;
}

.green {
  color: $color--green-password;
}

.sea-blue {
  color: $color--teal-main;
}

@mixin background-opacity($color, $opacity) {
  background-color: rgba(red($color), green($color), blue($color), $opacity);
}

@mixin automation-node-box-shadow($color, $opacity) {
  box-shadow: 0 1px 20px 0 rgba(red($color), green($color), blue($color), $opacity);
}
