@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.EditorViewHeader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;

  @media (min-width: 520px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }

  &__input-container {
    display: flex;
    align-items: center;
  }

  &__project-name-input {
    font-family: $font-family;
    font-size: 20px;
    font-weight: 600;
    color: $color--darks-martinique;
    border: none;
    outline: none;
    padding-right: 1em;
    background-color: transparent;
  }

  &__buttons-container {
    display: none;

    @media (min-width: 768px) {
      gap: 10px;
      display: flex;
    }
  }
}
