@import "../../../assets/styles/base/variables-color";
@import "../../../assets/styles/base/fonts";

.device-form__button {
  margin: 15px auto;
  padding: 12px 1em;
  font-weight: 600;
  height: auto;
  max-width: unset !important;
  background-color: $color--blue-royal;
  color: $color--white;
  width: 100%;
  min-width: 160px;
  border-radius: 40px;
  box-shadow: 0 2px 4px 0 $color--02;
  font-size: 24px;
  cursor: pointer;
  display: block;
  text-align: center;
  justify-content: center;
  border: none;
  overflow: hidden;
  overflow-wrap: break-word;
  font-family: $font-family-message;
}
