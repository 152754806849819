.notfound {
  &__header {
    height: 362px;
    background-color: $color--darks-black;
    color: $color--white;
    font-family: $font-family-headline;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 62px;

    &--big {
      font-size: 64px;
      line-height: 64px;
      font-weight: $fw-bold;
      margin: 0 50px 50px;
    }

    &--small {
      font-size: 24px;
      font-weight: $fw-bold;
      margin: 0 50px;
    }
  }

  &__content {
    min-height: calc(100vh - 422px);
    padding-top: 6em;
    overflow: hidden;

    &--text {
      color: $color--darks-martinique;
      font-size: 20px;
      font-style: italic;
      text-align: center;
      white-space: nowrap;
      min-width: 355px;
      max-width: 500px;
    }
  }

  &__flexable {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    > div {
      position: relative;
      height: 120px;
      max-width: 500px;
    }

    .ui.button {
      margin-top: 15px;
      font-size: 20px;
      font-weight: $fw-bold;
      white-space: nowrap;
      position: absolute;
      padding: 20px 45px;
      border-radius: 15px;
      bottom: 0;
      min-width: 360px;
      max-width: 500px;
    }
  }

  @media (max-width: 1100px) {
    &__flexable.ui.grid {
      align-items: center;
      flex-direction: column;
    }
  }

  &__footer {
    height: 60px;
    background-color: $color--darks-black;
  }

  &__validation {
    &__header {
      height: 362px;
      background-color: $color--white;
      color: $color--blue-main;
      font-family: $font-family-headline;
      display: flex;
      justify-content: center;
      align-items: center;

      &--big {
        font-size: 64px;
        line-height: 64px;
        font-weight: $fw-bold;
        margin: 0 50px 50px;
      }

      &--small {
        font-size: 24px;
        font-weight: $fw-bold;
        margin: 0 50px;
      }
    }

    &__content {
      min-height: calc(100vh - 550px);

      &--text {
        color: $color--darks-martinique;
        font-size: 20px;
        font-style: italic;
        text-align: center;
        white-space: nowrap;
        min-width: 355px;
        max-width: 500px;
      }
    }

    &__flexable {
      display: flex;
      width: 100%;
      justify-content: space-evenly;

      > div {
        position: relative;
        height: 120px;
        max-width: 500px;
      }

      .ui.button {
        margin-top: 15px;
        font-size: 20px;
        font-weight: $fw-bold;
        white-space: nowrap;
        position: absolute;
        padding: 20px 45px;
        border-radius: 15px;
        bottom: 0;
        min-width: 360px;
        max-width: 500px;
      }
    }

    @media (max-width: 1100px) {
      &__flexable.ui.grid {
        align-items: center;
        flex-direction: column;
      }
    }

    &__footer {
      height: 60px;
      background-color: $color--white;
    }
  }
}
