// stylelint-disable
@import url('https://fonts.googleapis.com/css?family=Dosis:400,500,700|Open+Sans:400,600,700');

// Font Families:
$font-family: "Open Sans";
$font-family-headline: "Dosis";
$font-family-message: 'Helvetica Neue';

// Font Weights:
$fw-book: 400;
$fw-regular: 500;
$fw-medium: 600;
$fw-bold: 700;

// Mixins:
@mixin montserrat($weight, $style) {
  font-family: $font-family;
  font-weight: $weight;
  font-style: $style;
}

@mixin primary-font($weight: $fw-regular, $style: normal) {
  @include montserrat($weight, $style);
}

@mixin normal-font() {
  font-size: 14px;
}

@mixin antialias($apply:true) {
  @if ($apply) {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  } @else {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

@mixin open-sans($weight: '500') {
  font-family: $font-family;
  font-weight: $weight;
}
