.overall__sent-playback {
  display: block;
  height: 19px;
  text-align: right;
  position: relative;
  bottom: 32px;
  margin: 0 auto;
  width: 93.5%;

  @media (max-width: 400px) {
    bottom: 0;
  }
}

.overall__sent-left {
  display: block;
  width: 100%;
  height: 19px;
  text-align: left;
  position: relative;
  bottom: 20px;
}

.question-id {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .total-responses {
    font-size: 14px;
    font-weight: $fw-medium;
    display: flex;

    strong {
      font-size: 20px;
      font-weight: $fw-bold;
    }
  }
}

.question {
  color: $color--darks-black;
  display: inline-block;
  font-size: 18px;
  padding: 15px 0;
  font-weight: $fw-medium;
  word-break: break-word;

  &__head {
    margin-bottom: 30px;
  }
}

.answers {
  font-weight: $fw-medium;
  width: 100%;

  > .ui.grid {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &__text {
    width: 50%;
  }

  &__likedislike {
    width: 25%;
  }

  @media (max-width: 768px) {
    padding-left: 0;
  }

  &__percentage {
    font-size: 18px;
    font-weight: $fw-regular;
    line-height: 24px;
  }

  &__answers {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    white-space: nowrap;

    > .ui.grid {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &__answer {
    display: block;
    -ms-word-break: break-all;
    word-break: break-all;
  }

  &__progress-column {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding-left: 0 !important;

    > .ui.progress {
      margin: 0 !important;
      width: 100%;

      > .bar {
        height: 15px;
        width: 100% !important;
        min-width: 2px !important;
      }

      &.odd {
        > .bar {
          background-color: $color--teal-tag !important;
        }
      }

      &.even {
        > .bar {
          background-color: $color--teal-light !important;
        }
      }
    }
  }
}

.survey__container {
  background: $color--white;
  margin: 30px auto;
  max-width: 1440px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;

  @media (max-width: 992px) {
    margin: 20px auto;

    @media (max-width: 768px) {
      padding: 20px;

      @media (max-width: 500px) {
        padding: 10px;
      }
    }
  }

  .answers-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
  }

  &--likeDislike {
    .question {
      width: 100%;
    }

    &--answers {
      display: inline-block;
      width: 50%;

      &:nth-child(3) {
        display: inline-flex;
        justify-content: flex-end;

        .answers__answer {
          justify-content: flex-end;
        }
      }

      .answers__answer {
        display: flex;
        align-items: center;
        margin: 0 25px;

        button {
          cursor: none;
          height: 70px;
          width: 70px;
          margin-right: 25px;
        }

        .answers__answers {
          font-size: 18px;
          font-weight: $fw-regular;
          color: $color--darks-answer;
          font-family: $font-family;
        }
      }
    }
  }
}

.survey__container-individual {
  @include box-shadow($block);
  background: $color--white;
  padding-top: 15px;
}

.message-statistics {
  width: 93.5%;
  max-width: 1440px;
  margin: 1em auto 2em !important;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    > * {
      margin-right: 30px;
      line-height: 42px;
    }

    > span {
      font-size: 30px;
      text-transform: uppercase;

      @media (max-width: 800px) {
        font-size: 24px;
      }
    }

    span {
      font-family: $font-family;
      font-weight: $fw-bold;
      color: $color--darks-black;
    }

    &__contacts {
      display: flex;
      align-items: center;
      font-family: $font-family;

      &--label {
        font-size: 24px;
        font-weight: $fw-bold;
        color: $color--darks-black;
      }

      &--value {
        font-size: 24px;
        font-weight: $fw-bold;
        color: $color--blue-light;
        margin-right: 9px;
      }
    }

    &__sending {
      font-family: $font-family;
      display: flex;
      align-items: center;
      min-width: 170px;

      @media (max-width: 768px) {
        max-width: 180px;
      }

      &--label {
        font-size: 16px;
        font-weight: $fw-bold;
        color: $color--darks-black;
        margin-right: 10px;
      }

      &--value {
        font-size: 16px;
        color: $color--darks-black;
        font-weight: $fw-regular;
      }

      &:nth-child(3) {
        > div {
          &:last-child {
            text-transform: uppercase;
          }
        }
      }

      &:nth-child(4) {
        min-width: 300px;
      }
    }

    &__button {
      margin-left: auto;
      margin-right: 0;

      @media (max-width: 450px) {
        margin-left: 0;
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    border-bottom: 1px solid;
    margin-bottom: 30px;
    padding-bottom: 10px;

    > div {
      color: $color--darks-black;
      font-size: 16px;
      font-weight: $fw-bold;
    }

    &__overview {
      margin-right: 20px;
      cursor: pointer;

      &--selected {
        margin-right: 20px;
        cursor: pointer;
        color: $color--blue-light !important;
      }
    }

    &__details {
      margin-right: 20px;
      cursor: pointer;

      &--selected {
        margin-right: 20px;
        cursor: pointer;
        color: $color--blue-light !important;
      }

      &--dropdown {
        display: flex !important;
        align-items: center;

        > .text {
          color: $color--darks-black !important;
          font-size: 16px;
          font-weight: $fw-bold;
        }
      }
    }

    &__realtime {
      display: flex;
      align-items: center;
      margin-left: auto;

      .ripple-color {
        background-image: url('../styles/icons/ripple-color.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120%;
        height: 25px;
        width: 50px;
      }

      &--desktop {
        @media (max-width: 500px) {
          display: none;
        }
      }
    }
  }

  .messageStats {
    &__header {
      display: flex;
      align-items: baseline;
      width: 100%;
      height: 40px;

      &__text {
        font-size: 25px;
        font-family: $font-family;
        color: $color--darks-black;
        font-weight: $fw-medium;
        height: 40px;
      }

      &__back {
        font-size: 16px;
        font-weight: $fw-medium;
        color: $color--darks-black;
        margin-right: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 40px;

        &::before {
          content: "";
          background: svg-load('../svg/arrow-black.svg') no-repeat;
          background-size: contain;
          height: 16px;
          width: 16px;
          margin-right: 5px;
          transform: rotate(180deg);
        }
      }

      &__divider {
        position: relative !important;
        height: 100% !important;
        left: -15px !important;

        &::before {
          top: 0 !important;
        }

        &::after {
          top: 0 !important;
          bottom: 0 !important;
        }
      }

      &__buttons {
        margin-left: auto;
        display: flex;

        .ui.button.blue {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 20px;
          margin-left: 8px;
          font-size: 16px;

          > i {
            margin-right: 12px;
          }
        }

        &__dropdown {
          display: flex;
          align-items: center;
          background: #036;
          height: 40px;
          border-radius: 5px;
          color: $color--white;
          padding-left: 12px;

          .Select {
            width: 140px;
            font-size: 14px;
            color: $color--white;
            cursor: pointer;

            .Select-control {
              background-color: #036;
              color: $color--white;
              border: none;
              box-shadow: none;
              cursor: pointer;
            }

            .Select-menu-outer {
              width: 250px;
              left: unset;
              right: 0;

              .Select-menu {
                > div > div:last-child {
                  overflow: auto !important;
                }
              }
            }

            .Select-placeholder {
              color: $color--white;
            }
          }
        }

        @media (max-width: 700px) {
          margin-left: 0;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
      }
    }
  }

  .ui.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .link-clicks {
      margin-left: 0 !important;
    }
  }

  h1.ui.header.contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 992px) {
      margin-right: -1em;
    }
  }

  @media (max-width: 768px) {
    canvas {
      max-height: 309px;
    }
  }
}

.message-statistics__navigation__realtime--mobile {
  margin-left: -5px;

  @media (min-width: 501px) {
    display: none;
  }
}

.checkbox-field {
  display: flex;
  align-items: center;
  line-height: 24px;

  > .ui.checkbox {
    margin-right: 10px;
  }
}

.overall .view-as-analytics {
  line-height: 33px;
}

.donut-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  > canvas {
    position: relative;
    z-index: 2;
  }

  .donut-percentage {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    padding-top: 38px; /* depends on height of legend */
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: $font-family-message;
    font-weight: $fw-bold;
    font-size: 1.4rem;
    animation-name: donutPercent;
    animation-duration: 4s;
    opacity: 1;
    z-index: 2;
    pointer-events: none;

    &--long {
      font-size: 1rem;
    }
  }
}

.overall-statistics {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: -10px;

  &__half-desktop {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
  }

  &__box {
    border: 1px solid #979797;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    background-color: $color--white;

    &__header {
      font-size: 14px;
      font-family: $font-family;
      color: $color--darks-black;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 29px;

      canvas {
        max-height: 190px;
        max-width: 380px;
      }

      .ui.statistic > .value {
        color: #00B5E5 !important;
        font-family: $font-family;
        font-size: 90px !important;
        font-weight: 700;
      }

      &--disabled {
        color: $color--white-inactive;
        font-family: $font-family;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &--half-section {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }

    &--big-section {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      > div {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    &--small-section {
      display: flex;
      flex-direction: column;
      width: 45%;
      height: 286px;
    }

    &--giant {
      width: 100%;
      height: 368px;
      margin-bottom: 10px;

      canvas {
        max-height: 220px;
        max-width: 460px;
        margin: auto;
      }

      .donut-wrapper .donut-percentage {
        font-size: 2.1rem;

        &--long {
          font-size: 1.4rem;
        }
      }
    }

    &--half-full {
      width: 50%;
      height: 253px;

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }

      .smaller-statistics {
        color: $color--blue-light;

        @media (max-width: 1200px) {
          font-size: 50px;
        }
      }
    }

    &--big {
      width: 55%;
      height: 286px;

      .ui.statistic > .value {
        color: #00B5E5;
        font-family: $font-family;
        font-size: 90px !important;
        font-weight: $fw-bold;
      }
    }

    &--large {
      width: 100%;
      height: 335px;
      margin-top: 10px;

      .loaded-content {
        transform: scale(2);
      }

      canvas {
        max-height: 260px;
        max-width: 600px;
      }
    }

    &--small {
      width: 100%;
      height: 132px;

      .ui.statistic > .value {
        color: #00B5E5;
        font-family: $font-family;
        font-size: 50px !important;
        font-weight: $fw-bold;
      }

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    &__half-desktop {
      width: 93%;
      min-width: 700px;
    }

    &__box {
      &--half-full {
        &:first-child {
          .overall-statistics__box__content {
            position: inherit;
            transform: scale(0.9);
            top: unset;
            left: unset;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    &__half-desktop {
      width: calc(100% - 30px);
      min-width: 290px;
    }

    &__box {
      &--half-section {
        flex-direction: column;
      }

      &--small-section {
        margin-left: initial;
        margin-top: 10px;
        width: 100%;
      }

      &--big-section {
        flex-direction: column;

        > div {
          &:first-child {
            margin-right: initial;
          }

          &:last-child {
            margin-left: initial;
          }
        }
      }

      &--big {
        width: 100%;
        margin-bottom: 10px;
      }

      &--half-full {
        width: 100%;

        &:first-child {
          margin-right: initial;
          margin-bottom: 10px;
        }

        &:last-child {
          margin-left: initial;
          margin-top: 10px;
        }
      }
    }
  }

  &__segment-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  &__segment {
    height: 245px;
    width: 50%;
    background-color: $color--white;

    @media (max-width: 992px) {
      width: 100%;
    }

    &:first-child {
      margin-right: 10px;

      @media (max-width: 992px) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    &:last-child {
      margin-left: 10px;

      @media (max-width: 992px) {
        margin-left: 0;
      }
    }
  }
}

@keyframes donutPercent {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.details-column-text {
  white-space: pre-wrap;
}
