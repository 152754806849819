//TODO: this should be refactored at the end of every sprint

.international-icon {
  background-color: $color--white-grey;
  height: 20px;
  width: 32px;
}

.confirmation-modal {
  .confirmation-modal-content {
    flex-direction: column;
    margin: 0 auto;
    width: 600px;

    .header {
      color: $color--white;
      text-align: right;
      white-space: nowrap;

      &--delivery {
        color: $color--white;
        text-align: center;
        white-space: pre;
      }
    }

    .button:hover {
      box-shadow: none;
    }
  }

  @media only screen and (max-width: 700px) {
    .confirmation-modal-content {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: auto;

      .header {
        color: $color--white;
        text-align: right;
        white-space: normal;
      }

      .button:hover {
        box-shadow: none;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .confirmation-modal-content {
      max-width: 300px;
    }

    .actions.actions {
      .ui.button {
        padding: 12px 24px !important;
        height: 54px;
        line-height: 20px;
        font-size: 18px;
      }
    }
  }
}

.confirmation-modal-content.actions {
  float: right;

  .button {
    border: 4px solid;
    border-radius: 11px;
    font-size: 30px;

    &.ui.inverted.button {
      box-shadow: none !important;
    }

    &__inverted-green:hover {
      background-color: $color--blue-light;
      border-color: $color--darks-black;
      box-shadow: none;
      color: $color--darks-black !important;
    }
  }

  @media (max-width: 991px) {
    .confirmation-modal-content {
      width: 520px;
    }
  }
}

.button__inverted {
  &--white {
    border: 2px solid $color--white !important;
    color: $color--white !important;
    box-shadow: none !important;
    background: none !important;

    &:hover {
      border-color: $color--darks-black !important;
      color: $color--darks-black !important;
      background: $color--white none !important;
    }
  }

  &--green {
    border: 2px solid $color--blue-light !important;
    color: $color--blue-light !important;
    box-shadow: none !important;
    background: none !important;

    &:hover {
      border-color: $color--darks-black !important;
      color: $color--darks-black !important;
      background: $color--blue-light none !important;
    }
  }
}

.align-center {
  display: flex;
  flex-basis: auto;
  align-items: center;

  .col--50 {
    text-align: left;
    width: 50%;
  }
}

.big {
  font-size: 1.5em;
}

// media library
.title {
  display: block;
  overflow: hidden;
  padding: 2px;
  text-overflow: ellipsis;
}

.selected-image,
.select-video {
  display: grid;
  height: 500px;
  margin-bottom: 50px;
  max-width: 700px;
}

.selected-image img,
.select-video video {
  display: block;
  height: auto;
  max-height: 450px;
  max-width: 700px;
  width: 100%;
}

.select-video video {
  width: 100%;
}

.image-thumbnail,
.video-thumbnail {
  display: flex;
  height: 120px;
  justify-content: space-around;
  overflow: auto;
  overflow-x: hidden;

  > div {
    height: 80px;

    > div {
      display: table-cell;
      height: 70px;
      vertical-align: middle;
      width: 70px;
    }
  }
}

.arrow {
  margin-top: 40px;

  button {
    border: 0;
    height: 34px;
    width: 22px;
  }

  &__next {
    background: url('../png/arrow--right.png') no-repeat;

  }

  &__previous {
    background: url('../png/arrow--left.png') no-repeat;
  }
}

.image-thumbnail img,
.video-thumbnail video {
  height: auto;
  max-height: 70px;
  max-width: 70px;
  padding: 1px;
  text-align: center;
  width: auto;
}

// media

.ui.grid.attachment-block {
  margin: 0;
  width: 100%;

  .w-100 {
    width: 100%;
  }

  .attachment {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    text-decoration: underline;
  }
}

.grid {
  &__total {
    float: right;
    margin-right: 15px;
  }
}

.ui.grid.no-margin {
  margin: 0;
}

.fr {
  float: right;
}

// ****

.clickable-underline {
  cursor: pointer;
  text-decoration: underline;
}

.thumbup {
  background: url('../png/thumbup.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0;
  cursor: pointer;
  height: 40px;
  margin-right: 20px;
  width: 40px;
}

.thumbdown {
  background: url('../png/thumbdown.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin-right: 20px;
}

.blank-video {
  width: 100%;
}

.rtl {
  direction: rtl;
}

.email-underline {
  color: inherit;
  text-decoration: underline;
}

.text-color-red {
  color: $color--red-password;
}

.text-color-green {
  color: $color--green-password;
}

.editor-button.left {
  &::after {
    display: none;
  }

  &::before {
    display: none;
  }
}

.visible.visible.transition {
  display: block;
}

.drive-icon {
  background-image: svg-load('../svg/drive.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  width: 24px;
}

.inverted-checkbox-tick-color {
  filter: invert(100%);
}

.video-js {
  height: 150px;
  width: 300px;
}

.pager {
  display: inline-block;
  height: 30px;
  width: 30px;

  &--next {
    background: svg-load("../svg/next.svg") no-repeat;
  }

  &--forward {
    background: svg-load("../svg/forward.svg") no-repeat;
  }

  &--previous {
    background: svg-load("../svg/previous.svg") no-repeat;
  }

  &--backward {
    background: svg-load("../svg/backward.svg") no-repeat;
  }
}

.image-logo {
  height: auto;
  width: 100px;
}

.account-settings-modal {
  margin-top: 0 !important;
}

.message-editor-close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
}

.modal-close-icon-for-tablet {
  float: right;
  margin-right: -25px;
}

.modal-button-center {
  display: inline-block;
  margin: 0 auto;
}

.all-messages-filter {
  margin: 0 !important;

  * {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .header {
    text-transform: none !important;
    margin: 0 !important;
  }

  .ui.icon.input > input {
    border-color: transparent;
    outline: none;
    border-radius: 0;
    border-bottom: 1px solid $color--darks-black;
  }

  .divider {
    width: 90%;
    margin: 0 auto;
  }
}

.filter-option {
  .ui.menu .ui.dropdown .menu > .active.item {
    font-size: 18px !important;
    font-family: $font-family !important;
    font-weight: $fw-medium !important;
    color: $color--blue-cerulean !important;
  }

  .visible.menu.transition {
    margin: 0 !important;
  }

  &--green {
    > div {
      color: $color--blue-light;
    }
  }
}

.add-circle {
  margin-bottom: 10px;
  background-color: $color--teal-light;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: $color--white;
  cursor: pointer;
  margin-right: 35px;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: $color--teal-main;
  }
}

.w300 {
  width: 300px;
}

.recomendation {
  color: $color--white-silver;
  font-family: $font-family;
  font-size: 12px;
  font-style: italic;
  margin-bottom: -5px;
  padding-left: 23px;
}

.message-notification {
  color: $color--white;
  font-family: $font-family;
  font-size: 16px;
  font-weight: $fw-bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: -0.32px;

  &__timestamp {
    color: $color--white;
    font-family: $font-family;
    font-size: 14px;
    font-weight: $fw-medium;
    letter-spacing: -0.16px;
  }

  > i.material-icons {
    margin-right: 10px;
    font-size: 20px;
  }

  &--enabled {
    animation: slide-up 2s ease;
  }

  &--disabled {
    opacity: 0;
    animation: slide-down 2s ease-out;
    display: none;
  }

  &--desktop {
    @media (max-width: 768px) {
      display: none !important;
    }
  }

  &--mobile {
    display: flex;
    padding: 0 1em;

    @media (min-width: 769px) {
      display: none !important;
    }

    .message-notification {
      color: $color--darks-black;

      > i.material-icons {
        margin-left: 0;
      }
    }
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.none {
  display: none !important;
}

.rdw-colorpicker-modal {
  left: -65px !important;
}

.rdw-editor-toolbar {
  margin-bottom: 0 !important;
}

.DraftEditor-editorContainer .public-DraftStyleDefault-block span {
  font-family: unset;
}

.visibility--none {
  visibility: hidden;
}
