.ui.inverted.dimmer {
  background-color: $color--background-dimmer;
}

.ui.dimmer {
  padding: 0;
}

body.dimmable.dimmed {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
}
