@import "../../../../../assets/styles/base/variables-color";

.OptInCheckboxController {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-title {
    padding: 20px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color--teal-main;
  }

  &__text-editor-wrapper {
    margin-top: 20px;
  }
}
