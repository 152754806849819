.sent {
  color: $color--blue-cerulean !important;
}

.line {
  border: 1px solid $color--darks-black;
  box-sizing: border-box;
  height: 2px;
  width: 235px;
}

.ui.button.see {
  background-color: $color--blue-celeste;
  box-sizing: border-box;
  height: 40px;
  left: 20px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: $color--blue-glow;
  }

  @media (max-width: 768px) {
    width: 100%;
    position: static;
    left: 0;
    margin: 0;
    border-radius: 0;
  }

  a {
    color: $color--blue-main;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      background: svg-load('../svg/arrow-blue.svg') no-repeat;
      background-size: 100%;
      height: 20px;
      width: 12px;
      content: '';
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.ui.grid > [class*='four column'].row > .column {
  width: 25% !important;
}

.message-item {
  &__content--tag {
    width: max-content;
    word-wrap: unset;
  }

  &__header {
    padding: 0 15px;
    height: 40px;
  }

  .padded-hr {
    width: 100%;

    @media (min-width: 992px) {
      display: none;
    }
  }
}

.table-i {
  color: $color--teal-light;
  cursor: pointer;
  float: right;
  font-size: 100px;
}

.message .filter-option {
  margin-right: 11px;
  position: relative;
  right: 7px;
}

.empty-messages {
  background-color: $color--white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10%;
  padding-top: 7.5%;
  margin-bottom: 20px;

  &__text-wrapper {
    margin-bottom: 1.5rem;
  }

  &__text {
    color: $color--blue-main;
    font-size: 36px;
    display: flex;
    font-weight: $fw-bold;
    font-family: $font-family-headline;
    justify-content: center;
    line-height: 46px;
    text-align: center;
  }

  &__button-text {
    color: $color--white;
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
  }

  .ui.button {
    align-self: center;
    border-radius: 14.74px;
    display: flex;
  }
}

.no-filter-messages {
  color: $color--darks-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 33px;
  text-align: center;

  &::after {
    background: svg-load('../svg/arrow_grey.svg') no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 40px;
    width: 40px;
    margin-top: 2em;
  }
}

.center-no-messages-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30%;
  padding-right: 30%;
}

@media (min-width: 768px) {
  .secondMessagesFilter,
  .thirdMessagesFilter {
    margin-left: 15px;
  }

  .only-mobile {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .no-mobile {
    display: none !important;
  }

  hr.only-mobile {
    border: 1px solid $color--white-inactive;
    width: 100%;

  }
}

.firstMessagesFilter,
.secondMessagesFilter {
  margin-right: 20px;
}

.firstMessagesFilter,
.secondMessagesFilter,
.thirdMessagesFilter {
  display: flex !important;
  align-items: center;
  height: 30px;
  max-width: 164px;
  white-space: nowrap;
}

.disabled-preview {
  pointer-events: none;
  opacity: 1 !important;
}

.message-blocks-preview {
  > div {
    pointer-events: none;
  }
}

.delete-border {
  border: 2px solid transparent;
  border-radius: 8px;
  position: relative;
  width: 100%;

  &--touch {
    border: 2px solid $color--darks-dark-accent;
    border-radius: 8px;
    position: relative;
    width: 100%;

    > *:nth-child(2) {
      margin: 5px auto;
    }
  }

  > *:nth-child(2) {
    margin: 5px auto;
  }

  &:hover {
    border: 2px solid $color--darks-dark-accent;

    .message-actions {
      display: flex;
      z-index: 1;
    }
  }
}

.delete-border-preview {
  position: relative;
  width: 100%;

  > *:nth-child(2) {
    margin: 5px auto;
  }
}

.message-actions {
  display: none;
  align-items: center;
  background-color: $color--darks-dark-accent;
  border-radius: 8px;
  position: absolute;
  top: -10px;
  right: 5px;
  height: 20px;
  padding: 0 6px;

  &--touch {
    display: flex;
    align-items: center;
    background-color: $color--darks-dark-accent;
    border-radius: 8px;
    position: absolute;
    top: -10px;
    right: 5px;
    height: 20px;
    padding: 0 6px;
  }

  &__delete-icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: svg-load("../svg/ic_delete_24px-white.svg") no-repeat;
    background-size: 50%;
    background-position: center center;
    cursor: pointer;
    z-index: 1;
  }

  &__reorder-down {
    height: 16px;
    width: 16px;
    margin-right: 2px;
    background: svg-load("../svg/arrow_down_white.svg") no-repeat;
    background-size: 50%;
    background-position: center center;
    cursor: pointer;
    z-index: 1;
  }

  &__reorder-up {
    height: 16px;
    width: 16px;
    margin-right: 2px;
    background: svg-load("../svg/arrow_up_white.svg") no-repeat;
    background-size: 50%;
    background-position: center center;
    cursor: pointer;
    z-index: 1;
  }
}

@media only screen and (max-width: 991px) {
  .message-actions {
    height: 30px;
    border-radius: 12px;
    top: -15px;

    &__delete-icon {
      height: 25px;
      width: 25px;
    }

    &__reorder-down {
      height: 25px;
      width: 25px;
      margin-right: 5px;
    }

    &__reorder-up {
      height: 25px;
      width: 25px;
      margin-right: 5px;
    }
  }
}

.content-divider {
  top: 0 !important;
  left: -0.5% !important;
  height: 100% !important;
  margin-top: 5% !important;

  &::before {
    top: 0 !important;
  }

  &::after {
    bottom: auto !important;
  }
}
