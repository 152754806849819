.message-icon {
  background-repeat: no-repeat;
  height: 20px;
  width: 19px;
  background-size: 100%;
  display: block;
  margin: 0 auto;
  cursor: pointer;

  &__edit {
    background-image: svg-load('../svg/ic_edit_24px.svg');
  }

  &__preview {
    background-image: svg-load('../svg/baseline-visibility-24px.svg');
    width: 23px;
  }

  &__chart {
    background-image: svg-load('../svg/ic_insert_chart_24px.svg');
  }

  &__chart_disabled {
    background-image: svg-load('../svg/ic_insert_chart_disabled_24px.svg');
    cursor: default;
  }

  &__email {
    background-image: svg-load('../svg/ic_stacked_email_24px.svg');
    width: 22px;
  }

  &__linkoff {
    background-image: svg-load('../svg/ic_link_off_24px.svg');
    fill: $color--darks-black;
    width: 22px;
  }

  &__link {
    background-image: svg-load('../svg/ic_link_24px.svg', 'fill=#{$color--blacky}');
    background-position: center top 5px;
    width: 22px;
  }

  &__link-draft {
    background-image: svg-load('../svg/ic_link_off_24px_for_draft.svg');
    width: 22px;
    cursor: default;
  }

  &__delete {
    background-image: svg-load('../svg/ic_delete_24px.svg');
    width: 16px;
  }
}
