.stats {
  .button {
    margin-top: 15px;
  }

  .segment {
    padding: 15px 20px;
  }

  .ui.input {
    width: 100%;
  }

  &__thead {
    background: $color--darks-black;
    line-height: 20px;
  }

  .row {
    margin-top: 10px;
  }

  .extend {
    color: $color--teal-light;
    cursor: pointer;
    float: right;
    text-decoration: underline;
    font-size: 18px;
  }

  .ui.segment {
    border: 0;
    box-shadow: none;
  }

  &__plan {
    @include box-shadow($chart-shadow);
    margin-left: 3px;
    padding: 30px;

    hr {
      border-color: $color--white-grey;
      margin: 36px 18px auto;
    }

    .label {
      margin-top: 10px;
    }
  }

  &__field {
    .label {
      color: $color--blue-royal;
      display: block;
      margin-top: 15px;
    }
  }

  .filter-label {
    color: $color--blue-light;
    display: inline;
    font-size: 18px;
    margin: 20px auto;
  }

  .filter-options {
    display: inline;
    font-size: 18px;

    .dropdown.icon {
      display: inline;
    }

    .text {
      display: inline;
      text-align: center;
      width: 100%;
    }
  }

  .button {
    &--apply {
      background-color: $color--blue-light;
      border-radius: 8px;
      color: $color--white;
      float: right;
      height: 40px;
      margin-top: 15px;
      width: 50%;
    }

    &.blue {
      display: inline;
      margin-left: 50px;
      width: 30%;
    }
  }

  .read-only {
    font-size: 18px;
    font-weight: $fw-book;
    line-height: 1.5em;

    strong {
      display: block;
      margin: 5px 0;
    }
  }
}

.block {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.stats-parent {
  display: flex;
  flex-direction: column;
}

.stats-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .statistic {
    min-width: 200px;

    .label {
      font-weight: 600;
      padding-bottom: 25%;
      padding-top: 25%;
    }

    .value {
      font-weight: 700;
    }
  }
}

.stats-comments-text {
  &__header {
    font-size: 18px;
    font-weight: 600;
  }

  &__small {
    font-size: 16px;
    color: $color--white-inactive;
    padding-bottom: 19.1%;
  }
}
