.manager__header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  > div {
    margin: 1em;
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }
}
