@import "../../../assets/styles/base/variables-color";

.MessageElementsDistributor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  padding: 8px 5px;

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__elements-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 8px;
  }

  &__dnd-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 99%;
    min-height: 100px;
    background-color: $color--white-splashed;
    margin: 0 0 15px;
    padding: 20px;
    background-image:
      linear-gradient(to right, #000 50%, transparent 50%),
      linear-gradient(to right, #000 50%, transparent 50%),
      linear-gradient(to bottom, #000 50%, transparent 50%),
      linear-gradient(to bottom, #000 50%, transparent 50%);
    background-position: left top, left bottom, left top, right top;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 24px 1.5px, 24px 1.5px, 1.5px 24px, 1.5px 24px;
    opacity: 1;
    animation-name: appear;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: alternate;

    &--can-drop {
      border-color: $color--blue-royal;
      background-color: #87CEFA2E;
      min-height: 40px;
      transition: min-height 0.4s ease-out;

      i {
        display: none;
      }

      p {
        display: none;
      }
    }

    i {
      font-size: 54px;
      text-align: center;
      width: 100%;
    }

    p {
      margin-top: 20px;
      font-size: 18px;
      color: $color--darks-black;
      text-align: center;
    }
  }

  &__element-wrapper {
    box-sizing: border-box;
    box-shadow: 0 0 0 2px transparent;
    position: relative;
    border-radius: 8px;
    display: flex;

    &--is-preview {
      pointer-events: none;
    }

    &:hover {
      box-shadow: 0 0 0 2px rgba(55, 71, 79, 1);

      .MessageElementsDistributor__element-cta-section {
        display: flex;
      }
    }
  }

  &__element-additional-wrapper {
    pointer-events: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .body__submit-button {
      pointer-events: none;
    }
  }

  &__element-cta-section {
    align-items: center;
    background-color: $color--darks-dark-accent;
    border-radius: 8px;
    position: absolute;
    top: -10px;
    right: 5px;
    height: 20px;
    padding: 0 6px;
    display: none;
    justify-content: center;
  }

  &__element-remove-button {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: svg-load("../../../assets/svg/ic_delete_24px-white.svg") no-repeat;
    background-color: $color--darks-dark-accent;
    background-size: 50%;
    background-position: center center;
    cursor: pointer;
    border: none;
    z-index: 20;
  }

  &__element-reorder-up {
    height: 16px;
    width: 16px;
    margin-right: 2px;
    background: svg-load("../../../assets/svg/arrow_up_white.svg") no-repeat;
    background-size: 50%;
    background-position: center center;
    cursor: pointer;
    z-index: 1;
    border: none;
  }

  &__element-reorder-down {
    height: 16px;
    width: 16px;
    margin-right: 2px;
    background: svg-load("../../../assets/svg/arrow_down_white.svg") no-repeat;
    background-size: 50%;
    background-position: center center;
    cursor: pointer;
    z-index: 1;
    border: none;
  }
}
