.LikeDislikeQuestionController {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 20px;

  &__thumb-up {
    background: svg-load('../../../../../../../../../assets/svg/thumbup_active.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 0;
    cursor: pointer;
    height: 40px;
    margin-right: 20px;
    width: 40px;
  }

  &__thumb-down {
    background: svg-load('../../../../../../../../../assets/svg/thumbdown_active.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 0;
    cursor: pointer;
    height: 40px;
    width: 40px;
    margin-right: 20px;
  }
}
