.tags input {
  border-color: $color--blue-light;
  z-index: 1;
}

.ui.tag.label::after,
.ui.tag.labels .label::after,
.ui.tag.label::before,
.ui.tag.labels .label::before {
  display: none;
}

.is-clearable .Select-control {
  border-radius: 8px;
}

.ReactTags {
  &:hover {
    height: max-content;
    position: relative;
  }

  &__tag {
    border: 1px solid;
    border-radius: 10px;
    color: $color--white;
    display: inline-block;
    font-size: 12px;
    font-weight: $fw-bold;
    line-height: 1.4;
    margin: 10px 4px;
    padding: 1px 8px;
    vertical-align: top;

    &__delete {
      border: 1px solid;
      border-radius: 10px;
      display: inline-flex;
      line-height: 1.4;
      margin: 10px 4px;
      padding: 1px 8px;
      align-items: center;

      &__text {
        vertical-align: top;
        color: $color--white;
        font-size: 12px;
        font-weight: $fw-bold;
        cursor: default;
      }

      &__icon {
        background: svg-load('../svg/close-white.svg') no-repeat;
        background-size: contain;
        margin-left: 10px;
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
    }

    &--interactive {
      cursor: pointer;
      color: $color--blue-main;
      border: 2px solid;

      &:hover {
        color: $color--blue-hoverblue;
      }
    }
  }

  &__remove {
    margin-left: 5px;
  }

  &__tagInput {
    display: none;
  }
}

.modal--tag {
  width: 384px !important;
  margin: 24px 0 0 !important;

  .content {
    padding: 0 !important;
  }

  &__content {
    padding: 0 24px 8px;

    .spinner__content {
      min-height: unset;
    }
  }

  &__actions {
    padding: 8px 24px 16px;
  }
}

.react-select.tag {
  width: 100%;

  .Select-control {
    width: 100%;
  }
}

.optin-delivery-tag {
  > div {
    border-color: $color--grey-border;
    border-radius: 8px;
    background-color: $color--grey-input;
  }

  &__dropdown-menu {
    z-index: 3 !important;
  }

  &__dropdown-icon {
    /* position and size of tag dropdown icon */
    font-size: 0.85rem !important;
    margin: 0 0.65rem 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
