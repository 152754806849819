@import "../../../../../assets/styles/base/variables-color";

.OptInTagsController {
  display: flex;
  gap: 14px;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 420px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &__label {
    white-space: nowrap;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $color--teal-main;
  }
}
