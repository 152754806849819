.site-logo-white {
  height: 24px;
  width: 110px;
  max-width: 110px;
  overflow: inherit;
  background-image: svg-load("../svg/rispons-logo-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;

  @media (max-width: 450px) {
    width: 100px;
    max-width: 100px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
