.ui.breadcrumb {
  white-space: nowrap;
  font-size: 18px;

  a {
    color: $color--white;
    cursor: pointer;

    &.active {
      color: $color--blue-glow;
    }
  }
}
