// Variables
@import 'base/fonts';
@import 'base/variables-color';
@import 'base/fonts';
@import 'base/layout';
@import 'base/typography';
@import 'refactor';
@import 'spinner';
// Semantic UI componenets
@import 'semantic/accordation';
@import 'semantic/button';
@import 'semantic/modal';
@import 'semantic/grid';
@import 'semantic/menu';
@import 'semantic/input';
@import 'semantic/header';
@import 'semantic/item';
@import 'semantic/dimmer';
@import 'semantic/breadcrumbs';
@import 'semantic/segment';
@import 'semantic/progress';
@import 'semantic/divider';
@import 'semantic/dropdown';
@import 'semantic/popup';
@import 'semantic/toggle';
@import 'semantic/phone-input';
// Components
@import 'components/admin/statistic';
@import 'components/admin/overall';
@import 'components/analytics';
@import 'components/button/link';
@import 'components/header/header';
@import 'components/content';
@import 'components/custom-containers';
@import 'components/footer/footer';
@import 'components/form/form';
@import 'components/content';
@import 'components/table/reactTable';
@import 'components/passwordValidity/password-validity';
@import 'components/contacts';
@import 'components/chart';
@import 'components/widget';
@import 'components/plans';
@import 'components/editor';
@import 'components/manager';
@import 'components/message';
@import 'components/notfound';
@import 'components/notification';
@import 'components/delivery-method';
@import 'components/tag';
@import 'components/register';
@import 'components/policy-page';
@import 'components/account-settings';
@import 'components/sms-pricing';
@import 'components/messageItem/message-desktop';
@import 'components/media-library';
@import 'components/messageItem/message-tablet';
@import 'components/messageItem/message-mobile';
@import 'components/select';
@import 'components/automation';
@import 'components/automation-node';
@import 'components/automation-editor';
@import 'components/color-picker';
// Icons
@import 'icons/contact-add';
@import 'icons/site-logo';
@import 'icons/site-logo-white';
@import 'icons/header-message';
@import 'icons/footer-icon';
@import 'icons/footer-logo';
@import 'icons/close';
@import 'icons/add';
@import 'icons/message-block';
@import 'icons/arrow_down';
@import 'icons/anonymous-logo';
@import 'icons/info';

body {
  background-color: $color--white;
  font-family: $font-family;
  margin: 0;
}

.app-main {
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &--contacts {
    overflow: unset;
  }
}

input,
textarea,
button,
select,
a,
span {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container {
  margin: 50px;

  &.message-statistics {
    @media (max-width: 992px) {
      margin: 20px;

      @media (max-width: 768px) {
        margin: 5px;
      }
    }
  }
}

.ris-container {
  margin: 0 50px;

  @media (max-width: 992px) {
    margin: 20px;
  }

  @media (max-width: 768px) {
    margin: 10px;

    .ui.header h1,
    .ui.header {
      font-size: 24px;
      padding: 0.5em 0 !important;
    }
  }

  &__account-settings {
    @media (min-width: 992px) {
      padding: 0 50px;
      max-width: 1440px;
      margin: 0 auto;
    }

    > .ui.grid {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.hide {
  display: none;
}

.pager {
  display: inline-block;
  height: 30px;
  width: 30px;

  &-next {
    background: svg-load('../svg/next.svg') no-repeat;
  }

  &-forward {
    background: svg-load('../svg/forward.svg') no-repeat;
  }

  &-previous {
    background: svg-load('../svg/previous.svg') no-repeat;
  }

  &-backward {
    background: svg-load('../svg/backward.svg') no-repeat;
  }
}

.ui.page.modals.transition.visible {
  display: flex !important;
}

.ui.label {
  background: none;
  color: $color--darks-black;
}

.ui.segment {
  box-shadow: none;

  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

.clear {
  clear: both;
}

.bold {
  font-weight: $fw-bold;
}

.inline {
  display: inline;
}

hr.light-grey {
  border: $color--white-splashed;
}

.ct {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.footer--spaced {
  margin-bottom: 50px;
}

.smaller-statistics {
  color: $color--teal;
  font-size: 60px;
  font-weight: 700;
  padding-bottom: 33px;
  padding-top: 39px;
}

.align-statistics {
  margin-top: -15px !important;
}

p {
  margin: 0;
}
