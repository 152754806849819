@import "../../../../../../../assets/styles/base/variables-color";
@import "../../../../../../../assets/styles/base/fonts";

.OpenQuestionAnswerUI {
  width: 100%;

  &__open-question-input {
    border: none;
    width: 100%;
    font-size: 14px;
    padding: 12px 10px;
    outline: none;
    font-family: $font-family-message;
    color: inherit;
    line-height: 16px;
    background-color: $color--grey-survey;
    border-radius: 7px;
    margin: 15px 15px 30px;
  }
}
