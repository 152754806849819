@import "../../../assets/styles/base/variables-color";

.FileUploadWidgetController {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__controller-body {
    display: flex;
    flex-direction: column;
    padding: 17px;
    max-width: 934px;
    margin: 0 auto;
    width: 100%;
  }

  &__input-wrapper {
    margin-bottom: 16px;
  }

  &__file-content-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__button-wrapper {
    gap: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__label-text {
    font-size: 14px;
    font-weight: 600;
    color: $color--accent-obsidian;
  }

  &__delete-file-button {
    border-radius: 8px;
    background-color: $color--white;
    border: 1px solid #C0C0C0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 20px 2%;
    width: 45%;
    cursor: pointer;
    text-decoration: none;
    max-width: 200px;
  }

  &__file-action-name {
    color: rgb(188, 0, 0);
    font-size: 10px;
    display: flex;
    justify-content: center;
    word-break: break-all;
    font-weight: 700;
    text-align: center;
  }

  &__file-name {
    text-decoration: underline;
    font-size: 10px;
    display: flex;
    justify-content: center;
    word-break: break-all;
    cursor: pointer;
  }
}
